@import './common/variables.scss';
@import './common/mixins.scss';
@font-face {
    font-family: 'Proxima Nova Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Proxima Nova Thin'), url('../../src/assets/fonts/FontsFree-Net-Proxima-Nova-Thin.otf') format('woff');
}

@font-face {
    font-family: 'Proxima Nova Rg Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Proxima Nova Rg Regular'), url('../../src/assets/fonts/FontsFree-Net-proxima_nova_reg-webfont.ttf') format('woff');
}

@font-face {
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Proxima Nova Bold'), url('../../src/assets/fonts/FontsFree-Net-Proxima-Nova-Bold.otf') format('woff');
}



/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: $primary-color;
}

input:focus+.slider {
    box-shadow: 0 0 1px $primary-color;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.mlveda-label {
    font-weight: 700;
    font-size: 13px;
    color: #676a6c;
    &.inline {
        display: inline-block;
    }
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.clear-fix {
    clear: both;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}