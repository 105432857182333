@import './common/variables.scss';
@import './common/mixins.scss';
.modal-content {
   border-radius: 0px;
   border: 1px solid $primary-border-color;
}

.note-modal-body-wrapper {
   border-radius: 0px;
   padding: 30px;
   & .bold-text {
      @include font-type($font-Proxima-bold, 16px, 400);
   }
   & .btn-custom-alert {
      width: 280px;
   }
}

.preview-notice {
   padding-left: 20px;
   padding-right: 20px;
}

.notice-image {
   width: 75px;
   margin-bottom: 5px;
}
.default-currency-notice{
   padding-left: 20px;
   padding-top: 10px;
   @include font-type($font-Proxima-regular, 14px, 400);
}
.btn-add-currency{
   border: 1px solid $primary-border-color;
   color: $primary-border-color;
   border-top: none;
   padding: 12px 25px;
   text-align: center;
}

.btn-add-currency .addCurrency {
  border: 1px solid $primary-btn-color;
  color: $primary-btn-color;
}

.currecy-custom .css-1g6gooi {
  position: relative;
}

.restWorldText {
   font-size: 12px;
   padding-left: 2px;
}

@media screen and (max-width: 768px) {
   .note-modal-body-wrapper {
      & .btn-custom-alert {
         width: 245px;
      }
   }
   .defaultCurrencyText {
      width: 60%;
   }
}