
@import './common/variables.scss';
@import './common/mixins.scss';
.headerDiv {
  font-size: 25px;
  text-align: center;
  padding-bottom: 10px;

}

.font-weight-600 {
  font-weight: 600;
}

.content-wrapper-dashboard {
  // padding-top: 30px;
  & .section-wrapper {
    float: none;
    margin: 0 auto; 
  }
}

.offer-title{
   @include font-type($font-Proxima-regular, 25px, 400);
   color: $primary-color;
}

.close-dialog-btn {
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 18px;
}

.pricing-btn-container {
  
   & span {
    width: 210px;
      & div:nth-child(2) {
        line-height: 15px;
      }
   }
  
}

.mb-20{
  margin-bottom: 20px;
}