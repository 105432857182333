@import './common/variables.scss';
@import './common/mixins.scss';

.noticeDiv {
  text-align: center;
  margin: 0px 40px 40px;
}

.noticeDiv img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.noteTitle {
  font-weight: 600;
  margin-top: 10px;
}

.start-plan-container {
  height: 50px;
  align-items: center;
  margin-top: 18px;
  flex-wrap: wrap; 
  text-align: center;
  display: block;
}

.btn-custom-primary.red {
  border: 1px solid $red-font-color;
  background: none;
  color: $red-font-color;
  width: 300px;
}

.btn-custom-primary.blue {
  width: 300px;
}

.btn-custom-primary.green {
  border: 1px solid $green-font-color;
  background: none;
  color: $green-font-color;
  width: 300px;
}

.start-plan-container.first {
  margin-top: 50px;
}

.importantIcon {
  font-size: 45px;
  color: $red-font-color;
  border: 3px solid $red-font-color;
  padding: 20px 28px;
  border-radius: 50%;
  display: inline-block;
}

.content-wrapper-impNotice {
  padding-top: 30px;
}