@charset "UTF-8";
.faq-conatiner {
  max-width: 950px;
  margin: 0 auto; }

.root {
  /*
Theme Name: Twenty Sixteen
Theme URI: https://wordpress.org/themes/twentysixteen/
Author: the WordPress team
Author URI: https://wordpress.org/
Description: Twenty Sixteen is a modernized take on an ever-popular WordPress layout â€” the horizontal masthead with an optional right sidebar that works perfectly for blogs and websites. It has custom color options with beautiful default color schemes, a harmonious fluid grid using a mobile-first approach, and impeccable polish in every detail. Twenty Sixteen will make your WordPress look beautiful everywhere.
Version: 1.8
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Tags: one-column, two-columns, right-sidebar, accessibility-ready, custom-background, custom-colors, custom-header, custom-menu, editor-style, featured-images, flexible-header, microformats, post-formats, rtl-language-support, sticky-post, threaded-comments, translation-ready, blog
Text Domain: twentysixteen

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.
*/
  /**
 * Table of Contents
 *
 * 1.0 - Normalize
 * 2.0 - Genericons
 * 3.0 - Typography
 * 4.0 - Elements
 * 5.0 - Forms
 * 6.0 - Navigation
 *   6.1 - Links
 *   6.2 - Menus
 * 7.0 - Accessibility
 * 8.0 - Alignments
 * 9.0 - Clearings
 * 10.0 - Widgets
 * 11.0 - Content
 *    11.1 - Header
 *    11.2 - Posts and pages
 *    11.3 - Post Formats
 *    11.4 - Comments
 *    11.5 - Sidebar
 *    11.6 - Footer
 * 12.0 - Media
 *    12.1 - Captions
 *    12.2 - Galleries
 * 13.0 - Multisite
 * 14.0 - Media Queries
 *    14.1 - >= 710px
 *    14.2 - >= 783px
 *    14.3 - >= 910px
 *    14.4 - >= 985px
 *    14.5 - >= 1200px
 * 15.0 - Print
 */
  /**
 * 1.0 - Normalize
 *
 * Normalizing styles have been helped along thanks to the fine work of
 * Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
 */
  /**
 * 2.0 - Genericons
 */
  /**
 * 3.0 - Typography
 */
  /**
 * 4.0 - Elements
 */
  /* Placeholder text color -- selectors need to be separate to work. */
  /**
 * 5.0 - Forms
 */
  /**
 * 6.0 - Navigation
 */
  /**
 * 6.1 - Links
 */
  /**
 * 6.2 - Menus
 */
  /* reset screen-reader-text */
  /**
 * 7.0 - Accessibility
 */
  /* Text meant only for screen readers */
  /* must have higher specificity than alternative color schemes inline styles */
  /**
 * 8.0 - Alignments
 */
  /**
 * 9.0 - Clearings
 */
  /**
 * 10.0 - Widgets
 */
  /* Calendar widget */
  /* Recent Posts widget */
  /* RSS widget */
  /* Tag Cloud widget */
  /**
 * 11.0 - Content
 */
  /* Do not show the outline on the skip link target. */
  /**
 * 11.1 - Header
 */
  /**
 * 11.2 - Posts and pages
 */
  /**
 * IE8 and earlier will drop any block with CSS3 selectors.
 * Do not combine these styles with the next block.
 */
  /**
 * 11.3 - Post Formats
 */
  /**
 * 11.4 - Comments
 */
  /**
 * 11.5 - Sidebar
 */
  /**
 * 11.6 - Footer
 */
  /**
 * 12.0 - Media
 */
  /* Make sure embeds and iframes fit their containers. */
  /**
 * 12.1 - Captions
 */
  /**
 * 12.2 - Galleries
 */
  /**
 * 13.0 - Multisites
 */
  /**
 * 14.0 - Media Queries
 */
  /**
 * Does the same thing as <meta name="viewport" content="width=device-width">,
 * but in the future W3C standard way. -ms- prefix is required for IE10+ to
 * render responsive styling in Windows 8 "snapped" views; IE10+ does not honor
 * the meta tag. See https://core.trac.wordpress.org/ticket/25888.
 */
  /**
 * 14.1 - >= 710px
 */
  /**
 * 14.2 - >= 783px
 */
  /**
 * 14.3 - >= 910px
 */
  /**
 * 14.4 - >= 985px
 */
  /**
 * 14.5 - >= 1200px
 */
  /**
 * 15.0 - Print
 */
  /**************************Custom Blog Page Css**********************************/
  /*************Header Css************/
  /************Blog Css**************/
  /**********Blog Details Css********/
  /*************Footer Css***********/
  /**********************Media Query*****************/
  /*
Theme Name: Twenty Sixteen
Description: Used to style blocks.
*/
  /*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.0 General Block Styles
2.0 Blocks - Common Blocks
3.0 Blocks - Formatting
4.0 Blocks - Layout Elements
5.0 Blocks - Widgets
6.0 Blocks - Colors
--------------------------------------------------------------*/
  /*--------------------------------------------------------------
1.0 General Block Styles
--------------------------------------------------------------*/
  /* Captions */
  /*--------------------------------------------------------------
2.0 Blocks - Common Blocks
--------------------------------------------------------------*/
  /* Paragraph */
  /* Image */
  /* Gallery */
  /* Quote */
  /* Audio */
  /* Cover */
  /* File */
  /*--------------------------------------------------------------
3.0 Blocks - Formatting Blocks
--------------------------------------------------------------*/
  /* Code */
  /* Pullquote */
  /* Table */
  /*--------------------------------------------------------------
4.0 Blocks - Layout Elements
--------------------------------------------------------------*/
  /* Buttons */
  /* Seperator */
  /* Media & Text */
  /*--------------------------------------------------------------
5.0 Blocks - Widget Blocks
--------------------------------------------------------------*/
  /* Archives, Categories & Latest Posts */
  /* Latest Comments */
  /*--------------------------------------------------------------
6.0 Blocks - Colors
--------------------------------------------------------------*/ }
  .root iframe {
    max-width: 100%; }
  .root img {
    max-width: 100%;
    height: auto; }
  .root .wp-block-audio figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .wp-block-audio audio {
    width: 100%;
    min-width: 300px; }
  .root .wp-block-button {
    color: #fff; }
  .root .wp-block-button.aligncenter {
    text-align: center; }
  .root .wp-block-button.alignright {
    text-align: right; }
  .root .wp-block-button__link {
    background-color: #32373c;
    border: none;
    border-radius: 28px;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin: 0;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word; }
  .root .wp-block-button__link:active,
  .root .wp-block-button__link:focus,
  .root .wp-block-button__link:hover,
  .root .wp-block-button__link:visited {
    color: inherit; }
  .root .is-style-squared .wp-block-button__link {
    border-radius: 0; }
  .root .no-border-radius.wp-block-button__link {
    border-radius: 0 !important; }
  .root .is-style-outline {
    color: #32373c; }
  .root .is-style-outline .wp-block-button__link {
    background-color: transparent;
    border: 2px solid; }
  .root .wp-block-calendar {
    text-align: center; }
  .root .wp-block-calendar tbody td,
  .root .wp-block-calendar th {
    padding: 4px;
    border: 1px solid #e2e4e7; }
  .root .wp-block-calendar tfoot td {
    border: none; }
  .root .wp-block-calendar table {
    width: 100%;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif; }
  .root .wp-block-calendar table th {
    font-weight: 400;
    background: #edeff0; }
  .root .wp-block-calendar a {
    text-decoration: underline; }
  .root .wp-block-calendar tfoot a {
    color: #00739c; }
  .root .wp-block-calendar table caption,
  .root .wp-block-calendar table tbody {
    color: #40464d; }
  .root .wp-block-categories.alignleft {
    margin-right: 2em; }
  .root .wp-block-categories.alignright {
    margin-left: 2em; }
  .root .wp-block-columns {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: wrap; }
  @media (min-width: 782px) {
    .root .wp-block-columns {
      flex-wrap: nowrap; } }
  .root .wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word; }
  @media (max-width: 599px) {
    .root .wp-block-column {
      flex-basis: 100% !important; } }
  @media (min-width: 600px) {
    .root .wp-block-column {
      flex-basis: calc(50% - 16px);
      flex-grow: 0; }
    .root .wp-block-column:nth-child(2n) {
      margin-left: 32px; } }
  @media (min-width: 782px) {
    .root .wp-block-column:not(:first-child) {
      margin-left: 32px; } }
  .root .wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start; }
  .root .wp-block-columns.are-vertically-aligned-center {
    align-items: center; }
  .root .wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end; }
  .root .wp-block-column.is-vertically-aligned-top {
    align-self: flex-start; }
  .root .wp-block-column.is-vertically-aligned-center {
    -ms-grid-row-align: center;
    align-self: center; }
  .root .wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end; }
  .root .wp-block-cover,
  .root .wp-block-cover-image {
    position: relative;
    background-color: #000;
    background-size: cover;
    background-position: 50%;
    min-height: 430px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
  .root .wp-block-cover-image.has-parallax,
  .root .wp-block-cover.has-parallax {
    background-attachment: fixed; }
  @supports (-webkit-overflow-scrolling: touch) {
    .root .wp-block-cover-image.has-parallax,
    .root .wp-block-cover.has-parallax {
      background-attachment: scroll; } }
  @media (prefers-reduced-motion: reduce) {
    .root .wp-block-cover-image.has-parallax,
    .root .wp-block-cover.has-parallax {
      background-attachment: scroll; } }
  .root .wp-block-cover-image.has-background-dim:before,
  .root .wp-block-cover.has-background-dim:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    opacity: 0.5;
    z-index: 1; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-10:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-10:before {
    opacity: 0.1; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-20:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-20:before {
    opacity: 0.2; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-30:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-30:before {
    opacity: 0.3; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-40:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-40:before {
    opacity: 0.4; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-50:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-50:before {
    opacity: 0.5; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-60:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-60:before {
    opacity: 0.6; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-70:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-70:before {
    opacity: 0.7; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-80:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-80:before {
    opacity: 0.8; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-90:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-90:before {
    opacity: 0.9; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-100:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-100:before {
    opacity: 1; }
  .root .wp-block-cover-image.alignleft,
  .root .wp-block-cover-image.alignright,
  .root .wp-block-cover.alignleft,
  .root .wp-block-cover.alignright {
    max-width: 305px;
    width: 100%; }
  .root .wp-block-cover-image:after,
  .root .wp-block-cover:after {
    display: block;
    content: '';
    font-size: 0;
    min-height: inherit; }
  @supports (position: sticky) {
    .root .wp-block-cover-image:after,
    .root .wp-block-cover:after {
      content: none; } }
  .root .wp-block-cover-image.aligncenter,
  .root .wp-block-cover-image.alignleft,
  .root .wp-block-cover-image.alignright,
  .root .wp-block-cover.aligncenter,
  .root .wp-block-cover.alignleft,
  .root .wp-block-cover.alignright {
    display: flex; }
  .root .wp-block-cover-image .wp-block-cover__inner-container,
  .root .wp-block-cover .wp-block-cover__inner-container {
    width: calc(100% - 70px);
    z-index: 1;
    color: #f8f9f9; }
  .root .wp-block-cover-image .wp-block-subhead:not(.has-text-color),
  .root .wp-block-cover-image h1:not(.has-text-color),
  .root .wp-block-cover-image h2:not(.has-text-color),
  .root .wp-block-cover-image h3:not(.has-text-color),
  .root .wp-block-cover-image h4:not(.has-text-color),
  .root .wp-block-cover-image h5:not(.has-text-color),
  .root .wp-block-cover-image h6:not(.has-text-color),
  .root .wp-block-cover-image p:not(.has-text-color),
  .root .wp-block-cover .wp-block-subhead:not(.has-text-color),
  .root .wp-block-cover h1:not(.has-text-color),
  .root .wp-block-cover h2:not(.has-text-color),
  .root .wp-block-cover h3:not(.has-text-color),
  .root .wp-block-cover h4:not(.has-text-color),
  .root .wp-block-cover h5:not(.has-text-color),
  .root .wp-block-cover h6:not(.has-text-color),
  .root .wp-block-cover p:not(.has-text-color) {
    color: inherit; }
  .root .wp-block-cover__video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover; }
  .root .wp-block-cover-image-text,
  .root .wp-block-cover-text,
  .root section.wp-block-cover-image h2 {
    color: #fff; }
  .root .wp-block-cover-image-text a,
  .root .wp-block-cover-image-text a:active,
  .root .wp-block-cover-image-text a:focus,
  .root .wp-block-cover-image-text a:hover,
  .root .wp-block-cover-text a,
  .root .wp-block-cover-text a:active,
  .root .wp-block-cover-text a:focus,
  .root .wp-block-cover-text a:hover,
  .root section.wp-block-cover-image h2 a,
  .root section.wp-block-cover-image h2 a:active,
  .root section.wp-block-cover-image h2 a:focus,
  .root section.wp-block-cover-image h2 a:hover {
    color: #fff; }
  .root .wp-block-cover-image .wp-block-cover.has-left-content {
    justify-content: flex-start; }
  .root .wp-block-cover-image .wp-block-cover.has-right-content {
    justify-content: flex-end; }
  .root .wp-block-cover-image.has-left-content .wp-block-cover-image-text,
  .root .wp-block-cover.has-left-content .wp-block-cover-text,
  .root section.wp-block-cover-image.has-left-content > h2 {
    margin-left: 0;
    text-align: left; }
  .root .wp-block-cover-image.has-right-content .wp-block-cover-image-text,
  .root .wp-block-cover.has-right-content .wp-block-cover-text,
  .root section.wp-block-cover-image.has-right-content > h2 {
    margin-right: 0;
    text-align: right; }
  .root .wp-block-cover-image .wp-block-cover-image-text,
  .root .wp-block-cover .wp-block-cover-text,
  .root section.wp-block-cover-image > h2 {
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 610px;
    padding: 14px;
    text-align: center; }
  .root .block-editor-block-list__block[data-type='core/embed'][data-align='left']
.block-editor-block-list__block-edit,
  .root .block-editor-block-list__block[data-type='core/embed'][data-align='right']
.block-editor-block-list__block-edit,
  .root .wp-block-embed.alignleft,
  .root .wp-block-embed.alignright {
    max-width: 360px;
    width: 100%; }
  .root .wp-block-embed {
    margin-bottom: 1em; }
  .root .wp-block-embed figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper {
    position: relative; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper:before {
    content: '';
    display: block;
    padding-top: 50%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper
iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper:before {
    padding-top: 42.85%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper:before {
    padding-top: 50%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper:before {
    padding-top: 56.25%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper:before {
    padding-top: 75%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper:before {
    padding-top: 100%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper:before {
    padding-top: 177.78%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper:before {
    padding-top: 200%; }
  .root .wp-block-file {
    margin-bottom: 1.5em; }
  .root .wp-block-file.aligncenter {
    text-align: center; }
  .root .wp-block-file.alignright {
    text-align: right; }
  .root .wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: 0.5em 1em; }
  .root .wp-block-file a.wp-block-file__button {
    text-decoration: none; }
  .root .wp-block-file a.wp-block-file__button:active,
  .root .wp-block-file a.wp-block-file__button:focus,
  .root .wp-block-file a.wp-block-file__button:hover,
  .root .wp-block-file a.wp-block-file__button:visited {
    box-shadow: none;
    color: #fff;
    opacity: 0.85;
    text-decoration: none; }
  .root .wp-block-file * + .wp-block-file__button {
    margin-left: 0.75em; }
  .root .blocks-gallery-grid,
  .root .wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .root .blocks-gallery-grid .blocks-gallery-image,
  .root .blocks-gallery-grid .blocks-gallery-item,
  .root .wp-block-gallery .blocks-gallery-image,
  .root .wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .root .blocks-gallery-grid .blocks-gallery-image figure,
  .root .blocks-gallery-grid .blocks-gallery-item figure,
  .root .wp-block-gallery .blocks-gallery-image figure,
  .root .wp-block-gallery .blocks-gallery-item figure {
    margin: 0;
    height: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid .blocks-gallery-image figure,
    .root .blocks-gallery-grid .blocks-gallery-item figure,
    .root .wp-block-gallery .blocks-gallery-image figure,
    .root .wp-block-gallery .blocks-gallery-item figure {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start; } }
  .root .blocks-gallery-grid .blocks-gallery-image img,
  .root .blocks-gallery-grid .blocks-gallery-item img,
  .root .wp-block-gallery .blocks-gallery-image img,
  .root .wp-block-gallery .blocks-gallery-item img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid .blocks-gallery-image img,
    .root .blocks-gallery-grid .blocks-gallery-item img,
    .root .wp-block-gallery .blocks-gallery-image img,
    .root .wp-block-gallery .blocks-gallery-item img {
      width: auto; } }
  .root .blocks-gallery-grid .blocks-gallery-image figcaption,
  .root .blocks-gallery-grid .blocks-gallery-item figcaption,
  .root .wp-block-gallery .blocks-gallery-image figcaption,
  .root .wp-block-gallery .blocks-gallery-item figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 9px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 70%, transparent); }
  .root .blocks-gallery-grid .blocks-gallery-image figcaption img,
  .root .blocks-gallery-grid .blocks-gallery-item figcaption img,
  .root .wp-block-gallery .blocks-gallery-image figcaption img,
  .root .wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline; }
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-item img,
  .root .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .root .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .root .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .root .wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-item img,
    .root .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .root .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .root .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .root .wp-block-gallery.is-cropped .blocks-gallery-item img {
      height: 100%;
      flex: 1;
      -o-object-fit: cover;
      object-fit: cover; } }
  .root .blocks-gallery-grid .blocks-gallery-image,
  .root .blocks-gallery-grid .blocks-gallery-item,
  .root .wp-block-gallery .blocks-gallery-image,
  .root .wp-block-gallery .blocks-gallery-item {
    width: calc((100% - 16px) / 2); }
  .root .blocks-gallery-grid .blocks-gallery-image:nth-of-type(2n),
  .root .blocks-gallery-grid .blocks-gallery-item:nth-of-type(2n),
  .root .wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
  .root .wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0; }
  .root .blocks-gallery-grid.columns-1 .blocks-gallery-image,
  .root .blocks-gallery-grid.columns-1 .blocks-gallery-item,
  .root .wp-block-gallery.columns-1 .blocks-gallery-image,
  .root .wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0; }
  @media (min-width: 600px) {
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-item,
    .root .wp-block-gallery.columns-3 .blocks-gallery-image,
    .root .wp-block-gallery.columns-3 .blocks-gallery-item {
      width: calc((100% - 32px) / 3);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-3 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-3 .blocks-gallery-item,
      .root .wp-block-gallery.columns-3 .blocks-gallery-image,
      .root .wp-block-gallery.columns-3 .blocks-gallery-item {
        width: calc((100% - 32px) / 3 - 1px); } }
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-item,
    .root .wp-block-gallery.columns-4 .blocks-gallery-image,
    .root .wp-block-gallery.columns-4 .blocks-gallery-item {
      width: calc((100% - 48px) / 4);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-4 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-4 .blocks-gallery-item,
      .root .wp-block-gallery.columns-4 .blocks-gallery-image,
      .root .wp-block-gallery.columns-4 .blocks-gallery-item {
        width: calc((100% - 48px) / 4 - 1px); } }
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-item,
    .root .wp-block-gallery.columns-5 .blocks-gallery-image,
    .root .wp-block-gallery.columns-5 .blocks-gallery-item {
      width: calc((100% - 64px) / 5);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-5 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-5 .blocks-gallery-item,
      .root .wp-block-gallery.columns-5 .blocks-gallery-image,
      .root .wp-block-gallery.columns-5 .blocks-gallery-item {
        width: calc((100% - 64px) / 5 - 1px); } }
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-item,
    .root .wp-block-gallery.columns-6 .blocks-gallery-image,
    .root .wp-block-gallery.columns-6 .blocks-gallery-item {
      width: calc((100% - 80px) / 6);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-6 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-6 .blocks-gallery-item,
      .root .wp-block-gallery.columns-6 .blocks-gallery-image,
      .root .wp-block-gallery.columns-6 .blocks-gallery-item {
        width: calc((100% - 80px) / 6 - 1px); } }
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-item,
    .root .wp-block-gallery.columns-7 .blocks-gallery-image,
    .root .wp-block-gallery.columns-7 .blocks-gallery-item {
      width: calc((100% - 96px) / 7);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-7 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-7 .blocks-gallery-item,
      .root .wp-block-gallery.columns-7 .blocks-gallery-image,
      .root .wp-block-gallery.columns-7 .blocks-gallery-item {
        width: calc((100% - 96px) / 7 - 1px); } }
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-item,
    .root .wp-block-gallery.columns-8 .blocks-gallery-image,
    .root .wp-block-gallery.columns-8 .blocks-gallery-item {
      width: calc((100% - 112px) / 8);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-8 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-8 .blocks-gallery-item,
      .root .wp-block-gallery.columns-8 .blocks-gallery-image,
      .root .wp-block-gallery.columns-8 .blocks-gallery-item {
        width: calc((100% - 112px) / 8 - 1px); } }
    .root .blocks-gallery-grid.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .root .blocks-gallery-grid.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .root .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .root .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .root .blocks-gallery-grid.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .root .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .root .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .root .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .root .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .root .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .root .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .root .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .root .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .root .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .root .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .root .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .root .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-item:nth-of-type(8n),
    .root .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .root .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
      margin-right: 0; } }
  .root .blocks-gallery-grid .blocks-gallery-image:last-child,
  .root .blocks-gallery-grid .blocks-gallery-item:last-child,
  .root .wp-block-gallery .blocks-gallery-image:last-child,
  .root .wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0; }
  .root .blocks-gallery-grid.alignleft,
  .root .blocks-gallery-grid.alignright,
  .root .wp-block-gallery.alignleft,
  .root .wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%; }
  .root .blocks-gallery-grid.aligncenter .blocks-gallery-item figure,
  .root .wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center; }
  .root .wp-block-image {
    max-width: 100%;
    margin-bottom: 1em; }
  .root .wp-block-image img {
    max-width: 100%; }
  .root .wp-block-image.aligncenter {
    text-align: center; }
  .root .wp-block-image.alignfull img,
  .root .wp-block-image.alignwide img {
    width: 100%; }
  .root .wp-block-image .aligncenter,
  .root .wp-block-image .alignleft,
  .root .wp-block-image .alignright,
  .root .wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0; }
  .root .wp-block-image .aligncenter > figcaption,
  .root .wp-block-image .alignleft > figcaption,
  .root .wp-block-image .alignright > figcaption,
  .root .wp-block-image.is-resized > figcaption {
    display: table-caption;
    caption-side: bottom; }
  .root .wp-block-image .alignleft {
    float: left;
    margin-right: 1em; }
  .root .wp-block-image .alignright {
    float: right;
    margin-left: 1em; }
  .root .wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto; }
  .root .wp-block-image figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .is-style-circle-mask img {
    border-radius: 9999px; }
  @supports (mask-image: none) or (-webkit-mask-image: none) {
    .root .is-style-circle-mask img {
      mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
      mask-mode: alpha;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      border-radius: none; } }
  .root .wp-block-latest-comments__comment {
    font-size: 15px;
    line-height: 1.1;
    list-style: none;
    margin-bottom: 1em; }
  .root .has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none; }
  .root .has-avatars
.wp-block-latest-comments__comment
.wp-block-latest-comments__comment-excerpt,
  .root .has-avatars
.wp-block-latest-comments__comment
.wp-block-latest-comments__comment-meta {
    margin-left: 52px; }
  .root .has-dates .wp-block-latest-comments__comment,
  .root .has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5; }
  .root .wp-block-latest-comments__comment-excerpt p {
    font-size: 14px;
    line-height: 1.8;
    margin: 5px 0 20px; }
  .root .wp-block-latest-comments__comment-date {
    color: #8f98a1;
    display: block;
    font-size: 12px; }
  .root .wp-block-latest-comments .avatar,
  .root .wp-block-latest-comments__comment-avatar {
    border-radius: 24px;
    display: block;
    float: left;
    height: 40px;
    margin-right: 12px;
    width: 40px; }
  .root .wp-block-latest-posts.alignleft {
    margin-right: 2em; }
  .root .wp-block-latest-posts.alignright {
    margin-left: 2em; }
  .root .wp-block-latest-posts.wp-block-latest-posts__list {
    list-style: none; }
  .root .wp-block-latest-posts.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
  .root .wp-block-latest-posts.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }
  @media (min-width: 600px) {
    .root .wp-block-latest-posts.columns-2 li {
      width: calc(50% - 16px); }
    .root .wp-block-latest-posts.columns-3 li {
      width: calc(33.33333% - 16px); }
    .root .wp-block-latest-posts.columns-4 li {
      width: calc(25% - 16px); }
    .root .wp-block-latest-posts.columns-5 li {
      width: calc(20% - 16px); }
    .root .wp-block-latest-posts.columns-6 li {
      width: calc(16.66667% - 16px); } }
  .root .wp-block-latest-posts__post-date {
    display: block;
    color: #6c7781;
    font-size: 13px; }
  .root .wp-block-latest-posts__post-excerpt {
    margin-top: 8px;
    margin-bottom: 16px; }
  .root .wp-block-media-text {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    -ms-grid-columns: 50% 1fr;
    grid-template-columns: 50% 1fr; }
  .root .wp-block-media-text .has-media-on-the-right {
    -ms-grid-columns: 1fr 50%;
    grid-template-columns: 1fr 50%; }
  .root .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
    -ms-grid-row-align: start;
    align-self: start; }
  .root .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media,
  .root .wp-block-media-text .wp-block-media-text__content,
  .root .wp-block-media-text .wp-block-media-text__media {
    -ms-grid-row-align: center;
    align-self: center; }
  .root .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
    -ms-grid-row-align: end;
    align-self: end; }
  .root .wp-block-media-text .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    margin: 0; }
  .root .wp-block-media-text .wp-block-media-text__content {
    word-break: break-word;
    padding: 0 8%; }
  .root .wp-block-media-text.has-media-on-the-right .wp-block-media-text__media,
  .root .wp-block-media-text .wp-block-media-text__content {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1; }
  .root .wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .root .wp-block-media-text > figure > img,
  .root .wp-block-media-text > figure > video {
    max-width: unset;
    width: 100%;
    vertical-align: middle; }
  .root .wp-block-media-text.is-image-fill figure {
    height: 100%;
    min-height: 250px;
    background-size: cover; }
  .root .wp-block-media-text.is-image-fill figure > img {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }
  @media (max-width: 600px) {
    .root .wp-block-media-text.is-stacked-on-mobile {
      -ms-grid-columns: 100% !important;
      grid-template-columns: 100% !important; }
    .root .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1; }
    .root .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
.wp-block-media-text__media,
    .root .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 2;
      grid-row: 2; }
    .root .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
.wp-block-media-text__content {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1; } }
  .root .is-small-text {
    font-size: 14px; }
  .root .is-regular-text {
    font-size: 16px; }
  .root .is-large-text {
    font-size: 36px; }
  .root .is-larger-text {
    font-size: 48px; }
  .root .has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 8.4em;
    line-height: 0.68;
    font-weight: 100;
    margin: 0.05em 0.1em 0 0;
    text-transform: uppercase;
    font-style: normal; }
  .root .has-drop-cap:not(:focus):after {
    content: '';
    display: table;
    clear: both;
    padding-top: 14px; }
  .root p.has-background {
    padding: 20px 30px; }
  .root p.has-text-color a {
    color: inherit; }
  .root .wp-block-pullquote {
    padding: 3em 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center; }
  .root .wp-block-pullquote.alignleft,
  .root .wp-block-pullquote.alignright {
    max-width: 305px; }
  .root .wp-block-pullquote.alignleft p,
  .root .wp-block-pullquote.alignright p {
    font-size: 20px; }
  .root .wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6; }
  .root .wp-block-pullquote cite,
  .root .wp-block-pullquote footer {
    position: relative; }
  .root .wp-block-pullquote .has-text-color a {
    color: inherit; }
  .root .wp-block-pullquote:not(.is-style-solid-color) {
    background: none; }
  .root .wp-block-pullquote.is-style-solid-color {
    border: none; }
  .root .wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%; }
  .root .wp-block-pullquote.is-style-solid-color blockquote p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px; }
  .root .wp-block-pullquote.is-style-solid-color blockquote cite {
    text-transform: none;
    font-style: normal; }
  .root .wp-block-pullquote cite {
    color: inherit; }
  .root .wp-block-quote.is-large,
  .root .wp-block-quote.is-style-large {
    margin: 0 0 16px;
    padding: 0 1em; }
  .root .wp-block-quote.is-large p,
  .root .wp-block-quote.is-style-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6; }
  .root .wp-block-quote.is-large cite,
  .root .wp-block-quote.is-large footer,
  .root .wp-block-quote.is-style-large cite,
  .root .wp-block-quote.is-style-large footer {
    font-size: 18px;
    text-align: right; }
  .root .wp-block-rss.alignleft {
    margin-right: 2em; }
  .root .wp-block-rss.alignright {
    margin-left: 2em; }
  .root .wp-block-rss.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none; }
  .root .wp-block-rss.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }
  @media (min-width: 600px) {
    .root .wp-block-rss.columns-2 li {
      width: calc(50% - 16px); }
    .root .wp-block-rss.columns-3 li {
      width: calc(33.33333% - 16px); }
    .root .wp-block-rss.columns-4 li {
      width: calc(25% - 16px); }
    .root .wp-block-rss.columns-5 li {
      width: calc(20% - 16px); }
    .root .wp-block-rss.columns-6 li {
      width: calc(16.66667% - 16px); } }
  .root .wp-block-rss__item-author,
  .root .wp-block-rss__item-publish-date {
    display: block;
    color: #6c7781;
    font-size: 13px; }
  .root .wp-block-search {
    display: flex;
    flex-wrap: wrap; }
  .root .wp-block-search .wp-block-search__label {
    width: 100%; }
  .root .wp-block-search .wp-block-search__input {
    flex-grow: 1; }
  .root .wp-block-search .wp-block-search__button {
    margin-left: 10px; }
  .root .wp-block-separator.is-style-wide {
    border-bottom-width: 1px; }
  .root .wp-block-separator.is-style-dots {
    background: none !important;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto; }
  .root .wp-block-separator.is-style-dots:before {
    content: '\00b7 \00b7 \00b7';
    color: currentColor;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif; }
  .root .wp-block-social-links {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0; }
  .root .wp-social-link {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 8px; }
  .root .wp-social-link,
  .root .wp-social-link a {
    display: block;
    transition: transform 0.1s ease; }
  .root .wp-social-link a {
    padding: 6px;
    line-height: 0; }
  .root .wp-social-link a,
  .root .wp-social-link a:active,
  .root .wp-social-link a:hover,
  .root .wp-social-link a:visited,
  .root .wp-social-link svg {
    color: currentColor;
    fill: currentColor; }
  .root .wp-social-link:hover {
    transform: scale(1.1); }
  .root .wp-block-social-links.aligncenter {
    justify-content: center;
    display: flex; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
    background-color: #f0f0f0;
    color: #444; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
    background-color: #f90;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
    background-color: #1ea0c3;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
    background-color: #0757fe;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
    background-color: #1e1f26;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
    background-color: #02e49b;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
    background-color: #e94c89;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
    background-color: #4280ff;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
    background-color: #f45800;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
    background-color: #1977f2;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
    background-color: #000;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
    background-color: #0461dd;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
    background-color: #e65678;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
    background-color: #24292d;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
    background-color: #eceadd;
    color: #382110; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
    background-color: #ea4434;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
    background-color: #f00075;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
    background-color: #e21b24;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
    background-color: #0577b5;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
    background-color: #3288d4;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
    background-color: #02ab6c;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
    background-color: #f6405f;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
    background-color: #e60122;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
    background-color: #ef4155;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
    background-color: #fe4500;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
    background-color: #0478d7;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
    background-color: #fefc00;
    color: #fff;
    stroke: #000; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
    background-color: #ff5600;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
    background-color: #1bd760;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
    background-color: #011835;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
    background-color: #6440a4;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
    background-color: #21a1f3;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
    background-color: #1eb7ea;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
    background-color: #4680c2;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
    background-color: #3499cd;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
    background-color: #ff0100;
    color: #fff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link {
    background: none;
    padding: 4px; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link svg {
    width: 28px;
    height: 28px; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
    color: #f90; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
    color: #1ea0c3; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-behance {
    color: #0757fe; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
    color: #1e1f26; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
    color: #02e49b; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
    color: #e94c89; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
    color: #4280ff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
    color: #f45800; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
    color: #1977f2; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
    color: #000; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
    color: #0461dd; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
    color: #e65678; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-github {
    color: #24292d; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
    color: #382110; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-google {
    color: #ea4434; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
    color: #f00075; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
    color: #e21b24; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
    color: #0577b5; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
    color: #3288d4; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-medium {
    color: #02ab6c; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
    color: #f6405f; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
    color: #e60122; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
    color: #ef4155; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
    color: #fe4500; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-skype {
    color: #0478d7; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
    color: #fff;
    stroke: #000; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
    color: #ff5600; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
    color: #1bd760; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
    color: #011835; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
    color: #6440a4; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
    color: #21a1f3; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
    color: #1eb7ea; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-vk {
    color: #4680c2; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
    color: #3499cd; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
    color: #ff0100; }
  .root .wp-block-social-links.is-style-pill-shape .wp-social-link {
    width: auto; }
  .root .wp-block-social-links.is-style-pill-shape .wp-social-link a {
    padding-left: 16px;
    padding-right: 16px; }
  .root .wp-block-spacer {
    clear: both; }
  .root p.wp-block-subhead {
    font-size: 1.1em;
    font-style: italic;
    opacity: 0.75; }
  .root .wp-block-table {
    overflow-x: auto; }
  .root .wp-block-table table {
    width: 100%; }
  .root .wp-block-table .has-fixed-layout {
    table-layout: fixed;
    width: 100%; }
  .root .wp-block-table .has-fixed-layout td,
  .root .wp-block-table .has-fixed-layout th {
    word-break: break-word; }
  .root .wp-block-table.aligncenter,
  .root .wp-block-table.alignleft,
  .root .wp-block-table.alignright {
    display: table;
    width: auto; }
  .root .wp-block-table.aligncenter td,
  .root .wp-block-table.aligncenter th,
  .root .wp-block-table.alignleft td,
  .root .wp-block-table.alignleft th,
  .root .wp-block-table.alignright td,
  .root .wp-block-table.alignright th {
    word-break: break-word; }
  .root .wp-block-table .has-subtle-light-gray-background-color {
    background-color: #f3f4f5; }
  .root .wp-block-table .has-subtle-pale-green-background-color {
    background-color: #e9fbe5; }
  .root .wp-block-table .has-subtle-pale-blue-background-color {
    background-color: #e7f5fe; }
  .root .wp-block-table .has-subtle-pale-pink-background-color {
    background-color: #fcf0ef; }
  .root .wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    border-bottom: 1px solid #f3f4f5; }
  .root .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .root .wp-block-table.is-style-stripes.has-subtle-light-gray-background-color
tbody
tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-green-background-color
tbody
tr:nth-child(odd) {
    background-color: #e9fbe5; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color
tbody
tr:nth-child(odd) {
    background-color: #e7f5fe; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color
tbody
tr:nth-child(odd) {
    background-color: #fcf0ef; }
  .root .wp-block-table.is-style-stripes td,
  .root .wp-block-table.is-style-stripes th {
    border-color: transparent; }
  .root .wp-block-text-columns,
  .root .wp-block-text-columns.aligncenter {
    display: flex; }
  .root .wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0; }
  .root .wp-block-text-columns .wp-block-column:first-child {
    margin-left: 0; }
  .root .wp-block-text-columns .wp-block-column:last-child {
    margin-right: 0; }
  .root .wp-block-text-columns.columns-2 .wp-block-column {
    width: 50%; }
  .root .wp-block-text-columns.columns-3 .wp-block-column {
    width: 33.33333%; }
  .root .wp-block-text-columns.columns-4 .wp-block-column {
    width: 25%; }
  .root pre.wp-block-verse {
    white-space: nowrap;
    overflow: auto; }
  .root .wp-block-video {
    margin-left: 0;
    margin-right: 0; }
  .root .wp-block-video video {
    max-width: 100%; }
  @supports (position: sticky) {
    .root .wp-block-video [poster] {
      -o-object-fit: cover;
      object-fit: cover; } }
  .root .wp-block-video.aligncenter {
    text-align: center; }
  .root .wp-block-video figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root :root .has-pale-pink-background-color {
    background-color: #f78da7; }
  .root :root .has-vivid-red-background-color {
    background-color: #cf2e2e; }
  .root :root .has-luminous-vivid-orange-background-color {
    background-color: #ff6900; }
  .root :root .has-luminous-vivid-amber-background-color {
    background-color: #fcb900; }
  .root :root .has-light-green-cyan-background-color {
    background-color: #7bdcb5; }
  .root :root .has-vivid-green-cyan-background-color {
    background-color: #00d084; }
  .root :root .has-pale-cyan-blue-background-color {
    background-color: #8ed1fc; }
  .root :root .has-vivid-cyan-blue-background-color {
    background-color: #0693e3; }
  .root :root .has-vivid-purple-background-color {
    background-color: #9b51e0; }
  .root :root .has-very-light-gray-background-color {
    background-color: #eee; }
  .root :root .has-cyan-bluish-gray-background-color {
    background-color: #abb8c3; }
  .root :root .has-very-dark-gray-background-color {
    background-color: #313131; }
  .root :root .has-pale-pink-color {
    color: #f78da7; }
  .root :root .has-vivid-red-color {
    color: #cf2e2e; }
  .root :root .has-luminous-vivid-orange-color {
    color: #ff6900; }
  .root :root .has-luminous-vivid-amber-color {
    color: #fcb900; }
  .root :root .has-light-green-cyan-color {
    color: #7bdcb5; }
  .root :root .has-vivid-green-cyan-color {
    color: #00d084; }
  .root :root .has-pale-cyan-blue-color {
    color: #8ed1fc; }
  .root :root .has-vivid-cyan-blue-color {
    color: #0693e3; }
  .root :root .has-vivid-purple-color {
    color: #9b51e0; }
  .root :root .has-very-light-gray-color {
    color: #eee; }
  .root :root .has-cyan-bluish-gray-color {
    color: #abb8c3; }
  .root :root .has-very-dark-gray-color {
    color: #313131; }
  .root .has-small-font-size {
    font-size: 13px; }
  .root .has-normal-font-size,
  .root .has-regular-font-size {
    font-size: 16px; }
  .root .has-medium-font-size {
    font-size: 20px; }
  .root .has-large-font-size {
    font-size: 36px; }
  .root .has-huge-font-size,
  .root .has-larger-font-size {
    font-size: 42px; }
  .root .has-text-align-center {
    text-align: center; }
  .root .has-text-align-left {
    text-align: left; }
  .root .has-text-align-right {
    text-align: right; }
  .root .wp-block-audio figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .wp-block-audio audio {
    width: 100%;
    min-width: 300px; }
  .root .wp-block-button {
    color: #fff; }
  .root .wp-block-button.aligncenter {
    text-align: center; }
  .root .wp-block-button.alignright {
    text-align: right; }
  .root .wp-block-button__link {
    background-color: #32373c;
    border: none;
    border-radius: 28px;
    box-shadow: none;
    color: inherit;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    margin: 0;
    padding: 12px 24px;
    text-align: center;
    text-decoration: none;
    overflow-wrap: break-word; }
  .root .wp-block-button__link:active,
  .root .wp-block-button__link:focus,
  .root .wp-block-button__link:hover,
  .root .wp-block-button__link:visited {
    color: inherit; }
  .root .is-style-squared .wp-block-button__link {
    border-radius: 0; }
  .root .no-border-radius.wp-block-button__link {
    border-radius: 0 !important; }
  .root .is-style-outline {
    color: #32373c; }
  .root .is-style-outline .wp-block-button__link {
    background-color: transparent;
    border: 2px solid; }
  .root .wp-block-calendar {
    text-align: center; }
  .root .wp-block-calendar tbody td,
  .root .wp-block-calendar th {
    padding: 4px;
    border: 1px solid #e2e4e7; }
  .root .wp-block-calendar tfoot td {
    border: none; }
  .root .wp-block-calendar table {
    width: 100%;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif; }
  .root .wp-block-calendar table th {
    font-weight: 400;
    background: #edeff0; }
  .root .wp-block-calendar a {
    text-decoration: underline; }
  .root .wp-block-calendar tfoot a {
    color: #00739c; }
  .root .wp-block-calendar table caption,
  .root .wp-block-calendar table tbody {
    color: #40464d; }
  .root .wp-block-categories.alignleft {
    margin-right: 2em; }
  .root .wp-block-categories.alignright {
    margin-left: 2em; }
  .root .wp-block-columns {
    display: flex;
    margin-bottom: 28px;
    flex-wrap: wrap; }
  @media (min-width: 782px) {
    .root .wp-block-columns {
      flex-wrap: nowrap; } }
  .root .wp-block-column {
    flex-grow: 1;
    min-width: 0;
    word-break: break-word;
    overflow-wrap: break-word; }
  @media (max-width: 599px) {
    .root .wp-block-column {
      flex-basis: 100% !important; } }
  @media (min-width: 600px) {
    .root .wp-block-column {
      flex-basis: calc(50% - 16px);
      flex-grow: 0; }
    .root .wp-block-column:nth-child(2n) {
      margin-left: 32px; } }
  @media (min-width: 782px) {
    .root .wp-block-column:not(:first-child) {
      margin-left: 32px; } }
  .root .wp-block-columns.are-vertically-aligned-top {
    align-items: flex-start; }
  .root .wp-block-columns.are-vertically-aligned-center {
    align-items: center; }
  .root .wp-block-columns.are-vertically-aligned-bottom {
    align-items: flex-end; }
  .root .wp-block-column.is-vertically-aligned-top {
    align-self: flex-start; }
  .root .wp-block-column.is-vertically-aligned-center {
    -ms-grid-row-align: center;
    align-self: center; }
  .root .wp-block-column.is-vertically-aligned-bottom {
    align-self: flex-end; }
  .root .wp-block-cover,
  .root .wp-block-cover-image {
    position: relative;
    background-color: #000;
    background-size: cover;
    background-position: 50%;
    min-height: 430px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; }
  .root .wp-block-cover-image.has-parallax,
  .root .wp-block-cover.has-parallax {
    background-attachment: fixed; }
  @supports (-webkit-overflow-scrolling: touch) {
    .root .wp-block-cover-image.has-parallax,
    .root .wp-block-cover.has-parallax {
      background-attachment: scroll; } }
  @media (prefers-reduced-motion: reduce) {
    .root .wp-block-cover-image.has-parallax,
    .root .wp-block-cover.has-parallax {
      background-attachment: scroll; } }
  .root .wp-block-cover-image.has-background-dim:before,
  .root .wp-block-cover.has-background-dim:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: inherit;
    opacity: 0.5;
    z-index: 1; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-10:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-10:before {
    opacity: 0.1; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-20:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-20:before {
    opacity: 0.2; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-30:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-30:before {
    opacity: 0.3; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-40:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-40:before {
    opacity: 0.4; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-50:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-50:before {
    opacity: 0.5; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-60:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-60:before {
    opacity: 0.6; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-70:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-70:before {
    opacity: 0.7; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-80:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-80:before {
    opacity: 0.8; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-90:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-90:before {
    opacity: 0.9; }
  .root .wp-block-cover-image.has-background-dim.has-background-dim-100:before,
  .root .wp-block-cover.has-background-dim.has-background-dim-100:before {
    opacity: 1; }
  .root .wp-block-cover-image.alignleft,
  .root .wp-block-cover-image.alignright,
  .root .wp-block-cover.alignleft,
  .root .wp-block-cover.alignright {
    max-width: 305px;
    width: 100%; }
  .root .wp-block-cover-image:after,
  .root .wp-block-cover:after {
    display: block;
    content: '';
    font-size: 0;
    min-height: inherit; }
  @supports (position: sticky) {
    .root .wp-block-cover-image:after,
    .root .wp-block-cover:after {
      content: none; } }
  .root .wp-block-cover-image.aligncenter,
  .root .wp-block-cover-image.alignleft,
  .root .wp-block-cover-image.alignright,
  .root .wp-block-cover.aligncenter,
  .root .wp-block-cover.alignleft,
  .root .wp-block-cover.alignright {
    display: flex; }
  .root .wp-block-cover-image .wp-block-cover__inner-container,
  .root .wp-block-cover .wp-block-cover__inner-container {
    width: calc(100% - 70px);
    z-index: 1;
    color: #f8f9f9; }
  .root .wp-block-cover-image .wp-block-subhead:not(.has-text-color),
  .root .wp-block-cover-image h1:not(.has-text-color),
  .root .wp-block-cover-image h2:not(.has-text-color),
  .root .wp-block-cover-image h3:not(.has-text-color),
  .root .wp-block-cover-image h4:not(.has-text-color),
  .root .wp-block-cover-image h5:not(.has-text-color),
  .root .wp-block-cover-image h6:not(.has-text-color),
  .root .wp-block-cover-image p:not(.has-text-color),
  .root .wp-block-cover .wp-block-subhead:not(.has-text-color),
  .root .wp-block-cover h1:not(.has-text-color),
  .root .wp-block-cover h2:not(.has-text-color),
  .root .wp-block-cover h3:not(.has-text-color),
  .root .wp-block-cover h4:not(.has-text-color),
  .root .wp-block-cover h5:not(.has-text-color),
  .root .wp-block-cover h6:not(.has-text-color),
  .root .wp-block-cover p:not(.has-text-color) {
    color: inherit; }
  .root .wp-block-cover__video-background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
    z-index: 0;
    -o-object-fit: cover;
    object-fit: cover; }
  .root .wp-block-cover-image-text,
  .root .wp-block-cover-text,
  .root section.wp-block-cover-image h2 {
    color: #fff; }
  .root .wp-block-cover-image-text a,
  .root .wp-block-cover-image-text a:active,
  .root .wp-block-cover-image-text a:focus,
  .root .wp-block-cover-image-text a:hover,
  .root .wp-block-cover-text a,
  .root .wp-block-cover-text a:active,
  .root .wp-block-cover-text a:focus,
  .root .wp-block-cover-text a:hover,
  .root section.wp-block-cover-image h2 a,
  .root section.wp-block-cover-image h2 a:active,
  .root section.wp-block-cover-image h2 a:focus,
  .root section.wp-block-cover-image h2 a:hover {
    color: #fff; }
  .root .wp-block-cover-image .wp-block-cover.has-left-content {
    justify-content: flex-start; }
  .root .wp-block-cover-image .wp-block-cover.has-right-content {
    justify-content: flex-end; }
  .root .wp-block-cover-image.has-left-content .wp-block-cover-image-text,
  .root .wp-block-cover.has-left-content .wp-block-cover-text,
  .root section.wp-block-cover-image.has-left-content > h2 {
    margin-left: 0;
    text-align: left; }
  .root .wp-block-cover-image.has-right-content .wp-block-cover-image-text,
  .root .wp-block-cover.has-right-content .wp-block-cover-text,
  .root section.wp-block-cover-image.has-right-content > h2 {
    margin-right: 0;
    text-align: right; }
  .root .wp-block-cover-image .wp-block-cover-image-text,
  .root .wp-block-cover .wp-block-cover-text,
  .root section.wp-block-cover-image > h2 {
    font-size: 2em;
    line-height: 1.25;
    z-index: 1;
    margin-bottom: 0;
    max-width: 610px;
    padding: 14px;
    text-align: center; }
  .root .block-editor-block-list__block[data-type='core/embed'][data-align='left']
.block-editor-block-list__block-edit,
  .root .block-editor-block-list__block[data-type='core/embed'][data-align='right']
.block-editor-block-list__block-edit,
  .root .wp-block-embed.alignleft,
  .root .wp-block-embed.alignright {
    max-width: 360px;
    width: 100%; }
  .root .wp-block-embed {
    margin-bottom: 1em; }
  .root .wp-block-embed figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper {
    position: relative; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper:before,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper:before {
    content: '';
    display: block;
    padding-top: 50%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper
iframe,
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper
iframe {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-21-9
.wp-block-embed__wrapper:before {
    padding-top: 42.85%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-18-9
.wp-block-embed__wrapper:before {
    padding-top: 50%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-16-9
.wp-block-embed__wrapper:before {
    padding-top: 56.25%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-4-3
.wp-block-embed__wrapper:before {
    padding-top: 75%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-1
.wp-block-embed__wrapper:before {
    padding-top: 100%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-9-16
.wp-block-embed__wrapper:before {
    padding-top: 177.78%; }
  .root .wp-embed-responsive
.wp-block-embed.wp-embed-aspect-1-2
.wp-block-embed__wrapper:before {
    padding-top: 200%; }
  .root .wp-block-file {
    margin-bottom: 1.5em; }
  .root .wp-block-file.aligncenter {
    text-align: center; }
  .root .wp-block-file.alignright {
    text-align: right; }
  .root .wp-block-file .wp-block-file__button {
    background: #32373c;
    border-radius: 2em;
    color: #fff;
    font-size: 13px;
    padding: 0.5em 1em; }
  .root .wp-block-file a.wp-block-file__button {
    text-decoration: none; }
  .root .wp-block-file a.wp-block-file__button:active,
  .root .wp-block-file a.wp-block-file__button:focus,
  .root .wp-block-file a.wp-block-file__button:hover,
  .root .wp-block-file a.wp-block-file__button:visited {
    box-shadow: none;
    color: #fff;
    opacity: 0.85;
    text-decoration: none; }
  .root .wp-block-file * + .wp-block-file__button {
    margin-left: 0.75em; }
  .root .blocks-gallery-grid,
  .root .wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .root .blocks-gallery-grid .blocks-gallery-image,
  .root .blocks-gallery-grid .blocks-gallery-item,
  .root .wp-block-gallery .blocks-gallery-image,
  .root .wp-block-gallery .blocks-gallery-item {
    margin: 0 16px 16px 0;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .root .blocks-gallery-grid .blocks-gallery-image figure,
  .root .blocks-gallery-grid .blocks-gallery-item figure,
  .root .wp-block-gallery .blocks-gallery-image figure,
  .root .wp-block-gallery .blocks-gallery-item figure {
    margin: 0;
    height: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid .blocks-gallery-image figure,
    .root .blocks-gallery-grid .blocks-gallery-item figure,
    .root .wp-block-gallery .blocks-gallery-image figure,
    .root .wp-block-gallery .blocks-gallery-item figure {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start; } }
  .root .blocks-gallery-grid .blocks-gallery-image img,
  .root .blocks-gallery-grid .blocks-gallery-item img,
  .root .wp-block-gallery .blocks-gallery-image img,
  .root .wp-block-gallery .blocks-gallery-item img {
    display: block;
    max-width: 100%;
    height: auto;
    width: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid .blocks-gallery-image img,
    .root .blocks-gallery-grid .blocks-gallery-item img,
    .root .wp-block-gallery .blocks-gallery-image img,
    .root .wp-block-gallery .blocks-gallery-item img {
      width: auto; } }
  .root .blocks-gallery-grid .blocks-gallery-image figcaption,
  .root .blocks-gallery-grid .blocks-gallery-item figcaption,
  .root .wp-block-gallery .blocks-gallery-image figcaption,
  .root .wp-block-gallery .blocks-gallery-item figcaption {
    position: absolute;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    overflow: auto;
    padding: 40px 10px 9px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 70%, transparent); }
  .root .blocks-gallery-grid .blocks-gallery-image figcaption img,
  .root .blocks-gallery-grid .blocks-gallery-item figcaption img,
  .root .wp-block-gallery .blocks-gallery-image figcaption img,
  .root .wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline; }
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
  .root .blocks-gallery-grid.is-cropped .blocks-gallery-item img,
  .root .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .root .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .root .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .root .wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%; }
  @supports (position: sticky) {
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-image a,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-image img,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-item a,
    .root .blocks-gallery-grid.is-cropped .blocks-gallery-item img,
    .root .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .root .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .root .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .root .wp-block-gallery.is-cropped .blocks-gallery-item img {
      height: 100%;
      flex: 1;
      -o-object-fit: cover;
      object-fit: cover; } }
  .root .blocks-gallery-grid .blocks-gallery-image,
  .root .blocks-gallery-grid .blocks-gallery-item,
  .root .wp-block-gallery .blocks-gallery-image,
  .root .wp-block-gallery .blocks-gallery-item {
    width: calc((100% - 16px) / 2); }
  .root .blocks-gallery-grid .blocks-gallery-image:nth-of-type(2n),
  .root .blocks-gallery-grid .blocks-gallery-item:nth-of-type(2n),
  .root .wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
  .root .wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0; }
  .root .blocks-gallery-grid.columns-1 .blocks-gallery-image,
  .root .blocks-gallery-grid.columns-1 .blocks-gallery-item,
  .root .wp-block-gallery.columns-1 .blocks-gallery-image,
  .root .wp-block-gallery.columns-1 .blocks-gallery-item {
    width: 100%;
    margin-right: 0; }
  @media (min-width: 600px) {
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-item,
    .root .wp-block-gallery.columns-3 .blocks-gallery-image,
    .root .wp-block-gallery.columns-3 .blocks-gallery-item {
      width: calc((100% - 32px) / 3);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-3 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-3 .blocks-gallery-item,
      .root .wp-block-gallery.columns-3 .blocks-gallery-image,
      .root .wp-block-gallery.columns-3 .blocks-gallery-item {
        width: calc((100% - 32px) / 3 - 1px); } }
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-item,
    .root .wp-block-gallery.columns-4 .blocks-gallery-image,
    .root .wp-block-gallery.columns-4 .blocks-gallery-item {
      width: calc((100% - 48px) / 4);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-4 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-4 .blocks-gallery-item,
      .root .wp-block-gallery.columns-4 .blocks-gallery-image,
      .root .wp-block-gallery.columns-4 .blocks-gallery-item {
        width: calc((100% - 48px) / 4 - 1px); } }
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-item,
    .root .wp-block-gallery.columns-5 .blocks-gallery-image,
    .root .wp-block-gallery.columns-5 .blocks-gallery-item {
      width: calc((100% - 64px) / 5);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-5 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-5 .blocks-gallery-item,
      .root .wp-block-gallery.columns-5 .blocks-gallery-image,
      .root .wp-block-gallery.columns-5 .blocks-gallery-item {
        width: calc((100% - 64px) / 5 - 1px); } }
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-item,
    .root .wp-block-gallery.columns-6 .blocks-gallery-image,
    .root .wp-block-gallery.columns-6 .blocks-gallery-item {
      width: calc((100% - 80px) / 6);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-6 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-6 .blocks-gallery-item,
      .root .wp-block-gallery.columns-6 .blocks-gallery-image,
      .root .wp-block-gallery.columns-6 .blocks-gallery-item {
        width: calc((100% - 80px) / 6 - 1px); } }
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-item,
    .root .wp-block-gallery.columns-7 .blocks-gallery-image,
    .root .wp-block-gallery.columns-7 .blocks-gallery-item {
      width: calc((100% - 96px) / 7);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-7 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-7 .blocks-gallery-item,
      .root .wp-block-gallery.columns-7 .blocks-gallery-image,
      .root .wp-block-gallery.columns-7 .blocks-gallery-item {
        width: calc((100% - 96px) / 7 - 1px); } }
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-image,
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-item,
    .root .wp-block-gallery.columns-8 .blocks-gallery-image,
    .root .wp-block-gallery.columns-8 .blocks-gallery-item {
      width: calc((100% - 112px) / 8);
      margin-right: 16px; }
    @supports (-ms-ime-align: auto) {
      .root .blocks-gallery-grid.columns-8 .blocks-gallery-image,
      .root .blocks-gallery-grid.columns-8 .blocks-gallery-item,
      .root .wp-block-gallery.columns-8 .blocks-gallery-image,
      .root .wp-block-gallery.columns-8 .blocks-gallery-item {
        width: calc((100% - 112px) / 8 - 1px); } }
    .root .blocks-gallery-grid.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .root .blocks-gallery-grid.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .root .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .root .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .root .blocks-gallery-grid.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .root .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .root .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .root .blocks-gallery-grid.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .root .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .root .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .root .blocks-gallery-grid.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .root .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .root .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .root .blocks-gallery-grid.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .root .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .root .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .root .blocks-gallery-grid.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .root .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .root .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .root .blocks-gallery-grid.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .root .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .root .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n) {
      margin-right: 0; }
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .root .blocks-gallery-grid.columns-8 .blocks-gallery-item:nth-of-type(8n),
    .root .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .root .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
      margin-right: 0; } }
  .root .blocks-gallery-grid .blocks-gallery-image:last-child,
  .root .blocks-gallery-grid .blocks-gallery-item:last-child,
  .root .wp-block-gallery .blocks-gallery-image:last-child,
  .root .wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0; }
  .root .blocks-gallery-grid.alignleft,
  .root .blocks-gallery-grid.alignright,
  .root .wp-block-gallery.alignleft,
  .root .wp-block-gallery.alignright {
    max-width: 305px;
    width: 100%; }
  .root .blocks-gallery-grid.aligncenter .blocks-gallery-item figure,
  .root .wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center; }
  .root .wp-block-image {
    max-width: 100%;
    margin-bottom: 1em; }
  .root .wp-block-image img {
    max-width: 100%; }
  .root .wp-block-image.aligncenter {
    text-align: center; }
  .root .wp-block-image.alignfull img,
  .root .wp-block-image.alignwide img {
    width: 100%; }
  .root .wp-block-image .aligncenter,
  .root .wp-block-image .alignleft,
  .root .wp-block-image .alignright,
  .root .wp-block-image.is-resized {
    display: table;
    margin-left: 0;
    margin-right: 0; }
  .root .wp-block-image .aligncenter > figcaption,
  .root .wp-block-image .alignleft > figcaption,
  .root .wp-block-image .alignright > figcaption,
  .root .wp-block-image.is-resized > figcaption {
    display: table-caption;
    caption-side: bottom; }
  .root .wp-block-image .alignleft {
    float: left;
    margin-right: 1em; }
  .root .wp-block-image .alignright {
    float: right;
    margin-left: 1em; }
  .root .wp-block-image .aligncenter {
    margin-left: auto;
    margin-right: auto; }
  .root .wp-block-image figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root .is-style-circle-mask img {
    border-radius: 9999px; }
  @supports (mask-image: none) or (-webkit-mask-image: none) {
    .root .is-style-circle-mask img {
      mask-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"/></svg>');
      mask-mode: alpha;
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      border-radius: none; } }
  .root .wp-block-latest-comments__comment {
    font-size: 15px;
    line-height: 1.1;
    list-style: none;
    margin-bottom: 1em; }
  .root .has-avatars .wp-block-latest-comments__comment {
    min-height: 36px;
    list-style: none; }
  .root .has-avatars
.wp-block-latest-comments__comment
.wp-block-latest-comments__comment-excerpt,
  .root .has-avatars
.wp-block-latest-comments__comment
.wp-block-latest-comments__comment-meta {
    margin-left: 52px; }
  .root .has-dates .wp-block-latest-comments__comment,
  .root .has-excerpts .wp-block-latest-comments__comment {
    line-height: 1.5; }
  .root .wp-block-latest-comments__comment-excerpt p {
    font-size: 14px;
    line-height: 1.8;
    margin: 5px 0 20px; }
  .root .wp-block-latest-comments__comment-date {
    color: #8f98a1;
    display: block;
    font-size: 12px; }
  .root .wp-block-latest-comments .avatar,
  .root .wp-block-latest-comments__comment-avatar {
    border-radius: 24px;
    display: block;
    float: left;
    height: 40px;
    margin-right: 12px;
    width: 40px; }
  .root .wp-block-latest-posts.alignleft {
    margin-right: 2em; }
  .root .wp-block-latest-posts.alignright {
    margin-left: 2em; }
  .root .wp-block-latest-posts.wp-block-latest-posts__list {
    list-style: none; }
  .root .wp-block-latest-posts.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0; }
  .root .wp-block-latest-posts.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }
  @media (min-width: 600px) {
    .root .wp-block-latest-posts.columns-2 li {
      width: calc(50% - 16px); }
    .root .wp-block-latest-posts.columns-3 li {
      width: calc(33.33333% - 16px); }
    .root .wp-block-latest-posts.columns-4 li {
      width: calc(25% - 16px); }
    .root .wp-block-latest-posts.columns-5 li {
      width: calc(20% - 16px); }
    .root .wp-block-latest-posts.columns-6 li {
      width: calc(16.66667% - 16px); } }
  .root .wp-block-latest-posts__post-date {
    display: block;
    color: #6c7781;
    font-size: 13px; }
  .root .wp-block-latest-posts__post-excerpt {
    margin-top: 8px;
    margin-bottom: 16px; }
  .root .wp-block-media-text {
    display: -ms-grid;
    display: grid;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    -ms-grid-columns: 50% 1fr;
    grid-template-columns: 50% 1fr; }
  .root .wp-block-media-text .has-media-on-the-right {
    -ms-grid-columns: 1fr 50%;
    grid-template-columns: 1fr 50%; }
  .root .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-top .wp-block-media-text__media {
    -ms-grid-row-align: start;
    align-self: start; }
  .root .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-center .wp-block-media-text__media,
  .root .wp-block-media-text .wp-block-media-text__content,
  .root .wp-block-media-text .wp-block-media-text__media {
    -ms-grid-row-align: center;
    align-self: center; }
  .root .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__content,
  .root .wp-block-media-text.is-vertically-aligned-bottom .wp-block-media-text__media {
    -ms-grid-row-align: end;
    align-self: end; }
  .root .wp-block-media-text .wp-block-media-text__media {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1;
    margin: 0; }
  .root .wp-block-media-text .wp-block-media-text__content {
    word-break: break-word;
    padding: 0 8%; }
  .root .wp-block-media-text.has-media-on-the-right .wp-block-media-text__media,
  .root .wp-block-media-text .wp-block-media-text__content {
    -ms-grid-column: 2;
    grid-column: 2;
    -ms-grid-row: 1;
    grid-row: 1; }
  .root .wp-block-media-text.has-media-on-the-right .wp-block-media-text__content {
    -ms-grid-column: 1;
    grid-column: 1;
    -ms-grid-row: 1;
    grid-row: 1; }
  .root .wp-block-media-text > figure > img,
  .root .wp-block-media-text > figure > video {
    max-width: unset;
    width: 100%;
    vertical-align: middle; }
  .root .wp-block-media-text.is-image-fill figure {
    height: 100%;
    min-height: 250px;
    background-size: cover; }
  .root .wp-block-media-text.is-image-fill figure > img {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }
  @media (max-width: 600px) {
    .root .wp-block-media-text.is-stacked-on-mobile {
      -ms-grid-columns: 100% !important;
      grid-template-columns: 100% !important; }
    .root .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__media {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1; }
    .root .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
.wp-block-media-text__media,
    .root .wp-block-media-text.is-stacked-on-mobile .wp-block-media-text__content {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 2;
      grid-row: 2; }
    .root .wp-block-media-text.is-stacked-on-mobile.has-media-on-the-right
.wp-block-media-text__content {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1; } }
  .root .is-small-text {
    font-size: 14px; }
  .root .is-regular-text {
    font-size: 16px; }
  .root .is-large-text {
    font-size: 36px; }
  .root .is-larger-text {
    font-size: 48px; }
  .root .has-drop-cap:not(:focus):first-letter {
    float: left;
    font-size: 8.4em;
    line-height: 0.68;
    font-weight: 100;
    margin: 0.05em 0.1em 0 0;
    text-transform: uppercase;
    font-style: normal; }
  .root .has-drop-cap:not(:focus):after {
    content: '';
    display: table;
    clear: both;
    padding-top: 14px; }
  .root p.has-background {
    padding: 20px 30px; }
  .root p.has-text-color a {
    color: inherit; }
  .root .wp-block-pullquote {
    padding: 3em 0;
    margin-left: 0;
    margin-right: 0;
    text-align: center; }
  .root .wp-block-pullquote.alignleft,
  .root .wp-block-pullquote.alignright {
    max-width: 305px; }
  .root .wp-block-pullquote.alignleft p,
  .root .wp-block-pullquote.alignright p {
    font-size: 20px; }
  .root .wp-block-pullquote p {
    font-size: 28px;
    line-height: 1.6; }
  .root .wp-block-pullquote cite,
  .root .wp-block-pullquote footer {
    position: relative; }
  .root .wp-block-pullquote .has-text-color a {
    color: inherit; }
  .root .wp-block-pullquote:not(.is-style-solid-color) {
    background: none; }
  .root .wp-block-pullquote.is-style-solid-color {
    border: none; }
  .root .wp-block-pullquote.is-style-solid-color blockquote {
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    max-width: 60%; }
  .root .wp-block-pullquote.is-style-solid-color blockquote p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px; }
  .root .wp-block-pullquote.is-style-solid-color blockquote cite {
    text-transform: none;
    font-style: normal; }
  .root .wp-block-pullquote cite {
    color: inherit; }
  .root .wp-block-quote.is-large,
  .root .wp-block-quote.is-style-large {
    margin: 0 0 16px;
    padding: 0 1em; }
  .root .wp-block-quote.is-large p,
  .root .wp-block-quote.is-style-large p {
    font-size: 24px;
    font-style: italic;
    line-height: 1.6; }
  .root .wp-block-quote.is-large cite,
  .root .wp-block-quote.is-large footer,
  .root .wp-block-quote.is-style-large cite,
  .root .wp-block-quote.is-style-large footer {
    font-size: 18px;
    text-align: right; }
  .root .wp-block-rss.alignleft {
    margin-right: 2em; }
  .root .wp-block-rss.alignright {
    margin-left: 2em; }
  .root .wp-block-rss.is-grid {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    list-style: none; }
  .root .wp-block-rss.is-grid li {
    margin: 0 16px 16px 0;
    width: 100%; }
  @media (min-width: 600px) {
    .root .wp-block-rss.columns-2 li {
      width: calc(50% - 16px); }
    .root .wp-block-rss.columns-3 li {
      width: calc(33.33333% - 16px); }
    .root .wp-block-rss.columns-4 li {
      width: calc(25% - 16px); }
    .root .wp-block-rss.columns-5 li {
      width: calc(20% - 16px); }
    .root .wp-block-rss.columns-6 li {
      width: calc(16.66667% - 16px); } }
  .root .wp-block-rss__item-author,
  .root .wp-block-rss__item-publish-date {
    display: block;
    color: #6c7781;
    font-size: 13px; }
  .root .wp-block-search {
    display: flex;
    flex-wrap: wrap; }
  .root .wp-block-search .wp-block-search__label {
    width: 100%; }
  .root .wp-block-search .wp-block-search__input {
    flex-grow: 1; }
  .root .wp-block-search .wp-block-search__button {
    margin-left: 10px; }
  .root .wp-block-separator.is-style-wide {
    border-bottom-width: 1px; }
  .root .wp-block-separator.is-style-dots {
    background: none !important;
    border: none;
    text-align: center;
    max-width: none;
    line-height: 1;
    height: auto; }
  .root .wp-block-separator.is-style-dots:before {
    content: '\00b7 \00b7 \00b7';
    color: currentColor;
    font-size: 20px;
    letter-spacing: 2em;
    padding-left: 2em;
    font-family: serif; }
  .root .wp-block-social-links {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0; }
  .root .wp-social-link {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    margin-right: 8px; }
  .root .wp-social-link,
  .root .wp-social-link a {
    display: block;
    transition: transform 0.1s ease; }
  .root .wp-social-link a {
    padding: 6px;
    line-height: 0; }
  .root .wp-social-link a,
  .root .wp-social-link a:active,
  .root .wp-social-link a:hover,
  .root .wp-social-link a:visited,
  .root .wp-social-link svg {
    color: currentColor;
    fill: currentColor; }
  .root .wp-social-link:hover {
    transform: scale(1.1); }
  .root .wp-block-social-links.aligncenter {
    justify-content: center;
    display: flex; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link {
    background-color: #f0f0f0;
    color: #444; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-amazon {
    background-color: #f90;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-bandcamp {
    background-color: #1ea0c3;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-behance {
    background-color: #0757fe;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-codepen {
    background-color: #1e1f26;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-deviantart {
    background-color: #02e49b;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dribbble {
    background-color: #e94c89;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-dropbox {
    background-color: #4280ff;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-etsy {
    background-color: #f45800;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-facebook {
    background-color: #1977f2;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-fivehundredpx {
    background-color: #000;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-flickr {
    background-color: #0461dd;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-foursquare {
    background-color: #e65678;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-github {
    background-color: #24292d;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-goodreads {
    background-color: #eceadd;
    color: #382110; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-google {
    background-color: #ea4434;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-instagram {
    background-color: #f00075;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-lastfm {
    background-color: #e21b24;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-linkedin {
    background-color: #0577b5;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-mastodon {
    background-color: #3288d4;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-medium {
    background-color: #02ab6c;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-meetup {
    background-color: #f6405f;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pinterest {
    background-color: #e60122;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-pocket {
    background-color: #ef4155;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-reddit {
    background-color: #fe4500;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-skype {
    background-color: #0478d7;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-snapchat {
    background-color: #fefc00;
    color: #fff;
    stroke: #000; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-soundcloud {
    background-color: #ff5600;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-spotify {
    background-color: #1bd760;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-tumblr {
    background-color: #011835;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitch {
    background-color: #6440a4;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-twitter {
    background-color: #21a1f3;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vimeo {
    background-color: #1eb7ea;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-vk {
    background-color: #4680c2;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-wordpress {
    background-color: #3499cd;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff; }
  .root .wp-block-social-links:not(.is-style-logos-only) .wp-social-link-youtube {
    background-color: #ff0100;
    color: #fff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link {
    background: none;
    padding: 4px; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link svg {
    width: 28px;
    height: 28px; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-amazon {
    color: #f90; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-bandcamp {
    color: #1ea0c3; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-behance {
    color: #0757fe; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-codepen {
    color: #1e1f26; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-deviantart {
    color: #02e49b; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-dribbble {
    color: #e94c89; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-dropbox {
    color: #4280ff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-etsy {
    color: #f45800; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-facebook {
    color: #1977f2; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-fivehundredpx {
    color: #000; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-flickr {
    color: #0461dd; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-foursquare {
    color: #e65678; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-github {
    color: #24292d; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-goodreads {
    color: #382110; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-google {
    color: #ea4434; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-instagram {
    color: #f00075; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-lastfm {
    color: #e21b24; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-linkedin {
    color: #0577b5; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-mastodon {
    color: #3288d4; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-medium {
    color: #02ab6c; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-meetup {
    color: #f6405f; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-pinterest {
    color: #e60122; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-pocket {
    color: #ef4155; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-reddit {
    color: #fe4500; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-skype {
    color: #0478d7; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-snapchat {
    color: #fff;
    stroke: #000; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-soundcloud {
    color: #ff5600; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-spotify {
    color: #1bd760; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-tumblr {
    color: #011835; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-twitch {
    color: #6440a4; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-twitter {
    color: #21a1f3; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-vimeo {
    color: #1eb7ea; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-vk {
    color: #4680c2; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-wordpress {
    color: #3499cd; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-yelp {
    background-color: #d32422;
    color: #fff; }
  .root .wp-block-social-links.is-style-logos-only .wp-social-link-youtube {
    color: #ff0100; }
  .root .wp-block-social-links.is-style-pill-shape .wp-social-link {
    width: auto; }
  .root .wp-block-social-links.is-style-pill-shape .wp-social-link a {
    padding-left: 16px;
    padding-right: 16px; }
  .root .wp-block-spacer {
    clear: both; }
  .root p.wp-block-subhead {
    font-size: 1.1em;
    font-style: italic;
    opacity: 0.75; }
  .root .wp-block-table {
    overflow-x: auto; }
  .root .wp-block-table table {
    width: 100%; }
  .root .wp-block-table .has-fixed-layout {
    table-layout: fixed;
    width: 100%; }
  .root .wp-block-table .has-fixed-layout td,
  .root .wp-block-table .has-fixed-layout th {
    word-break: break-word; }
  .root .wp-block-table.aligncenter,
  .root .wp-block-table.alignleft,
  .root .wp-block-table.alignright {
    display: table;
    width: auto; }
  .root .wp-block-table.aligncenter td,
  .root .wp-block-table.aligncenter th,
  .root .wp-block-table.alignleft td,
  .root .wp-block-table.alignleft th,
  .root .wp-block-table.alignright td,
  .root .wp-block-table.alignright th {
    word-break: break-word; }
  .root .wp-block-table .has-subtle-light-gray-background-color {
    background-color: #f3f4f5; }
  .root .wp-block-table .has-subtle-pale-green-background-color {
    background-color: #e9fbe5; }
  .root .wp-block-table .has-subtle-pale-blue-background-color {
    background-color: #e7f5fe; }
  .root .wp-block-table .has-subtle-pale-pink-background-color {
    background-color: #fcf0ef; }
  .root .wp-block-table.is-style-stripes {
    border-spacing: 0;
    border-collapse: inherit;
    background-color: transparent;
    border-bottom: 1px solid #f3f4f5; }
  .root .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .root .wp-block-table.is-style-stripes.has-subtle-light-gray-background-color
tbody
tr:nth-child(odd) {
    background-color: #f3f4f5; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-green-background-color
tbody
tr:nth-child(odd) {
    background-color: #e9fbe5; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-blue-background-color
tbody
tr:nth-child(odd) {
    background-color: #e7f5fe; }
  .root .wp-block-table.is-style-stripes.has-subtle-pale-pink-background-color
tbody
tr:nth-child(odd) {
    background-color: #fcf0ef; }
  .root .wp-block-table.is-style-stripes td,
  .root .wp-block-table.is-style-stripes th {
    border-color: transparent; }
  .root .wp-block-text-columns,
  .root .wp-block-text-columns.aligncenter {
    display: flex; }
  .root .wp-block-text-columns .wp-block-column {
    margin: 0 16px;
    padding: 0; }
  .root .wp-block-text-columns .wp-block-column:first-child {
    margin-left: 0; }
  .root .wp-block-text-columns .wp-block-column:last-child {
    margin-right: 0; }
  .root .wp-block-text-columns.columns-2 .wp-block-column {
    width: 50%; }
  .root .wp-block-text-columns.columns-3 .wp-block-column {
    width: 33.33333%; }
  .root .wp-block-text-columns.columns-4 .wp-block-column {
    width: 25%; }
  .root pre.wp-block-verse {
    white-space: nowrap;
    overflow: auto; }
  .root .wp-block-video {
    margin-left: 0;
    margin-right: 0; }
  .root .wp-block-video video {
    max-width: 100%; }
  @supports (position: sticky) {
    .root .wp-block-video [poster] {
      -o-object-fit: cover;
      object-fit: cover; } }
  .root .wp-block-video.aligncenter {
    text-align: center; }
  .root .wp-block-video figcaption {
    margin-top: 0.5em;
    margin-bottom: 1em; }
  .root :root .has-pale-pink-background-color {
    background-color: #f78da7; }
  .root :root .has-vivid-red-background-color {
    background-color: #cf2e2e; }
  .root :root .has-luminous-vivid-orange-background-color {
    background-color: #ff6900; }
  .root :root .has-luminous-vivid-amber-background-color {
    background-color: #fcb900; }
  .root :root .has-light-green-cyan-background-color {
    background-color: #7bdcb5; }
  .root :root .has-vivid-green-cyan-background-color {
    background-color: #00d084; }
  .root :root .has-pale-cyan-blue-background-color {
    background-color: #8ed1fc; }
  .root :root .has-vivid-cyan-blue-background-color {
    background-color: #0693e3; }
  .root :root .has-vivid-purple-background-color {
    background-color: #9b51e0; }
  .root :root .has-very-light-gray-background-color {
    background-color: #eee; }
  .root :root .has-cyan-bluish-gray-background-color {
    background-color: #abb8c3; }
  .root :root .has-very-dark-gray-background-color {
    background-color: #313131; }
  .root :root .has-pale-pink-color {
    color: #f78da7; }
  .root :root .has-vivid-red-color {
    color: #cf2e2e; }
  .root :root .has-luminous-vivid-orange-color {
    color: #ff6900; }
  .root :root .has-luminous-vivid-amber-color {
    color: #fcb900; }
  .root :root .has-light-green-cyan-color {
    color: #7bdcb5; }
  .root :root .has-vivid-green-cyan-color {
    color: #00d084; }
  .root :root .has-pale-cyan-blue-color {
    color: #8ed1fc; }
  .root :root .has-vivid-cyan-blue-color {
    color: #0693e3; }
  .root :root .has-vivid-purple-color {
    color: #9b51e0; }
  .root :root .has-very-light-gray-color {
    color: #eee; }
  .root :root .has-cyan-bluish-gray-color {
    color: #abb8c3; }
  .root :root .has-very-dark-gray-color {
    color: #313131; }
  .root .has-small-font-size {
    font-size: 13px; }
  .root .has-normal-font-size,
  .root .has-regular-font-size {
    font-size: 16px; }
  .root .has-medium-font-size {
    font-size: 20px; }
  .root .has-large-font-size {
    font-size: 36px; }
  .root .has-huge-font-size,
  .root .has-larger-font-size {
    font-size: 42px; }
  .root .has-text-align-center {
    text-align: center; }
  .root .has-text-align-left {
    text-align: left; }
  .root .has-text-align-right {
    text-align: right; }
  .root html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%; }
  .root body {
    margin: 0; }
  .root article,
  .root aside,
  .root details,
  .root figcaption,
  .root figure,
  .root footer,
  .root header,
  .root main,
  .root menu,
  .root nav,
  .root section,
  .root summary {
    display: block; }
  .root audio,
  .root canvas,
  .root progress,
  .root video {
    display: inline-block;
    vertical-align: baseline; }
  .root audio:not([controls]) {
    display: none;
    height: 0; }
  .root [hidden],
  .root template {
    display: none; }
  .root a {
    background-color: transparent; }
  .root abbr[title] {
    border-bottom: 1px dotted; }
  .root b,
  .root strong {
    font-weight: 700; }
  .root small {
    font-size: 80%; }
  .root sub,
  .root sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline; }
  .root sup {
    top: -0.5em; }
  .root sub {
    bottom: -0.25em; }
  .root img {
    border: 0; }
  .root svg:not(:root) {
    overflow: hidden; }
  .root figure {
    margin: 0; }
  .root hr {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box; }
  .root code,
  .root kbd,
  .root pre,
  .root samp {
    font-size: 1em; }
  .root button,
  .root input,
  .root optgroup,
  .root select,
  .root textarea {
    color: inherit;
    font: inherit;
    margin: 0; }
  .root select {
    text-transform: none; }
  .root button {
    overflow: visible; }
  .root button,
  .root input,
  .root select,
  .root textarea {
    max-width: 100%; }
  .root button,
  .root html input[type='button'],
  .root input[type='reset'],
  .root input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer; }
  .root button[disabled],
  .root html input[disabled] {
    cursor: default;
    opacity: 0.5; }
  .root button::-moz-focus-inner,
  .root input::-moz-focus-inner {
    border: 0;
    padding: 0; }
  .root input[type='checkbox'],
  .root input[type='radio'] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    margin-right: 0.4375em;
    padding: 0; }
  .root input[type='date']::-webkit-inner-spin-button,
  .root input[type='date']::-webkit-outer-spin-button,
  .root input[type='time']::-webkit-inner-spin-button,
  .root input[type='time']::-webkit-outer-spin-button,
  .root input[type='datetime-local']::-webkit-inner-spin-button,
  .root input[type='datetime-local']::-webkit-outer-spin-button,
  .root input[type='week']::-webkit-inner-spin-button,
  .root input[type='week']::-webkit-outer-spin-button,
  .root input[type='month']::-webkit-inner-spin-button,
  .root input[type='month']::-webkit-outer-spin-button,
  .root input[type='number']::-webkit-inner-spin-button,
  .root input[type='number']::-webkit-outer-spin-button {
    height: auto; }
  .root input[type='search'] {
    -webkit-appearance: textfield; }
  .root input[type='search']::-webkit-search-cancel-button,
  .root input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none; }
  .root fieldset {
    border: 1px solid #d1d1d1;
    margin: 0 0 1.75em;
    min-width: inherit;
    padding: 0.875em; }
  .root fieldset > :last-child {
    margin-bottom: 0; }
  .root legend {
    border: 0;
    padding: 0; }
  .root textarea {
    overflow: auto;
    vertical-align: top; }
  .root optgroup {
    font-weight: bold; }
  .root .menu-item-has-children a:after,
  .root .social-navigation a:before,
  .root .dropdown-toggle:after,
  .root .bypostauthor > article .fn:after,
  .root .comment-reply-title small a:before,
  .root .pagination .prev:before,
  .root .pagination .next:before,
  .root .pagination .nav-links:before,
  .root .pagination .nav-links:after,
  .root .search-submit:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-family: 'Genericons';
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
    vertical-align: top; }
  .root body,
  .root button,
  .root input,
  .root select,
  .root textarea {
    color: #1a1a1a;
    font-family: Merriweather, Georgia, serif;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.75; }
  .root h1,
  .root h2,
  .root h3,
  .root h4,
  .root h5,
  .root h6 {
    clear: both;
    font-weight: 700;
    margin: 0;
    text-rendering: optimizeLegibility; }
  .root p {
    margin: 0 0 1.75em; }
  .root dfn,
  .root cite,
  .root em,
  .root i {
    font-style: italic; }
  .root blockquote {
    border: 0 solid #1a1a1a;
    border-left-width: 4px;
    color: #686868;
    font-size: 19px;
    font-size: 1.1875rem;
    font-style: italic;
    line-height: 1.4736842105;
    margin: 0 0 1.4736842105em;
    overflow: hidden;
    padding: 0 0 0 1.263157895em; }
  .root blockquote,
  .root q {
    quotes: none; }
  .root blockquote:before,
  .root blockquote:after,
  .root q:before,
  .root q:after {
    content: ''; }
  .root blockquote p {
    margin-bottom: 1.4736842105em; }
  .root blockquote cite,
  .root blockquote small {
    color: #1a1a1a;
    display: block;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.75; }
  .root blockquote cite:before,
  .root blockquote small:before {
    content: '\2014\00a0'; }
  .root blockquote em,
  .root blockquote i,
  .root blockquote cite {
    font-style: normal; }
  .root blockquote strong,
  .root blockquote b {
    font-weight: 400; }
  .root blockquote > :last-child {
    margin-bottom: 0; }
  .root address {
    font-style: italic;
    margin: 0 0 1.75em; }
  .root code,
  .root kbd,
  .root tt,
  .root var,
  .root samp,
  .root pre {
    font-family: Inconsolata, monospace; }
  .root pre {
    border: 1px solid #d1d1d1;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.3125;
    margin: 0 0 1.75em;
    max-width: 100%;
    overflow: auto;
    padding: 1.75em;
    white-space: pre;
    white-space: pre-wrap;
    word-wrap: break-word; }
  .root code {
    background-color: #d1d1d1;
    padding: 0.125em 0.25em; }
  .root abbr,
  .root acronym {
    border-bottom: 1px dotted #d1d1d1;
    cursor: help; }
  .root mark,
  .root ins {
    background: #007acc;
    color: #fff;
    padding: 0.125em 0.25em;
    text-decoration: none; }
  .root big {
    font-size: 125%; }
  .root html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .root *,
  .root *:before,
  .root *:after {
    /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit; }
  .root body {
    background: #1a1a1a;
    /* Fallback for when there is no custom background color defined. */ }
  .root hr {
    background-color: #d1d1d1;
    border: 0;
    height: 1px;
    margin: 0 0 1.75em; }
  .root ul,
  .root ol {
    margin: 0 0 1.75em 1.25em;
    padding: 0; }
  .root ul {
    list-style: disc; }
  .root ol {
    list-style: decimal;
    margin-left: 1.5em; }
  .root li > ul,
  .root li > ol {
    margin-bottom: 0; }
  .root dl {
    margin: 0 0 1.75em; }
  .root dt {
    font-weight: 700; }
  .root dd {
    margin: 0 0 1.75em; }
  .root img {
    height: auto;
    /* Make sure images are scaled correctly. */
    max-width: 100%;
    /* Adhere to container width. */
    vertical-align: middle; }
  .root del {
    opacity: 0.8; }
  .root table,
  .root th,
  .root td {
    border: 1px solid #d1d1d1; }
  .root table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.75em;
    table-layout: fixed;
    /* Prevents HTML tables from becoming too wide */
    width: 100%; }
  .root caption,
  .root th,
  .root td {
    font-weight: normal;
    text-align: left; }
  .root th {
    border-width: 0 1px 1px 0;
    font-weight: 700; }
  .root td {
    border-width: 0 1px 1px 0; }
  .root th,
  .root td {
    padding: 0.4375em; }
  .root ::-webkit-input-placeholder {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif; }
  .root :-moz-placeholder {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif; }
  .root ::-moz-placeholder {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    opacity: 1;
    /* Since FF19 lowers the opacity of the placeholder by default */ }
  .root :-ms-input-placeholder {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif; }
  .root input {
    line-height: normal; }
  .root button,
  .root button[disabled]:hover,
  .root button[disabled]:focus,
  .root input[type='button'],
  .root input[type='button'][disabled]:hover,
  .root input[type='button'][disabled]:focus,
  .root input[type='reset'],
  .root input[type='reset'][disabled]:hover,
  .root input[type='reset'][disabled]:focus,
  .root input[type='submit'],
  .root input[type='submit'][disabled]:hover,
  .root input[type='submit'][disabled]:focus {
    background: #1a1a1a;
    border: 0;
    border-radius: 2px;
    color: #fff;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-weight: 700;
    letter-spacing: 0.046875em;
    line-height: 1;
    padding: 0.84375em 0.875em 0.78125em;
    text-transform: uppercase; }
  .root button:hover,
  .root button:focus,
  .root input[type='button']:hover,
  .root input[type='button']:focus,
  .root input[type='reset']:hover,
  .root input[type='reset']:focus,
  .root input[type='submit']:hover,
  .root input[type='submit']:focus {
    background: #007acc; }
  .root button:focus,
  .root input[type='button']:focus,
  .root input[type='reset']:focus,
  .root input[type='submit']:focus {
    outline: thin dotted;
    outline-offset: -4px; }
  .root input[type='date'],
  .root input[type='time'],
  .root input[type='datetime-local'],
  .root input[type='week'],
  .root input[type='month'],
  .root input[type='text'],
  .root input[type='email'],
  .root input[type='url'],
  .root input[type='password'],
  .root input[type='search'],
  .root input[type='tel'],
  .root input[type='number'],
  .root textarea {
    background: #f7f7f7;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    color: #686868;
    padding: 0.625em 0.4375em;
    width: 100%; }
  .root input[type='date']:focus,
  .root input[type='time']:focus,
  .root input[type='datetime-local']:focus,
  .root input[type='week']:focus,
  .root input[type='month']:focus,
  .root input[type='text']:focus,
  .root input[type='email']:focus,
  .root input[type='url']:focus,
  .root input[type='password']:focus,
  .root input[type='search']:focus,
  .root input[type='tel']:focus,
  .root input[type='number']:focus,
  .root textarea:focus {
    background-color: #fff;
    border-color: #007acc;
    color: #1a1a1a;
    outline: 0; }
  .root .post-password-form {
    margin-bottom: 1.75em; }
  .root .post-password-form label {
    color: #686868;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    letter-spacing: 0.076923077em;
    line-height: 1.6153846154;
    margin-bottom: 1.75em;
    text-transform: uppercase; }
  .root .post-password-form input[type='password'] {
    margin-top: 0.4375em; }
  .root .post-password-form > :last-child {
    margin-bottom: 0; }
  .root .search-form {
    position: relative; }
  .root input[type='search'].search-field {
    border-radius: 2px 0 0 2px;
    width: -webkit-calc(100% - 42px);
    width: calc(100% - 42px); }
  .root .search-submit:before {
    content: '\f400';
    font-size: 24px;
    left: 2px;
    line-height: 42px;
    position: relative;
    width: 40px; }
  .root .search-submit {
    border-radius: 0 2px 2px 0;
    bottom: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 42px; }
  .root a {
    color: #007acc;
    text-decoration: none; }
  .root a:hover,
  .root a:focus,
  .root a:active {
    color: #686868; }
  .root a:focus {
    outline: thin dotted; }
  .root a:hover,
  .root a:active {
    outline: 0; }
  .root .entry-content a,
  .root .entry-summary a,
  .root .taxonomy-description a,
  .root .logged-in-as a,
  .root .comment-content a,
  .root .pingback .comment-body > a,
  .root .textwidget a,
  .root .entry-footer a:hover,
  .root .site-info a:hover {
    box-shadow: 0 1px 0 0 currentColor; }
  .root .entry-content a:hover,
  .root .entry-content a:focus,
  .root .entry-summary a:hover,
  .root .entry-summary a:focus,
  .root .taxonomy-description a:hover,
  .root .taxonomy-description a:focus,
  .root .logged-in-as a:hover,
  .root .logged-in-as a:focus,
  .root .comment-content a:hover,
  .root .comment-content a:focus,
  .root .pingback .comment-body > a:hover,
  .root .pingback .comment-body > a:focus,
  .root .textwidget a:hover,
  .root .textwidget a:focus {
    box-shadow: none; }
  .root .site-header-menu {
    display: none;
    -webkit-flex: 0 1 100%;
    -ms-flex: 0 1 100%;
    flex: 0 1 100%;
    margin: 0.875em 0; }
  .root .site-header-menu.toggled-on,
  .root .no-js .site-header-menu {
    display: block; }
  .root .main-navigation {
    font-family: Montserrat, 'Helvetica Neue', sans-serif; }
  .root .site-footer .main-navigation {
    margin-bottom: 1.75em; }
  .root .main-navigation ul {
    list-style: none;
    margin: 0; }
  .root .main-navigation li {
    border-top: 1px solid #d1d1d1;
    position: relative; }
  .root .main-navigation a {
    color: #1a1a1a;
    display: block;
    line-height: 1.3125;
    outline-offset: -1px;
    padding: 0.84375em 0; }
  .root .main-navigation a:hover,
  .root .main-navigation a:focus {
    color: #007acc; }
  .root .main-navigation .current-menu-item > a,
  .root .main-navigation .current-menu-ancestor > a {
    font-weight: 700; }
  .root .main-navigation ul ul {
    display: none;
    margin-left: 0.875em; }
  .root .no-js .main-navigation ul ul {
    display: block; }
  .root .main-navigation ul .toggled-on {
    display: block; }
  .root .main-navigation .primary-menu {
    border-bottom: 1px solid #d1d1d1; }
  .root .main-navigation .menu-item-has-children > a {
    margin-right: 56px; }
  .root .dropdown-toggle {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    color: #1a1a1a;
    content: '';
    height: 48px;
    padding: 0;
    position: absolute;
    right: 0;
    text-transform: none;
    top: 0;
    width: 48px; }
  .root .dropdown-toggle:after {
    border: 0 solid #d1d1d1;
    border-left-width: 1px;
    content: '\f431';
    font-size: 24px;
    left: 1px;
    position: relative;
    width: 48px; }
  .root .dropdown-toggle:hover,
  .root .dropdown-toggle:focus {
    background-color: transparent;
    color: #007acc; }
  .root .dropdown-toggle:focus {
    outline: thin dotted;
    outline-offset: -1px; }
  .root .dropdown-toggle:focus:after {
    border-color: transparent; }
  .root .dropdown-toggle.toggled-on:after {
    content: '\f432'; }
  .root .site-header .main-navigation + .social-navigation {
    margin-top: 1.75em; }
  .root .site-footer .social-navigation {
    margin-bottom: 1.75em; }
  .root .social-navigation ul {
    list-style: none;
    margin: 0 0 -0.4375em; }
  .root .social-navigation li {
    float: left;
    margin: 0 0.4375em 0.4375em 0; }
  .root .social-navigation a {
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    color: #1a1a1a;
    display: block;
    height: 35px;
    position: relative;
    width: 35px; }
  .root .social-navigation a:before {
    content: '\f415';
    height: 33px;
    line-height: 33px;
    text-align: center;
    width: 33px; }
  .root .social-navigation a:hover:before,
  .root .social-navigation a:focus:before {
    color: #007acc; }
  .root .social-navigation a[href*='codepen.io']:before {
    content: '\f216'; }
  .root .social-navigation a[href*='digg.com']:before {
    content: '\f221'; }
  .root .social-navigation a[href*='dribbble.com']:before {
    content: '\f201'; }
  .root .social-navigation a[href*='dropbox.com']:before {
    content: '\f225'; }
  .root .social-navigation a[href*='facebook.com']:before {
    content: '\f203'; }
  .root .social-navigation a[href*='flickr.com']:before {
    content: '\f211'; }
  .root .social-navigation a[href*='foursquare.com']:before {
    content: '\f226'; }
  .root .social-navigation a[href*='plus.google.com']:before {
    content: '\f206'; }
  .root .social-navigation a[href*='github.com']:before {
    content: '\f200'; }
  .root .social-navigation a[href*='instagram.com']:before {
    content: '\f215'; }
  .root .social-navigation a[href*='linkedin.com']:before {
    content: '\f208'; }
  .root .social-navigation a[href*='path.com']:before {
    content: '\f219'; }
  .root .social-navigation a[href*='pinterest.com']:before {
    content: '\f210'; }
  .root .social-navigation a[href*='getpocket.com']:before {
    content: '\f224'; }
  .root .social-navigation a[href*='polldaddy.com']:before {
    content: '\f217'; }
  .root .social-navigation a[href*='reddit.com']:before {
    content: '\f222'; }
  .root .social-navigation a[href*='skype.com']:before {
    content: '\f220'; }
  .root .social-navigation a[href*='stumbleupon.com']:before {
    content: '\f223'; }
  .root .social-navigation a[href*='tumblr.com']:before {
    content: '\f214'; }
  .root .social-navigation a[href*='twitter.com']:before {
    content: '\f202'; }
  .root .social-navigation a[href*='vimeo.com']:before {
    content: '\f212'; }
  .root .social-navigation a[href*='wordpress.com']:before,
  .root .social-navigation a[href*='wordpress.org']:before {
    content: '\f205'; }
  .root .social-navigation a[href*='youtube.com']:before {
    content: '\f213'; }
  .root .social-navigation a[href^='mailto:']:before {
    content: '\f410'; }
  .root .social-navigation a[href*='spotify.com']:before {
    content: '\f515'; }
  .root .social-navigation a[href*='twitch.tv']:before {
    content: '\f516'; }
  .root .social-navigation a[href$='/feed/']:before {
    content: '\f413'; }
  .root .post-navigation {
    border-top: 4px solid #1a1a1a;
    border-bottom: 4px solid #1a1a1a;
    clear: both;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    margin: 0 7.6923% 3.5em; }
  .root .post-navigation a {
    color: #1a1a1a;
    display: block;
    padding: 1.75em 0; }
  .root .post-navigation span {
    display: block; }
  .root .post-navigation .meta-nav {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    letter-spacing: 0.076923077em;
    line-height: 1.6153846154;
    margin-bottom: 0.5384615385em;
    text-transform: uppercase; }
  .root .post-navigation .post-title {
    display: inline;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 23px;
    font-size: 1.4375rem;
    font-weight: 700;
    line-height: 1.2173913043;
    text-rendering: optimizeLegibility; }
  .root .post-navigation a:hover .post-title,
  .root .post-navigation a:focus .post-title {
    color: #007acc; }
  .root .post-navigation div + div {
    border-top: 4px solid #1a1a1a; }
  .root .pagination {
    border-top: 4px solid #1a1a1a;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 19px;
    font-size: 1.1875rem;
    margin: 0 7.6923% 2.947368421em;
    min-height: 56px;
    position: relative; }
  .root .pagination:before,
  .root .pagination:after {
    background-color: #1a1a1a;
    content: '';
    height: 52px;
    position: absolute;
    top: 0;
    width: 52px;
    z-index: 0; }
  .root .pagination:before {
    right: 0; }
  .root .pagination:after {
    right: 54px; }
  .root .pagination a:hover,
  .root .pagination a:focus {
    color: #1a1a1a; }
  .root .pagination .nav-links {
    padding-right: 106px;
    position: relative; }
  .root .pagination .nav-links:before,
  .root .pagination .nav-links:after {
    color: #fff;
    font-size: 32px;
    line-height: 51px;
    opacity: 0.3;
    position: absolute;
    width: 52px;
    z-index: 1; }
  .root .pagination .nav-links:before {
    content: '\f429';
    right: -1px; }
  .root .pagination .nav-links:after {
    content: '\f430';
    right: 55px; }
  .root .pagination .current .screen-reader-text {
    position: static !important; }
  .root .pagination .page-numbers {
    display: none;
    letter-spacing: 0.013157895em;
    line-height: 1;
    margin: 0 0.7368421053em 0 -0.7368421053em;
    padding: 0.8157894737em 0.7368421053em 0.3947368421em;
    text-transform: uppercase; }
  .root .pagination .current {
    display: inline-block;
    font-weight: 700; }
  .root .pagination .prev,
  .root .pagination .next {
    background-color: #1a1a1a;
    color: #fff;
    display: inline-block;
    height: 52px;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 0;
    width: 52px;
    z-index: 2; }
  .root .pagination .prev:before,
  .root .pagination .next:before {
    font-size: 32px;
    height: 53px;
    line-height: 52px;
    position: relative;
    width: 53px; }
  .root .pagination .prev:hover,
  .root .pagination .prev:focus,
  .root .pagination .next:hover,
  .root .pagination .next:focus {
    background-color: #007acc;
    color: #fff; }
  .root .pagination .prev:focus,
  .root .pagination .next:focus {
    outline: 0; }
  .root .pagination .prev {
    right: 54px; }
  .root .pagination .prev:before {
    content: '\f430';
    left: -1px;
    top: -1px; }
  .root .pagination .next {
    right: 0; }
  .root .pagination .next:before {
    content: '\f429';
    right: -1px;
    top: -1px; }
  .root .image-navigation,
  .root .comment-navigation {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin: 0 7.6923% 2.1538461538em;
    padding: 1.0769230769em 0; }
  .root .comment-navigation {
    margin-right: 0;
    margin-left: 0; }
  .root .comments-title + .comment-navigation {
    border-bottom: 0;
    margin-bottom: 0; }
  .root .image-navigation .nav-previous:not(:empty),
  .root .image-navigation .nav-next:not(:empty),
  .root .comment-navigation .nav-previous:not(:empty),
  .root .comment-navigation .nav-next:not(:empty) {
    display: inline-block; }
  .root .image-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before,
  .root .comment-navigation .nav-previous:not(:empty) + .nav-next:not(:empty):before {
    content: '\002f';
    display: inline-block;
    opacity: 0.7;
    padding: 0 0.538461538em; }
  .root .says,
  .root .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    /* many screen reader and browser combinations announce broken words as they would appear visually */
    word-wrap: normal !important; }
  .root .site .skip-link {
    background-color: #f1f1f1;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
    color: #21759b;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 700;
    left: -9999em;
    outline: none;
    padding: 15px 23px 14px;
    text-decoration: none;
    text-transform: none;
    top: -9999em; }
  .root .logged-in .site .skip-link {
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
    font-family: 'Open Sans', sans-serif; }
  .root .site .skip-link:focus {
    clip: auto;
    height: auto;
    left: 6px;
    top: 7px;
    width: auto;
    z-index: 100000; }
  .root .alignleft {
    float: left;
    margin: 0.375em 1.75em 1.75em 0; }
  .root .alignright {
    float: right;
    margin: 0.375em 0 1.75em 1.75em; }
  .root .aligncenter {
    clear: both;
    display: block;
    margin: 0 auto 1.75em; }
  .root blockquote.alignleft {
    margin: 0.3157894737em 1.4736842105em 1.473684211em 0; }
  .root blockquote.alignright {
    margin: 0.3157894737em 0 1.473684211em 1.4736842105em; }
  .root blockquote.aligncenter {
    margin-bottom: 1.473684211em; }
  .root .clear:before,
  .root .clear:after,
  .root blockquote:before,
  .root blockquote:after,
  .root .entry-content:before,
  .root .entry-content:after,
  .root .entry-summary:before,
  .root .entry-summary:after,
  .root .comment-content:before,
  .root .comment-content:after,
  .root .site-content:before,
  .root .site-content:after,
  .root .site-main > article:before,
  .root .site-main > article:after,
  .root .primary-menu:before,
  .root .primary-menu:after,
  .root .social-links-menu:before,
  .root .social-links-menu:after,
  .root .textwidget:before,
  .root .textwidget:after,
  .root .content-bottom-widgets:before,
  .root .content-bottom-widgets:after {
    content: '';
    display: table; }
  .root .clear:after,
  .root blockquote:after,
  .root .entry-content:after,
  .root .entry-summary:after,
  .root .comment-content:after,
  .root .site-content:after,
  .root .site-main > article:after,
  .root .primary-menu:after,
  .root .social-links-menu:after,
  .root .textwidget:after,
  .root .content-bottom-widgets:after {
    clear: both; }
  .root .widget {
    border-top: 4px solid #1a1a1a;
    margin-bottom: 3.5em;
    padding-top: 1.75em; }
  .root .widget-area > :last-child,
  .root .widget > :last-child {
    margin-bottom: 0; }
  .root .widget .widget-title {
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    font-size: 1rem;
    letter-spacing: 0.046875em;
    line-height: 1.3125;
    margin: 0 0 1.75em;
    text-transform: uppercase; }
  .root .widget .widget-title:empty {
    margin-bottom: 0; }
  .root .widget-title a {
    color: #1a1a1a; }
  .root .widget.widget_calendar table {
    margin: 0; }
  .root .widget_calendar td,
  .root .widget_calendar th {
    line-height: 2.5625;
    padding: 0;
    text-align: center; }
  .root .widget_calendar caption {
    font-weight: 900;
    margin-bottom: 1.75em; }
  .root .widget_calendar tbody a {
    background-color: #007acc;
    color: #fff;
    display: block;
    font-weight: 700; }
  .root .widget_calendar tbody a:hover,
  .root .widget_calendar tbody a:focus {
    background-color: #686868;
    color: #fff; }
  .root .widget_recent_entries .post-date {
    color: #686868;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.615384615;
    margin-bottom: 0.538461538em; }
  .root .widget_recent_entries li:last-child .post-date {
    margin-bottom: 0; }
  .root .widget_rss .rsswidget img {
    margin-top: -0.375em; }
  .root .widget_rss .rss-date,
  .root .widget_rss cite {
    color: #686868;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: normal;
    line-height: 1.615384615;
    margin-bottom: 0.538461538em; }
  .root .widget_rss .rssSummary:last-child {
    margin-bottom: 2.1538461538em; }
  .root .widget_rss li:last-child :last-child {
    margin-bottom: 0; }
  .root .tagcloud a {
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    display: inline-block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    line-height: 1;
    margin: 0 0.1875em 0.4375em 0;
    padding: 0.5625em 0.4375em 0.5em; }
  .root .tagcloud ul {
    list-style-type: none;
    margin-left: 0; }
  .root .tagcloud ul li {
    display: inline-block; }
  .root .tagcloud a:hover,
  .root .tagcloud a:focus {
    border-color: #007acc;
    color: #007acc;
    outline: 0; }
  .root .site {
    background-color: #fff; }
  .root .site-inner {
    margin: 0 auto;
    max-width: 1320px;
    position: relative; }
  .root .site-content {
    word-wrap: break-word; }
  .root #content[tabindex='-1']:focus {
    outline: 0; }
  .root .site-main {
    margin-bottom: 3.5em; }
  .root .site-main > :last-child {
    margin-bottom: 0; }
  .root .site-header {
    padding: 2.625em 7.6923%; }
  .root .site-header-main {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .root .site-branding {
    margin: 0.875em auto 0.875em 0;
    /* Avoid overflowing wide custom logo in small screens in Firefox and IEs */
    max-width: 100%;
    min-width: 0;
    overflow: hidden; }
  .root .custom-logo-link {
    display: block; }
  .root .custom-logo {
    max-width: 180px; }
  .root .site-title {
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 23px;
    font-size: 1.4375rem;
    font-weight: 700;
    line-height: 1.2173913043;
    margin: 0; }
  .root .site-branding .site-title a {
    color: #1a1a1a; }
  .root .site-branding .site-title a:hover,
  .root .site-branding .site-title a:focus {
    color: #007acc; }
  .root .wp-custom-logo .site-title {
    margin-top: 0.608695652em; }
  .root .site-description {
    color: #686868;
    display: none;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.0769230769;
    margin: 0.538461538em 0 0; }
  .root .menu-toggle {
    background-color: transparent;
    border: 1px solid #d1d1d1;
    color: #1a1a1a;
    font-size: 13px;
    font-size: 0.8125rem;
    margin: 1.076923077em 0;
    padding: 0.769230769em; }
  .root .no-js .menu-toggle {
    display: none; }
  .root .menu-toggle:hover,
  .root .menu-toggle:focus {
    background-color: transparent;
    border-color: #007acc;
    color: #007acc; }
  .root .menu-toggle.toggled-on,
  .root .menu-toggle.toggled-on:hover,
  .root .menu-toggle.toggled-on:focus {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    color: #fff; }
  .root .menu-toggle:focus {
    outline: 0; }
  .root .menu-toggle.toggled-on:focus {
    outline: thin dotted; }
  .root .header-image {
    clear: both;
    margin: 0.875em 0; }
  .root .header-image a {
    display: block; }
  .root .header-image a:hover img,
  .root .header-image a:focus img {
    opacity: 0.85; }
  .root .site-main > article {
    margin-bottom: 3.5em;
    position: relative; }
  .root .entry-header,
  .root .entry-summary,
  .root .entry-content,
  .root .entry-footer,
  .root .page-content {
    margin-right: 7.6923%;
    margin-left: 7.6923%; }
  .root .entry-title {
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 28px;
    font-size: 1.75rem;
    font-weight: 700;
    line-height: 1.25;
    margin-bottom: 1em; }
  .root .entry-title a {
    color: #1a1a1a; }
  .root .entry-title a:hover,
  .root .entry-title a:focus {
    color: #007acc; }
  .root .post-thumbnail {
    display: block;
    margin: 0 7.6923% 1.75em; }
  .root .post-thumbnail img {
    display: block; }
  .root .no-sidebar .post-thumbnail img {
    margin: 0 auto; }
  .root a.post-thumbnail:hover,
  .root a.post-thumbnail:focus {
    opacity: 0.85; }
  .root .entry-content,
  .root .entry-summary {
    border-color: #d1d1d1; }
  .root .entry-content h1,
  .root .entry-summary h1,
  .root .comment-content h1,
  .root .textwidget h1 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 1.25;
    margin-top: 2em;
    margin-bottom: 1em; }
  .root .entry-content h2,
  .root .entry-summary h2,
  .root .comment-content h2,
  .root .textwidget h2 {
    font-size: 23px;
    font-size: 1.4375rem;
    line-height: 1.2173913043;
    margin-top: 2.4347826087em;
    margin-bottom: 1.2173913043em; }
  .root .entry-content h3,
  .root .entry-summary h3,
  .root .comment-content h3,
  .root .textwidget h3 {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.1052631579;
    margin-top: 2.9473684211em;
    margin-bottom: 1.4736842105em; }
  .root .entry-content h4,
  .root .entry-content h5,
  .root .entry-content h6,
  .root .entry-summary h4,
  .root .entry-summary h5,
  .root .entry-summary h6,
  .root .comment-content h4,
  .root .comment-content h5,
  .root .comment-content h6,
  .root .textwidget h4,
  .root .textwidget h5,
  .root .textwidget h6 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.3125;
    margin-top: 3.5em;
    margin-bottom: 1.75em; }
  .root .entry-content h4,
  .root .entry-summary h4,
  .root .comment-content h4,
  .root .textwidget h4 {
    letter-spacing: 0.140625em;
    text-transform: uppercase; }
  .root .entry-content h6,
  .root .entry-summary h6,
  .root .comment-content h6,
  .root .textwidget h6 {
    font-style: italic; }
  .root .entry-content h1,
  .root .entry-content h2,
  .root .entry-content h3,
  .root .entry-content h4,
  .root .entry-content h5,
  .root .entry-content h6,
  .root .entry-summary h1,
  .root .entry-summary h2,
  .root .entry-summary h3,
  .root .entry-summary h4,
  .root .entry-summary h5,
  .root .entry-summary h6,
  .root .comment-content h1,
  .root .comment-content h2,
  .root .comment-content h3,
  .root .comment-content h4,
  .root .comment-content h5,
  .root .comment-content h6,
  .root .textwidget h1,
  .root .textwidget h2,
  .root .textwidget h3,
  .root .textwidget h4,
  .root .textwidget h5,
  .root .textwidget h6 {
    font-weight: 900; }
  .root .entry-content h1:first-child,
  .root .entry-content h2:first-child,
  .root .entry-content h3:first-child,
  .root .entry-content h4:first-child,
  .root .entry-content h5:first-child,
  .root .entry-content h6:first-child,
  .root .entry-summary h1:first-child,
  .root .entry-summary h2:first-child,
  .root .entry-summary h3:first-child,
  .root .entry-summary h4:first-child,
  .root .entry-summary h5:first-child,
  .root .entry-summary h6:first-child,
  .root .comment-content h1:first-child,
  .root .comment-content h2:first-child,
  .root .comment-content h3:first-child,
  .root .comment-content h4:first-child,
  .root .comment-content h5:first-child,
  .root .comment-content h6:first-child,
  .root .textwidget h1:first-child,
  .root .textwidget h2:first-child,
  .root .textwidget h3:first-child,
  .root .textwidget h4:first-child,
  .root .textwidget h5:first-child,
  .root .textwidget h6:first-child {
    margin-top: 0; }
  .root .post-navigation .post-title,
  .root .entry-title,
  .root .comments-title {
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto; }
  .root body:not(.search-results) .entry-summary {
    color: #686868;
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.4736842105;
    margin-bottom: 1.4736842105em; }
  .root body:not(.search-results) .entry-header + .entry-summary {
    margin-top: -0.736842105em; }
  .root body:not(.search-results) .entry-summary p,
  .root body:not(.search-results) .entry-summary address,
  .root body:not(.search-results) .entry-summary hr,
  .root body:not(.search-results) .entry-summary ul,
  .root body:not(.search-results) .entry-summary ol,
  .root body:not(.search-results) .entry-summary dl,
  .root body:not(.search-results) .entry-summary dd,
  .root body:not(.search-results) .entry-summary table {
    margin-bottom: 1.4736842105em; }
  .root body:not(.search-results) .entry-summary li > ul,
  .root body:not(.search-results) .entry-summary li > ol {
    margin-bottom: 0; }
  .root body:not(.search-results) .entry-summary th,
  .root body:not(.search-results) .entry-summary td {
    padding: 0.3684210526em; }
  .root body:not(.search-results) .entry-summary fieldset {
    margin-bottom: 1.4736842105em;
    padding: 0.3684210526em; }
  .root body:not(.search-results) .entry-summary blockquote {
    border-color: currentColor; }
  .root body:not(.search-results) .entry-summary blockquote > :last-child {
    margin-bottom: 0; }
  .root body:not(.search-results) .entry-summary .alignleft {
    margin: 0.2631578947em 1.4736842105em 1.4736842105em 0; }
  .root body:not(.search-results) .entry-summary .alignright {
    margin: 0.2631578947em 0 1.4736842105em 1.4736842105em; }
  .root body:not(.search-results) .entry-summary .aligncenter {
    margin-bottom: 1.4736842105em; }
  .root .entry-content > :last-child,
  .root .entry-summary > :last-child,
  .root body:not(.search-results) .entry-summary > :last-child,
  .root .page-content > :last-child,
  .root .comment-content > :last-child,
  .root .textwidget > :last-child {
    margin-bottom: 0; }
  .root .more-link {
    white-space: nowrap; }
  .root .author-info {
    border-color: inherit;
    border-style: solid;
    border-width: 1px 0 1px 0;
    clear: both;
    padding-top: 1.75em;
    padding-bottom: 1.75em; }
  .root .author-avatar .avatar {
    float: left;
    height: 42px;
    margin: 0 1.75em 1.75em 0;
    width: 42px; }
  .root .author-description > :last-child {
    margin-bottom: 0; }
  .root .entry-content .author-title {
    clear: none;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 900;
    line-height: 1.75;
    margin: 0; }
  .root .author-bio {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin-bottom: 1.6153846154em;
    overflow: hidden; }
  .root .author-link {
    white-space: nowrap; }
  .root .entry-footer {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin-top: 2.1538461538em; }
  .root .entry-footer:empty {
    margin: 0; }
  .root .entry-footer a {
    color: #686868; }
  .root .entry-footer a:hover,
  .root .entry-footer a:focus {
    color: #007acc; }
  .root .entry-footer > span:not(:last-child):after {
    content: '\002f';
    display: inline-block;
    opacity: 0.7;
    padding: 0 0.538461538em; }
  .root .entry-footer .avatar {
    height: 21px;
    margin: -0.1538461538em 0.5384615385em 0 0;
    width: 21px; }
  .root .sticky-post {
    color: #686868;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    letter-spacing: 0.076923077em;
    line-height: 1.6153846154;
    margin-bottom: 0.5384615385em;
    text-transform: uppercase; }
  .root .updated:not(.published) {
    display: none; }
  .root .sticky .posted-on,
  .root .byline {
    display: none; }
  .root .single .byline,
  .root .group-blog .byline {
    display: inline; }
  .root .page-header {
    border-top: 4px solid #1a1a1a;
    margin: 0 7.6923% 3.5em;
    padding-top: 1.75em; }
  .root body.error404 .page-header,
  .root body.search-no-results .page-header {
    border-top: 0;
    padding-top: 0; }
  .root .page-title {
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 23px;
    font-size: 1.4375rem;
    line-height: 1.2173913043; }
  .root .taxonomy-description {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154; }
  .root .taxonomy-description p {
    margin: 0.5384615385em 0 1.6153846154em; }
  .root .taxonomy-description > :last-child {
    margin-bottom: 0; }
  .root .page-links {
    clear: both;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    margin: 0 0 1.75em; }
  .root .page-links a,
  .root .page-links > span {
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    display: inline-block;
    font-size: 13px;
    font-size: 0.8125rem;
    height: 1.8461538462em;
    line-height: 1.6923076923em;
    margin-right: 0.3076923077em;
    text-align: center;
    width: 1.8461538462em; }
  .root .page-links a {
    background-color: #1a1a1a;
    border-color: #1a1a1a;
    color: #fff; }
  .root .page-links a:hover,
  .root .page-links a:focus {
    background-color: #007acc;
    border-color: transparent;
    color: #fff; }
  .root .page-links > .page-links-title {
    border: 0;
    color: #1a1a1a;
    height: auto;
    margin: 0;
    padding-right: 0.6153846154em;
    width: auto; }
  .root .entry-attachment {
    margin-bottom: 1.75em; }
  .root .entry-caption {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: italic;
    line-height: 1.6153846154;
    padding-top: 1.0769230769em; }
  .root .entry-caption > :last-child {
    margin-bottom: 0; }
  .root .content-bottom-widgets {
    margin: 0 7.6923%; }
  .root .content-bottom-widgets .widget-area {
    margin-bottom: 3.5em; }
  .root .format-aside .entry-title,
  .root .format-image .entry-title,
  .root .format-video .entry-title,
  .root .format-quote .entry-title,
  .root .format-gallery .entry-title,
  .root .format-status .entry-title,
  .root .format-link .entry-title,
  .root .format-audio .entry-title,
  .root .format-chat .entry-title {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 1.473684211;
    margin-bottom: 1.473684211em; }
  .root .blog .format-status .entry-title,
  .root .archive .format-status .entry-title {
    display: none; }
  .root .comments-area {
    margin: 0 7.6923% 3.5em; }
  .root .comment-list + .comment-respond,
  .root .comment-navigation + .comment-respond {
    padding-top: 1.75em; }
  .root .comments-title,
  .root .comment-reply-title {
    border-top: 4px solid #1a1a1a;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 23px;
    font-size: 1.4375rem;
    font-weight: 700;
    line-height: 1.3125;
    padding-top: 1.217391304em; }
  .root .comments-title {
    margin-bottom: 1.217391304em; }
  .root .comment-list {
    list-style: none;
    margin: 0; }
  .root .comment-list article,
  .root .comment-list .pingback,
  .root .comment-list .trackback {
    border-top: 1px solid #d1d1d1;
    padding: 1.75em 0; }
  .root .comment-list .children {
    list-style: none;
    margin: 0; }
  .root .comment-list .children > li {
    padding-left: 0.875em; }
  .root .comment-author {
    color: #1a1a1a;
    margin-bottom: 0.4375em; }
  .root .comment-author .avatar {
    float: left;
    height: 28px;
    margin-right: 0.875em;
    position: relative;
    width: 28px; }
  .root .bypostauthor > article .fn:after {
    content: '\f304';
    left: 3px;
    position: relative;
    top: 5px; }
  .root .comment-metadata,
  .root .pingback .edit-link {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154; }
  .root .comment-metadata {
    margin-bottom: 2.1538461538em; }
  .root .comment-metadata a,
  .root .pingback .comment-edit-link {
    color: #686868; }
  .root .comment-metadata a:hover,
  .root .comment-metadata a:focus,
  .root .pingback .comment-edit-link:hover,
  .root .pingback .comment-edit-link:focus {
    color: #007acc; }
  .root .comment-metadata .edit-link,
  .root .pingback .edit-link {
    display: inline-block; }
  .root .comment-metadata .edit-link:before,
  .root .pingback .edit-link:before {
    content: '\002f';
    display: inline-block;
    opacity: 0.7;
    padding: 0 0.538461538em; }
  .root .comment-content ul,
  .root .comment-content ol {
    margin: 0 0 1.5em 1.25em; }
  .root .comment-content li > ul,
  .root .comment-content li > ol {
    margin-bottom: 0; }
  .root .comment-reply-link {
    border: 1px solid #d1d1d1;
    border-radius: 2px;
    color: #007acc;
    display: inline-block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1;
    margin-top: 2.1538461538em;
    padding: 0.5384615385em 0.5384615385em 0.4615384615em; }
  .root .comment-reply-link:hover,
  .root .comment-reply-link:focus {
    border-color: currentColor;
    color: #007acc;
    outline: 0; }
  .root .comment-form {
    padding-top: 1.75em; }
  .root .comment-form label {
    color: #686868;
    display: block;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    letter-spacing: 0.076923077em;
    line-height: 1.6153846154;
    margin-bottom: 0.5384615385em;
    text-transform: uppercase; }
  .root .comment-list .comment-form {
    padding-bottom: 1.75em; }
  .root .comment-notes,
  .root .comment-awaiting-moderation,
  .root .logged-in-as,
  .root .form-allowed-tags {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154;
    margin-bottom: 2.1538461538em; }
  .root .no-comments {
    border-top: 1px solid #d1d1d1;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-weight: 700;
    margin: 0;
    padding-top: 1.75em; }
  .root .comment-navigation + .no-comments {
    border-top: 0;
    padding-top: 0; }
  .root .form-allowed-tags code {
    font-family: Inconsolata, monospace; }
  .root .form-submit {
    margin-bottom: 0; }
  .root .required {
    color: #007acc;
    font-family: Merriweather, Georgia, serif; }
  .root .comment-reply-title small {
    font-size: 100%; }
  .root .comment-reply-title small a {
    border: 0;
    float: right;
    height: 32px;
    overflow: hidden;
    width: 26px; }
  .root .comment-reply-title small a:hover,
  .root .comment-reply-title small a:focus {
    color: #1a1a1a; }
  .root .comment-reply-title small a:before {
    content: '\f405';
    font-size: 32px;
    position: relative;
    top: -5px; }
  .root .comment-form #wp-comment-cookies-consent {
    margin: 0 10px 0 0; }
  .root .comment-form .comment-form-cookies-consent label {
    display: inline;
    font-family: Merriweather, Georgia, serif;
    letter-spacing: 0;
    text-transform: none; }
  .root .sidebar {
    margin-bottom: 3.5em;
    padding: 0 7.6923%; }
  .root .site-footer {
    padding: 0 7.6923% 1.75em; }
  .root .site-info {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154; }
  .root .site-info a {
    color: #686868; }
  .root .site-info a:hover,
  .root .site-info a:focus {
    color: #007acc; }
  .root .site-footer .site-title {
    font-family: inherit;
    font-size: inherit;
    font-weight: 400; }
  .root .site-footer .site-title:after {
    content: '\002f';
    display: inline-block;
    font-family: Montserrat, sans-serif;
    opacity: 0.7;
    padding: 0 0.307692308em 0 0.538461538em; }
  .root .site-footer span[role='separator'] {
    font-family: Montserrat, sans-serif;
    opacity: 0.7;
    padding: 0 0.307692308em 0 0.538461538em; }
  .root .site-footer span[role='separator']::before {
    content: '\002f'; }
  .root .site .avatar {
    border-radius: 50%; }
  .root .entry-content .wp-smiley,
  .root .entry-summary .wp-smiley,
  .root .comment-content .wp-smiley,
  .root .textwidget .wp-smiley {
    border: none;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0; }
  .root .entry-content a img,
  .root .entry-summary a img,
  .root .comment-content a img,
  .root .textwidget a img {
    display: block; }
  .root embed,
  .root iframe,
  .root object,
  .root video {
    margin-bottom: 1.75em;
    max-width: 100%;
    vertical-align: middle; }
  .root p > embed,
  .root p > iframe,
  .root p > object,
  .root p > video {
    margin-bottom: 0; }
  .root .entry-content .wp-audio-shortcode a,
  .root .entry-content .wp-playlist a {
    box-shadow: none; }
  .root .wp-audio-shortcode,
  .root .wp-video,
  .root .wp-playlist.wp-audio-playlist {
    margin-top: 0;
    margin-bottom: 1.75em; }
  .root .wp-playlist.wp-audio-playlist {
    padding-bottom: 0; }
  .root .wp-playlist .wp-playlist-tracks {
    margin-top: 0; }
  .root .wp-playlist-item .wp-playlist-caption {
    border-bottom: 0;
    padding: 0.7142857143em 0; }
  .root .wp-playlist-item .wp-playlist-item-length {
    top: 0.7142857143em; }
  .root .wp-caption {
    margin-bottom: 1.75em;
    max-width: 100%; }
  .root .wp-caption img[class*='wp-image-'] {
    display: block;
    margin: 0; }
  .root .wp-caption .wp-caption-text {
    color: #686868;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: italic;
    line-height: 1.6153846154;
    padding-top: 0.5384615385em; }
  .root .gallery {
    margin: 0 -1.1666667% 1.75em; }
  .root .gallery-item {
    display: inline-block;
    max-width: 33.33%;
    padding: 0 1.1400652% 2.2801304%;
    text-align: center;
    vertical-align: top;
    width: 100%; }
  .root .gallery-columns-1 .gallery-item {
    max-width: 100%; }
  .root .gallery-columns-2 .gallery-item {
    max-width: 50%; }
  .root .gallery-columns-4 .gallery-item {
    max-width: 25%; }
  .root .gallery-columns-5 .gallery-item {
    max-width: 20%; }
  .root .gallery-columns-6 .gallery-item {
    max-width: 16.66%; }
  .root .gallery-columns-7 .gallery-item {
    max-width: 14.28%; }
  .root .gallery-columns-8 .gallery-item {
    max-width: 12.5%; }
  .root .gallery-columns-9 .gallery-item {
    max-width: 11.11%; }
  .root .gallery-icon img {
    margin: 0 auto; }
  .root .gallery-caption {
    color: #686868;
    display: block;
    font-size: 13px;
    font-size: 0.8125rem;
    font-style: italic;
    line-height: 1.6153846154;
    padding-top: 0.5384615385em; }
  .root .gallery-columns-6 .gallery-caption,
  .root .gallery-columns-7 .gallery-caption,
  .root .gallery-columns-8 .gallery-caption,
  .root .gallery-columns-9 .gallery-caption {
    display: none; }
  .root .widecolumn {
    margin-bottom: 3.5em;
    padding: 0 7.6923%; }
  .root .widecolumn .mu_register {
    width: auto; }
  .root .widecolumn .mu_register .mu_alert {
    background: transparent;
    border-color: #d1d1d1;
    color: inherit;
    margin-bottom: 3.5em;
    padding: 1.75em; }
  .root .widecolumn form,
  .root .widecolumn .mu_register form {
    margin-top: 0; }
  .root .widecolumn h2 {
    font-size: 23px;
    font-size: 1.4375rem;
    font-weight: 900;
    line-height: 1.2173913043;
    margin-bottom: 1.2173913043em; }
  .root .widecolumn p {
    margin: 1.75em 0; }
  .root .widecolumn p + h2 {
    margin-top: 2.4347826087em; }
  .root .widecolumn label,
  .root .widecolumn .mu_register label {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 400;
    letter-spacing: 0.076923077em;
    line-height: 1.6153846154;
    text-transform: uppercase; }
  .root .widecolumn .mu_register label {
    margin: 2.1538461538em 0.7692307692em 0.5384615385em 0; }
  .root .widecolumn .mu_register label strong {
    font-weight: 400; }
  .root .widecolumn #key,
  .root .widecolumn .mu_register #blog_title,
  .root .widecolumn .mu_register #user_email,
  .root .widecolumn .mu_register #blogname,
  .root .widecolumn .mu_register #user_name {
    font-size: 16px;
    font-size: 1rem;
    width: 100%; }
  .root .widecolumn .mu_register #blogname {
    margin: 0; }
  .root .widecolumn .mu_register #blog_title,
  .root .widecolumn .mu_register #user_email,
  .root .widecolumn .mu_register #user_name {
    margin: 0 0 0.375em; }
  .root .widecolumn #submit,
  .root .widecolumn .mu_register input[type='submit'] {
    font-size: 16px;
    font-size: 1rem;
    margin: 0;
    width: auto; }
  .root .widecolumn .mu_register .prefix_address,
  .root .widecolumn .mu_register .suffix_address {
    font-size: inherit; }
  .root .widecolumn .mu_register > :last-child,
  .root .widecolumn form > :last-child {
    margin-bottom: 0; }

@-ms-viewport {
  .root {
    width: device-width; } }

@viewport {
  .root {
    width: device-width; } }
  @media screen and (min-width: 44.375em) {
    .root {
      /* restore screen-reader-text */ }
      .root body:not(.custom-background-image):before,
      .root body:not(.custom-background-image):after {
        background: inherit;
        content: '';
        display: block;
        height: 21px;
        left: 0;
        position: fixed;
        width: 100%;
        z-index: 99; }
      .root body:not(.custom-background-image):before {
        top: 0; }
      .root body:not(.custom-background-image).admin-bar:before {
        top: 46px; }
      .root body:not(.custom-background-image):after {
        bottom: 0; }
      .root .site {
        margin: 21px; }
      .root .site-main {
        margin-bottom: 5.25em; }
      .root .site-header {
        padding: 3.9375em 7.6923%; }
      .root .site-branding {
        margin-top: 1.3125em;
        margin-bottom: 1.3125em; }
      .root .custom-logo {
        max-width: 210px; }
      .root .site-title {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 1.25; }
      .root .wp-custom-logo .site-title {
        margin-top: 0.5em; }
      .root .site-description {
        display: block; }
      .root .menu-toggle {
        font-size: 16px;
        font-size: 1rem;
        margin: 1.3125em 0;
        padding: 0.8125em 0.875em 0.6875em; }
      .root .site-header-menu {
        margin: 1.3125em 0; }
      .root .site-header .main-navigation + .social-navigation {
        margin-top: 2.625em; }
      .root .header-image {
        margin: 1.3125em 0; }
      .root .pagination {
        margin: 0 23.0769% 4.421052632em 7.6923%; }
      .root .post-navigation {
        margin-bottom: 5.25em; }
      .root .post-navigation .post-title {
        font-size: 28px;
        font-size: 1.75rem;
        line-height: 1.25; }
      .root .pagination .current .screen-reader-text {
        position: absolute !important; }
      .root .pagination .page-numbers {
        display: inline-block; }
      .root .site-main > article {
        margin-bottom: 5.25em; }
      .root .entry-header,
      .root .post-thumbnail,
      .root .entry-content,
      .root .entry-summary,
      .root .entry-footer,
      .root .comments-area,
      .root .image-navigation,
      .root .post-navigation,
      .root .page-header,
      .root .page-content,
      .root .content-bottom-widgets {
        margin-right: 23.0769%; }
      .root .entry-title {
        font-size: 33px;
        font-size: 2.0625rem;
        line-height: 1.2727272727;
        margin-bottom: 0.8484848485em; }
      .root .entry-content blockquote.alignleft,
      .root .entry-content blockquote.alignright {
        border-width: 4px 0 0 0;
        padding: 0.9473684211em 0 0;
        width: -webkit-calc(50% - 0.736842105em);
        width: calc(50% - 0.736842105em); }
      .root .entry-content blockquote:not(.alignleft):not(.alignright),
      .root .entry-summary blockquote,
      .root .comment-content blockquote {
        margin-left: -1.473684211em; }
      .root .entry-content blockquote blockquote:not(.alignleft):not(.alignright),
      .root .entry-summary blockquote blockquote,
      .root .comment-content blockquote blockquote {
        margin-left: 0; }
      .root .entry-content ul,
      .root .entry-summary ul,
      .root .comment-content ul,
      .root .entry-content ol,
      .root .entry-summary ol,
      .root .comment-content ol {
        /*margin-left: 0;*/ }
      .root .entry-content li > ul,
      .root .entry-summary li > ul,
      .root .comment-content li > ul,
      .root .entry-content blockquote > ul,
      .root .entry-summary blockquote > ul,
      .root .comment-content blockquote > ul {
        margin-left: 1.25em; }
      .root .entry-content li > ol,
      .root .entry-summary li > ol,
      .root .comment-content li > ol,
      .root .entry-content blockquote > ol,
      .root .entry-summary blockquote > ol,
      .root .comment-content blockquote > ol {
        margin-left: 1.5em; }
      .root .comment-author {
        margin-bottom: 0; }
      .root .comment-author .avatar {
        height: 42px;
        position: relative;
        top: 0.25em;
        width: 42px; }
      .root .comment-list .children > li {
        padding-left: 1.75em; }
      .root .comment-list + .comment-respond,
      .root .comment-navigation + .comment-respond {
        padding-top: 3.5em; }
      .root .comments-area,
      .root .widget,
      .root .content-bottom-widgets .widget-area {
        margin-bottom: 5.25em; }
      .root .sidebar,
      .root .widecolumn {
        margin-bottom: 5.25em;
        padding-right: 23.0769%; }
      .root body:not(.search-results) .entry-summary li > ul,
      .root body:not(.search-results) .entry-summary blockquote > ul {
        margin-left: 1.157894737em; }
      .root body:not(.search-results) .entry-summary li > ol,
      .root body:not(.search-results) .entry-summary blockquote > ol {
        margin-left: 1.473684211em; } }
  @media screen and (min-width: 48.9375em) {
    .root body:not(.custom-background-image).admin-bar:before {
      top: 32px; } }
  @media screen and (min-width: 56.875em) {
    .root .site-header {
      padding-right: 4.5455%;
      padding-left: 4.5455%; }
    .root .site-header-main {
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start; }
    .root .wp-custom-logo .site-header-main {
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center; }
    .root .site-header-menu {
      display: block;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto; }
    .root .main-navigation {
      margin: 0 -0.875em; }
    .root .main-navigation .primary-menu,
    .root .main-navigation .primary-menu > li {
      border: 0; }
    .root .main-navigation .primary-menu > li {
      float: left; }
    .root .main-navigation a {
      outline-offset: -8px;
      padding: 0.65625em 0.875em;
      white-space: nowrap; }
    .root .main-navigation li:hover > a,
    .root .main-navigation li.focus > a {
      color: #007acc; }
    .root .main-navigation ul ul {
      border-bottom: 1px solid #d1d1d1;
      display: block;
      left: -999em;
      margin: 0;
      position: absolute;
      z-index: 99999; }
    .root .main-navigation ul ul ul {
      top: -1px; }
    .root .main-navigation ul ul ul:before,
    .root .main-navigation ul ul ul:after {
      border: 0; }
    .root .main-navigation ul ul li {
      background-color: #fff;
      border: 1px solid #d1d1d1;
      border-bottom-width: 0; }
    .root .main-navigation ul ul a {
      white-space: normal;
      width: 12.6875em; }
    .root .main-navigation ul ul:before,
    .root .main-navigation ul ul:after {
      border-style: solid;
      content: '';
      position: absolute; }
    .root .main-navigation ul ul:before {
      border-color: #d1d1d1 transparent;
      border-width: 0 10px 10px;
      right: 9px;
      top: -9px; }
    .root .main-navigation ul ul:after {
      border-color: #fff transparent;
      border-width: 0 8px 8px;
      right: 11px;
      top: -7px; }
    .root .main-navigation li:hover > ul,
    .root .main-navigation li.focus > ul {
      left: auto;
      right: 0; }
    .root .main-navigation ul ul li:hover > ul,
    .root .main-navigation ul ul li.focus > ul {
      left: auto;
      right: 100%; }
    .root .main-navigation .menu-item-has-children > a {
      margin: 0;
      padding-right: 2.25em; }
    .root .main-navigation .menu-item-has-children > a:after {
      content: '\f431';
      position: absolute;
      right: 0.625em;
      top: 0.8125em; }
    .root .main-navigation ul ul .menu-item-has-children > a {
      padding-right: 2.0625em; }
    .root .main-navigation ul ul .menu-item-has-children > a:after {
      right: 0.5625em;
      top: 0.875em;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg); }
    .root .dropdown-toggle,
    .root .main-navigation ul .dropdown-toggle.toggled-on,
    .root .menu-toggle,
    .root .site-header .social-navigation,
    .root .site-footer .main-navigation {
      display: none; }
    .root .site-content {
      padding: 0 4.5455%; }
    .root .content-area {
      float: left;
      margin-right: -100%;
      width: 70%; }
    .root .entry-header,
    .root .post-thumbnail,
    .root .entry-content,
    .root .entry-summary,
    .root .entry-footer,
    .root .comments-area,
    .root .image-navigation,
    .root .post-navigation,
    .root .pagination,
    .root .page-header,
    .root .page-content,
    .root .content-bottom-widgets {
      margin-right: 0;
      margin-left: 0; }
    .root .sidebar {
      float: left;
      margin-left: 75%;
      padding: 0;
      width: 25%; }
    .root .widget {
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 1.6153846154;
      margin-bottom: 3.230769231em;
      padding-top: 1.615384615em; }
    .root .widget .widget-title {
      margin-bottom: 1.3125em; }
    .root .widget p,
    .root .widget address,
    .root .widget hr,
    .root .widget ul,
    .root .widget ol,
    .root .widget dl,
    .root .widget dd,
    .root .widget table {
      margin-bottom: 1.6153846154em; }
    .root .widget li > ul,
    .root .widget li > ol {
      margin-bottom: 0; }
    .root .widget blockquote {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.3125;
      margin-bottom: 1.3125em;
      padding-left: 1.0625em; }
    .root .widget blockquote cite,
    .root .widget blockquote small {
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 1.6153846154; }
    .root .widget th,
    .root .widget td {
      padding: 0.5384615385em; }
    .root .widget pre {
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 1.6153846154;
      margin-bottom: 1.6153846154em;
      padding: 0.5384615385em; }
    .root .widget fieldset {
      margin-bottom: 1.6153846154em;
      padding: 0.5384615385em; }
    .root .widget button,
    .root .widget input,
    .root .widget select,
    .root .widget textarea {
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 1.6153846154; }
    .root .widget button,
    .root .widget input[type='button'],
    .root .widget input[type='reset'],
    .root .widget input[type='submit'] {
      line-height: 1;
      padding: 0.846153846em; }
    .root .widget input[type='date'],
    .root .widget input[type='time'],
    .root .widget input[type='datetime-local'],
    .root .widget input[type='week'],
    .root .widget input[type='month'],
    .root .widget input[type='text'],
    .root .widget input[type='email'],
    .root .widget input[type='url'],
    .root .widget input[type='password'],
    .root .widget input[type='search'],
    .root .widget input[type='tel'],
    .root .widget input[type='number'],
    .root .widget textarea {
      padding: 0.4615384615em 0.5384615385em; }
    .root .widget h1 {
      font-size: 23px;
      font-size: 1.4375rem;
      line-height: 1.2173913043;
      margin-bottom: 0.9130434783em; }
    .root .widget h2 {
      font-size: 19px;
      font-size: 1.1875rem;
      line-height: 1.1052631579;
      margin-bottom: 1.1052631579em; }
    .root .widget h3 {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.3125;
      margin-bottom: 1.3125em; }
    .root .widget h4,
    .root .widget h5,
    .root .widget h6 {
      font-size: 13px;
      font-size: 0.8125rem;
      line-height: 1.6153846154;
      margin-bottom: 0.9130434783em; }
    .root .widget .alignleft {
      margin: 0.2307692308em 1.6153846154em 1.6153846154em 0; }
    .root .widget .alignright {
      margin: 0.2307692308em 0 1.6153846154em 1.6153846154em; }
    .root .widget .aligncenter {
      margin-bottom: 1.6153846154em; }
    .root .widget_calendar td,
    .root .widget_calendar th {
      line-height: 2.6923076923;
      padding: 0; }
    .root .widget_rss .rssSummary:last-child {
      margin-bottom: 1.615384615em; }
    .root .widget input[type='search'].search-field {
      width: -webkit-calc(100% - 35px);
      width: calc(100% - 35px); }
    .root .widget .search-submit:before {
      font-size: 16px;
      left: 1px;
      line-height: 35px;
      width: 34px; }
    .root .widget button.search-submit {
      padding: 0;
      width: 35px; }
    .root .tagcloud a {
      margin: 0 0.2307692308em 0.5384615385em 0;
      padding: 0.5384615385em 0.4615384615em 0.4615384615em; }
    .root .textwidget h1 {
      margin-top: 1.8260869565em; }
    .root .textwidget h2 {
      margin-top: 2.2105263158em; }
    .root .textwidget h3 {
      margin-top: 2.625em; }
    .root .textwidget h4 {
      letter-spacing: 0.153846154em; }
    .root .textwidget h4,
    .root .textwidget h5,
    .root .textwidget h6 {
      margin-top: 3.2307692308em; }
    .root .content-bottom-widgets .widget-area:nth-child(1):nth-last-child(2),
    .root .content-bottom-widgets .widget-area:nth-child(2):nth-last-child(1) {
      float: left;
      margin-right: 7.1428571%;
      width: 46.42857145%; }
    .root .content-bottom-widgets
.widget-area:nth-child(2):nth-last-child(1):last-of-type {
      margin-right: 0; }
    .root .site-footer {
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      padding: 0 4.5455% 3.5em; }
    .root .site-footer .social-navigation {
      margin: 0;
      -webkit-order: 2;
      -ms-flex-order: 2;
      order: 2; }
    .root .site-info {
      margin: 0.538461538em auto 0.538461538em 0;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1; }
    .root .no-sidebar .content-area {
      float: none;
      margin: 0;
      width: 100%; }
    .root .no-sidebar .entry-header,
    .root .no-sidebar .entry-content,
    .root .no-sidebar .entry-summary,
    .root .no-sidebar .entry-footer,
    .root .no-sidebar .comments-area,
    .root .no-sidebar .image-navigation,
    .root .no-sidebar .post-navigation,
    .root .no-sidebar .pagination,
    .root .no-sidebar .page-header,
    .root .no-sidebar .page-content,
    .root .no-sidebar .content-bottom-widgets {
      margin-right: 15%;
      margin-left: 15%; }
    .root .widecolumn {
      padding-right: 15%;
      padding-left: 15%; } }
  @media screen and (min-width: 61.5625em) {
    .root .site-main {
      margin-bottom: 7em; }
    .root .site-header {
      padding: 5.25em 4.5455%; }
    .root .site-branding,
    .root .site-header-menu,
    .root .header-image {
      margin-top: 1.75em;
      margin-bottom: 1.75em; }
    .root .custom-logo {
      max-width: 240px; }
    .root .image-navigation {
      margin-bottom: 3.230769231em; }
    .root .post-navigation {
      margin-bottom: 7em; }
    .root .pagination {
      margin-bottom: 5.894736842em; }
    .root .widget {
      margin-bottom: 4.307692308em; }
    .root .site-main > article {
      margin-bottom: 7em; }
    .root .entry-title {
      font-size: 40px;
      font-size: 2.5rem;
      line-height: 1.225;
      margin-bottom: 1.05em; }
    .root .format-aside .entry-title,
    .root .format-image .entry-title,
    .root .format-video .entry-title,
    .root .format-quote .entry-title,
    .root .format-gallery .entry-title,
    .root .format-status .entry-title,
    .root .format-link .entry-title,
    .root .format-audio .entry-title,
    .root .format-chat .entry-title {
      font-size: 23px;
      font-size: 1.4375em;
      line-height: 1.304347826;
      margin-bottom: 1.826086957em; }
    .root .post-thumbnail {
      margin-bottom: 2.625em; }
    .root .entry-content h1,
    .root .entry-summary h1,
    .root .comment-content h1 {
      font-size: 33px;
      font-size: 2.0625rem;
      line-height: 1.2727272727;
      margin-top: 1.696969697em;
      margin-bottom: 0.8484848485em; }
    .root .entry-content h2,
    .root .entry-summary h2,
    .root .comment-content h2 {
      font-size: 28px;
      font-size: 1.75rem;
      line-height: 1.25;
      margin-top: 2em;
      margin-bottom: 1em; }
    .root .entry-content h3,
    .root .entry-summary h3,
    .root .comment-content h3 {
      font-size: 23px;
      font-size: 1.4375rem;
      line-height: 1.2173913043;
      margin-top: 2.4347826087em;
      margin-bottom: 1.2173913043em; }
    .root .entry-content h4,
    .root .entry-summary h4,
    .root .entry-intro h4,
    .root .comment-content h4 {
      letter-spacing: 0.131578947em; }
    .root .entry-content h4,
    .root .entry-content h5,
    .root .entry-content h6,
    .root .entry-summary h4,
    .root .entry-summary h5,
    .root .entry-summary h6,
    .root .comment-content h4,
    .root .comment-content h5,
    .root .comment-content h6 {
      font-size: 19px;
      font-size: 1.1875rem;
      line-height: 1.1052631579;
      margin-top: 2.9473684211em;
      margin-bottom: 1.473684211em; }
    .root .author-info {
      border-bottom-width: 0;
      padding-bottom: 0; }
    .root .comment-list + .comment-respond,
    .root .comment-navigation + .comment-respond {
      padding-top: 5.25em; }
    .root .comments-area,
    .root .sidebar,
    .root .content-bottom-widgets .widget-area,
    .root .widecolumn {
      margin-bottom: 7em; }
    .root body:not(.search-results) .entry-summary {
      margin-bottom: 2.210526316em; }
    .root body:not(.search-results) .entry-header + .entry-summary {
      margin-top: -1.105263158em; }
    .root body:not(.search-results) article:not(.type-page) .entry-content {
      float: right;
      width: 71.42857144%; }
    .root body:not(.search-results)
article:not(.type-page)
.entry-content
> blockquote.alignleft.below-entry-meta {
      margin-left: -40%;
      width: -webkit-calc(60% - 1.4736842105em);
      width: calc(60% - 1.4736842105em); }
    .root body:not(.search-results) article:not(.type-page) img.below-entry-meta,
    .root body:not(.search-results) article:not(.type-page) figure.below-entry-meta {
      clear: both;
      display: block;
      float: none;
      margin-right: 0;
      margin-left: -40%;
      max-width: 140%; }
    .root body:not(.search-results)
article:not(.type-page)
figure.below-entry-meta
img.below-entry-meta,
    .root body:not(.search-results)
article:not(.type-page)
table
figure.below-entry-meta,
    .root body:not(.search-results) article:not(.type-page) table img.below-entry-meta {
      margin: 0;
      max-width: 100%; }
    .root body:not(.search-results) article:not(.type-page) .entry-footer {
      float: left;
      margin-top: 0.1538461538em;
      width: 21.42857143%; }
    .root body:not(.search-results)
article:not(.type-page)
.entry-footer
> span:not(:last-child):after {
      display: none; }
    .root .single .byline,
    .root .full-size-link,
    .root body:not(.search-results).group-blog .byline,
    .root body:not(.search-results) .entry-format,
    .root body:not(.search-results) .cat-links,
    .root body:not(.search-results) .tags-links,
    .root body:not(.search-results) article:not(.sticky) .posted-on,
    .root body:not(.search-results) article:not(.type-page) .comments-link,
    .root body:not(.search-results) article:not(.type-page) .entry-footer .edit-link {
      display: block;
      margin-bottom: 0.5384615385em; }
    .root body:not(.search-results)
article:not(.type-page)
.entry-footer
> span:last-child {
      margin-bottom: 0; }
    .root body:not(.search-results) article:not(.type-page) .entry-footer .avatar {
      display: block;
      height: auto;
      margin: 0 0 0.5384615385em;
      width: 49px; }
    .root body.no-sidebar:not(.search-results) article:not(.type-page) .entry-content {
      float: left;
      margin-right: -100%;
      margin-left: 34.99999999%;
      width: 50.00000001%; }
    .root body.no-sidebar:not(.search-results) article:not(.type-page) .entry-footer {
      margin-right: -100%;
      margin-left: 15%;
      width: 15%; } }
  @media screen and (min-width: 75em) {
    .root body:not(.search-results) .entry-summary {
      font-size: 23px;
      font-size: 1.4375rem;
      line-height: 1.5217391304;
      margin-bottom: 1.826086957em; }
    .root body:not(.search-results) .entry-header + .entry-summary {
      margin-top: -0.913043478em; }
    .root body:not(.search-results) .entry-summary p,
    .root body:not(.search-results) .entry-summary address,
    .root body:not(.search-results) .entry-summary hr,
    .root body:not(.search-results) .entry-summary ul,
    .root body:not(.search-results) .entry-summary ol,
    .root body:not(.search-results) .entry-summary dl,
    .root body:not(.search-results) .entry-summary dd,
    .root body:not(.search-results) .entry-summary table {
      margin-bottom: 1.5217391304em; }
    .root body:not(.search-results) .entry-summary li > ul,
    .root body:not(.search-results) .entry-summary blockquote > ul {
      margin-left: 0.956521739em; }
    .root body:not(.search-results) .entry-summary li > ol,
    .root body:not(.search-results) .entry-summary blockquote > ol {
      margin-left: 1.52173913em; }
    .root body:not(.search-results) .entry-summary blockquote {
      font-size: 23px;
      font-size: 1.4375rem;
      line-height: 1.5217391304;
      margin: 0 0 1.5217391304em;
      padding-left: 1.347826087em; }
    .root body:not(.search-results)
.entry-summary
blockquote:not(.alignleft):not(.alignright) {
      margin-left: -1.52173913em; }
    .root body:not(.search-results)
.entry-summary
blockquote
blockquote:not(.alignleft):not(.alignright) {
      margin-left: 0; }
    .root body:not(.search-results) .entry-summary blockquote cite,
    .root body:not(.search-results) .entry-summary blockquote small {
      font-size: 19px;
      font-size: 1.1875rem;
      line-height: 1.8421052632; }
    .root body:not(.search-results) .entry-summary th,
    .root body:not(.search-results) .entry-summary td {
      padding: 0.3043478261em; }
    .root body:not(.search-results) .entry-summary pre {
      font-size: 16px;
      font-size: 1rem;
      line-height: 1.75;
      margin-bottom: 1.75em;
      padding: 1.75em; }
    .root body:not(.search-results) .entry-summary fieldset {
      margin-bottom: 1.5217391304em;
      padding: 0.3043478261em; }
    .root body:not(.search-results) .entry-summary h1 {
      margin-top: 2.121212121em;
      margin-bottom: 1.060606061em; }
    .root body:not(.search-results) .entry-summary h2 {
      margin-top: 2.5em;
      margin-bottom: 1.25em; }
    .root body:not(.search-results) .entry-summary h3 {
      margin-top: 3.043478261em;
      margin-bottom: 1.52173913em; }
    .root body:not(.search-results) .entry-summary h4,
    .root body:not(.search-results) .entry-summary h5,
    .root body:not(.search-results) .entry-summary h6 {
      margin-top: 3.684210526em;
      margin-bottom: 1.842105263em; }
    .root body:not(.search-results) .entry-summary h1:first-child,
    .root body:not(.search-results) .entry-summary h2:first-child,
    .root body:not(.search-results) .entry-summary h3:first-child,
    .root body:not(.search-results) .entry-summary h4:first-child,
    .root body:not(.search-results) .entry-summary h5:first-child,
    .root body:not(.search-results) .entry-summary h6:first-child {
      margin-top: 0; }
    .root body:not(.search-results) .entry-summary .alignleft {
      margin: 0.2608695652em 1.5217391304em 1.5217391304em 0; }
    .root body:not(.search-results) .entry-summary .alignright {
      margin: 0.2608695652em 0 1.5217391304em 1.5217391304em; }
    .root body:not(.search-results) .entry-summary .aligncenter {
      margin-bottom: 1.5217391304em; } }
  @media print {
    .root form,
    .root button,
    .root input,
    .root select,
    .root textarea,
    .root .navigation,
    .root .main-navigation,
    .root .social-navigation,
    .root .sidebar,
    .root .content-bottom-widgets,
    .root .header-image,
    .root .page-links,
    .root .edit-link,
    .root .comment-respond,
    .root .comment-edit-link,
    .root .comment-reply-link,
    .root .comment-metadata .edit-link,
    .root .pingback .edit-link {
      display: none; }
    .root body,
    .root blockquote cite,
    .root blockquote small,
    .root pre,
    .root .entry-content h4,
    .root .entry-content h5,
    .root .entry-content h6,
    .root .entry-summary h4,
    .root .entry-summary h5,
    .root .entry-summary h6,
    .root .comment-content h4,
    .root .comment-content h5,
    .root .comment-content h6,
    .root .entry-content .author-title {
      font-size: 12pt; }
    .root blockquote {
      font-size: 14.25pt; }
    .root .site-title,
    .root .page-title,
    .root .comments-title,
    .root .entry-content h2,
    .root .entry-summary h2,
    .root .comment-content h2,
    .root .widecolumn h2 {
      font-size: 17.25pt; }
    .root .site-description {
      display: block; }
    .root .entry-title {
      font-size: 24.75pt;
      line-height: 1.2727272727;
      margin-bottom: 1.696969697em; }
    .root .format-aside .entry-title,
    .root .format-image .entry-title,
    .root .format-video .entry-title,
    .root .format-quote .entry-title,
    .root .format-gallery .entry-title,
    .root .format-status .entry-title,
    .root .format-link .entry-title,
    .root .format-audio .entry-title,
    .root .format-chat .entry-title {
      font-size: 17.25pt;
      line-height: 1.304347826;
      margin-bottom: 1.826086957em; }
    .root .entry-content h1,
    .root .entry-summary h1,
    .root .comment-content h1 {
      font-size: 21pt; }
    .root .entry-content h3,
    .root .entry-summary h3,
    .root .comment-content h3,
    .root body:not(.search-results) .entry-summary {
      font-size: 14.25pt; }
    .root .site-description,
    .root .author-bio,
    .root .entry-footer,
    .root .sticky-post,
    .root .taxonomy-description,
    .root .entry-caption,
    .root .comment-metadata,
    .root .comment-notes,
    .root .comment-awaiting-moderation,
    .root .site-info,
    .root .wp-caption .wp-caption-text,
    .root .gallery-caption {
      font-size: 9.75pt; }
    .root body,
    .root .site {
      background: none !important;
      /* Brute force since user agents all print differently. */ }
    .root body,
    .root blockquote cite,
    .root blockquote small,
    .root .site-branding .site-title a,
    .root .entry-title a,
    .root .comment-author {
      color: #1a1a1a !important;
      /* Make sure color schemes don't affect to print */ }
    .root blockquote,
    .root .page-header,
    .root .comments-title {
      border-color: #1a1a1a !important;
      /* Make sure color schemes don't affect to print */ }
    .root blockquote,
    .root .site-description,
    .root body:not(.search-results) .entry-summary,
    .root body:not(.search-results) .entry-summary blockquote,
    .root .author-bio,
    .root .entry-footer,
    .root .entry-footer a,
    .root .sticky-post,
    .root .taxonomy-description,
    .root .entry-caption,
    .root .comment-author,
    .root .comment-metadata a,
    .root .comment-notes,
    .root .comment-awaiting-moderation,
    .root .site-info,
    .root .site-info a,
    .root .wp-caption .wp-caption-text,
    .root .gallery-caption {
      color: #686868 !important;
      /* Make sure color schemes don't affect to print */ }
    .root code,
    .root hr {
      background-color: #d1d1d1 !important;
      /* Make sure color schemes don't affect to print */ }
    .root pre,
    .root abbr,
    .root acronym,
    .root table,
    .root th,
    .root td,
    .root .author-info,
    .root .comment-list article,
    .root .comment-list .pingback,
    .root .comment-list .trackback,
    .root .no-comments {
      border-color: #d1d1d1 !important;
      /* Make sure color schemes don't affect to print */ }
    .root a {
      color: #007acc !important;
      /* Make sure color schemes don't affect to print */ }
    .root .entry-content a,
    .root .entry-summary a,
    .root .taxonomy-description a,
    .root .comment-content a,
    .root .pingback .comment-body > a {
      box-shadow: none;
      border-bottom: 1px solid #007acc !important;
      /* Make sure color schemes don't affect to print */ }
    .root .site {
      margin: 5%; }
    .root .site-inner {
      max-width: none; }
    .root .site-header {
      padding: 0 0 1.75em; }
    .root .site-branding {
      margin-top: 0;
      margin-bottom: 1.75em; }
    .root .site-main {
      margin-bottom: 3.5em; }
    .root .entry-header,
    .root .entry-footer,
    .root .page-header,
    .root .page-content,
    .root .entry-content,
    .root .entry-summary,
    .root .post-thumbnail,
    .root .comments-area {
      margin-right: 0;
      margin-left: 0; }
    .root .post-thumbnail,
    .root .site-main > article {
      margin-bottom: 3.5em; }
    .root .entry-content blockquote.alignleft,
    .root .entry-content blockquote.alignright {
      border-width: 4px 0 0 0;
      padding: 0.9473684211em 0 0;
      width: -webkit-calc(50% - 0.736842105em);
      width: calc(50% - 0.736842105em); }
    .root body:not(.search-results) .entry-header + .entry-summary {
      margin-top: -1.473684211em; }
    .root .site-footer,
    .root .widecolumn {
      padding: 0; } }
  .root body {
    background: #fff !important;
    font-family: 'Nunito Sans', sans-serif;
    color: #333;
    font-size: 16px;
    line-height: 1.6; }
  .root body:not(.custom-background-image):before,
  .root body:not(.custom-background-image):after {
    height: auto !important; }
  .root .cf:before,
  .root .cf:after {
    content: ' ';
    display: table; }
  .root .cf:after {
    clear: both; }
  .root .wrap {
    position: relative;
    max-width: 1170px;
    padding: 0 15px;
    margin: 0 auto; }
  .root .btn {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;
    padding: 15px 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #25c16f;
    border: 2px solid #25c16f;
    text-decoration: none;
    text-transform: uppercase; }
  .root .btn:hover,
  .root .btn:active {
    background-color: #fff;
    color: #333333;
    border: 2px solid #25c16f; }
  .root .site {
    margin: 0px !important; }
  .root .toggleMenu {
    display: none; }
  .root .pageHeader {
    position: relative; }
  .root .siteLogo {
    float: left;
    padding: 20px 0px; }
  .root .siteNav {
    float: right;
    padding: 50px 0px 34px 0px; }
  .root .topMenu {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    width: 800px; }
  .root .topMenu li {
    display: inline-block;
    margin-left: 25px;
    line-height: 42px; }
  .root .topMenu li a {
    text-decoration: none;
    color: #5c5c5c;
    border-bottom: 2px solid transparent;
    padding-bottom: 4px;
    -o-transition: color 0.2s linear;
    -moz-transition: color 0.2s linear;
    -khtml-transition: color 0.2s linear;
    -webkit-transition: color 0.2s linear;
    -ms-transition: color 0.2s linear;
    transition: color 0.2s linear; }
  .root .topMenu li a:hover,
  .root .topMenu li a:focus,
  .root .topMenu li a:active {
    color: #5c5c5c;
    border-color: #5c5c5c; }
  .root .conUsMenu {
    float: right; }
  .root .conUsMenu .btn {
    display: inline-block;
    padding: 10px 15px 8px 15px;
    color: #fff; }
  .root .conUsMenu .btn:hover {
    color: #333333;
    border-color: #25c16f; }
  .root .siteTopBanner {
    background-color: #2d308d;
    padding: 30px 0px; }
  .root .siteBnTitle {
    font-size: 30px;
    color: #fff;
    line-height: 1.6;
    display: inline-block;
    font-weight: 100; }
  .root .siteTopBanner .btn {
    float: right;
    position: relative;
    top: 0px;
    font-size: 16px; }
  .root .siteTopBanner .btn:active,
  .root .siteTopBanner .btn:focus {
    background-color: #fff !important;
    color: #333333 !important; }
  .root .siteTopBannerFixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999; }
  .root .modelBox {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 5%;
    max-width: 450px;
    margin: 0 auto;
    background-color: #fff;
    z-index: 9999999999999;
    padding: 20px 25px;
    background-color: #eff5f9; }
  .root .overlayPopup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 99999999999; }
  .root .btnClose {
    position: absolute;
    right: 0px;
    right: -15px;
    top: -15px;
    font-size: 30px;
    font-weight: bold;
    background-color: #000;
    height: 40px;
    width: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%; }
  .root .modelBox .popInqTitle {
    display: block;
    text-align: center;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 1.4;
    color: #000;
    margin-bottom: 15px; }
  .root .site-main {
    margin-bottom: 0 !important; }
  .root .blogMainPage .content-area {
    float: none !important;
    margin-right: 0 !important;
    width: 100% !important;
    padding-top: 60px; }
  .root .mlvedaBlogCat {
    margin: 0px -20px 30px; }
  .root .mlvedaBlogCat .post {
    position: relative;
    float: left;
    width: 33.33%;
    padding: 0px 40px 20px;
    border: none !important; }
  .root .postThumbnail {
    border: 0;
    display: block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 20px; }
  .root .postThumbnail img {
    display: block;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    max-height: 200px;
    min-height: 200px; }
  .root a.postThumbnail:hover img,
  .root a.postThumbnail:focus img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0.8; }
  .root .blogTitle {
    font-size: 18px !important;
    line-height: 1.4;
    margin-bottom: 0;
    min-height: 100px; }
  .root .blogTitle a {
    color: #283595; }
  .root .siteBanner {
    background-color: #2d308d;
    padding: 30px 0px; }
  .root .blogDetailsPage .content-area {
    margin-right: 0 !important;
    width: 55% !important;
    float: left !important;
    padding-left: 80px;
    padding-top: 60px; }
  .root .blogDetailsPage .content-area .entry-title {
    font-size: 27px !important;
    line-height: 1.4;
    color: #283595;
    margin-bottom: 25px;
    border-bottom: 2px solid #283595;
    padding-bottom: 25px; }
  .root .blogDetailsPage .entry-content {
    float: none !important;
    width: 100% !important; }
  .root .blogDetailsPage .entry-content img {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important; }
  .root .blogDetailsPage .entry-content a {
    color: #283595 !important; }
  .root .blogDetailsPage .entry-content ul {
    margin: 0px 0px 25px 15px !important; }
  .root .blogDetailsPage .comments-area {
    margin-bottom: 60px !important;
    margin-top: 30px !important; }
  .root .blogDetailsPage .sidebar {
    float: right !important;
    margin-left: 0 !important;
    padding: 0px !important;
    width: 27% !important; }
  .root .sidebarInqBlock {
    padding: 25px 20px;
    background-color: #eff5f9;
    -webkit-box-shadow: 0px 10px 35px 0px #dcdddd;
    -moz-box-shadow: 0px 10px 35px 0px #dcdddd;
    box-shadow: 0px 10px 35px 0px #dcdddd; }
  .root .sidebarTitle {
    font-size: 24px !important;
    font-weight: 300 !important;
    line-height: 1.4;
    color: #000;
    margin-bottom: 25px; }
  .root .sidebarTitle span {
    font-weight: 900 !important;
    display: block; }
  .root .sidebarInqBlock br,
  .root .modelBox br {
    display: none; }
  .root .sidebarInqBlock p,
  .root .modelBox p {
    margin-bottom: 0px !important; }
  .root .sidebarInqBlock label,
  .root .modelBox label {
    display: block !important;
    margin-bottom: 10px; }
  .root .sidebarInqBlock input,
  .root .modelBox input {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px !important;
    padding: 7px !important; }
  .root .sidebarInqBlock .wpcf7-textarea,
  .root .modelBox .wpcf7-textarea {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px !important;
    padding: 7px !important; }
  .root .sidebarInqBlock .wpcf7-submit,
  .root .modelBox .wpcf7-submit {
    font-family: 'Nunito Sans', sans-serif !important;
    font-size: 16px !important;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;
    padding: 15px !important;
    text-decoration: none;
    text-transform: uppercase;
    width: 100% !important;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #25c16f;
    border: 2px solid #25c16f;
    margin-top: 10px !important; }
  .root .sidebarInqBlock .wpcf7-submit:hover,
  .root .sidebarInqBlock .wpcf7-submit:focus,
  .root .sidebarInqBlock .wpcf7-submit:active {
    background-color: #fff !important;
    color: #333333 !important; }
  .root .modelBox .wpcf7-submit:hover,
  .root .modelBox .wpcf7-submit:focus,
  .root .modelBox .wpcf7-submit:active {
    background-color: #fff !important;
    color: #333333 !important; }
  .root div.wpcf7 .ajax-loader {
    display: block !important;
    margin-top: 10px !important; }
  .root div.wpcf7-response-output {
    margin: 0px !important; }
  .root .requiredFld,
  .root .required {
    color: red !important; }
  .root .sidebarInqFixed {
    position: fixed;
    width: 310px;
    top: 115px;
    z-index: 9999; }
  .root a.page-numbers {
    color: #283595 !important; }
  .root a.next,
  .root a.prev {
    color: #fff !important; }
  .root .pagination .prev:hover,
  .root .pagination .prev:focus,
  .root .pagination .next:hover,
  .root .pagination .next:focus {
    background-color: #25c16f !important;
    color: #fff; }
  .root .post-navigation {
    margin-bottom: 80px !important; }
  .root .post-navigation a {
    padding: 10px 0px !important; }
  .root .post-navigation a:hover .post-title,
  .root .post-navigation a:focus .post-title {
    color: #283595 !important; }
  .root .post-navigation .post-title {
    font-size: 18px !important;
    line-height: 1.25; }
  .root .form-submit #submit {
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    line-height: 1.2;
    padding: 15px 30px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    background-color: #25c16f;
    border: 2px solid #25c16f !important;
    text-decoration: none;
    text-transform: uppercase; }
  .root .form-submit #submit:hover,
  .root .form-submit #submit:active {
    background-color: #fff !important;
    color: #333333 !important;
    border: 2px solid #25c16f !important; }
  .root .siteBtmFixedBanner {
    display: none; }
  .root .site-footer {
    padding: 30px 60px !important; }
  .root .lastFooter {
    position: relative;
    padding: 30px 0px 30px 0px;
    border-top: 1px solid #e7e7e7; }
  .root .footerMainBlock {
    margin-bottom: 30px;
    width: 100%; }
  .root .footerLogo {
    float: left;
    width: 25%; }
  .root .footerMainBlock ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  .root .footerMainBlock ul li strong {
    font-weight: bold;
    display: block;
    margin-bottom: 10px; }
  .root .footerMainBlock ul li {
    margin-bottom: 7px; }
  .root .footerMainBlock ul li a {
    text-decoration: none;
    color: #555555;
    font-size: 14px; }
  .root .footerMainBlock ul li a:hover {
    color: #283595; }
  .root .footerBlock {
    float: left; }
  .root .footerBlock1 {
    width: 20%; }
  .root .footerBlock2 {
    width: 15%; }
  .root .footerBlock3 {
    width: 15%; }
  .root .footerBlock4 {
    width: 15%;
    padding-left: 20px; }
  .root .footerBlock5 {
    float: right; }
  .root .footerEndBlock {
    padding: 20px 0px;
    text-align: center;
    border: 1px solid #ddd;
    width: 50%;
    margin: 0 auto; }
  .root .footerEndBlock h6 {
    font-weight: bold;
    margin-bottom: 0;
    font-size: 16px; }
  .root .footerEndBlock h6 img {
    position: relative;
    top: -1px;
    padding-left: 5px;
    font-size: 12px; }
  .root .footerEndBlock span {
    font-size: 12px; }
  .root .cmtBlog {
    word-break: break-word; }
  .root .cmtBlog ul {
    margin-bottom: 0px !important; }
  .root .cmtBlog ul li {
    margin-bottom: 15px !important; }
  @media only screen and (max-width: 1169px) {
    .root .wrap {
      width: 100%; }
    .root .topMenu {
      width: 800px; } }
  @media only screen and (max-width: 1023px) {
    .root .topMenu {
      width: 700px; }
    .root .topMenu li {
      margin-left: 25px; }
    .root .siteBnTitle {
      font-size: 30px;
      line-height: 1.4; }
    .root .mlvedaBlogCat {
      margin: 0px 0px 30px; }
    .root .mlvedaBlogCat .post {
      padding: 0px 25px 20px; }
    .root .siteTopBanner {
      text-align: center; }
    .root .siteBnTitle {
      display: block;
      margin-bottom: 20px; }
    .root .siteTopBanner .btn {
      float: none;
      position: relative;
      top: 0px;
      font-size: 16px; }
    .root .blogDetailsPage .content-area {
      margin-right: 0 !important;
      width: 67% !important;
      float: left !important;
      padding-left: 0; }
    .root .blogDetailsPage .content-area .entry-header {
      margin: 0 !important; }
    .root .blogDetailsPage .content-area .post-thumbnail {
      margin: 0px 0px 20px !important; }
    .root .blogDetailsPage .entry-content {
      margin-right: 0 !important;
      margin-left: 0px !important; }
    .root .blogDetailsPage .comments-area {
      margin: 30px 0px 60px 0px !important; }
    .root .post-navigation {
      margin: 0px 0px 80px 0px !important; } }
  @media only screen and (max-width: 899px) {
    .root .toggleMenu {
      display: block;
      position: absolute;
      right: 15px;
      top: 30px;
      border: none;
      background-color: #fff;
      width: 45px;
      height: 45px;
      cursor: pointer;
      padding: 0px 10px; }
    .root .toggleMenu span {
      display: block;
      width: 100%;
      height: 2px;
      background-color: #2d308d;
      margin: 6px 0px; }
    .root .toggleMenu:hover,
    .root .toggleMenu:active,
    .root .toggleMenu:focus {
      background-color: #fff !important; }
    .root .toggleMenu:focus {
      outline: none; }
    .root .siteLogo {
      float: none;
      padding: 10px 0px;
      max-width: 140px; }
    .root .siteLogo a {
      display: block; }
    .root .siteNav {
      float: none;
      padding: 0; }
    .root .topMenu {
      display: none;
      width: 100%;
      position: absolute;
      top: 105px;
      background-color: #25c16f;
      left: 0;
      right: 0;
      z-index: 999; }
    .root .topMenu li {
      margin: 0;
      display: block;
      border-bottom: 1px solid #fff; }
    .root .topMenu li:first-child {
      border-top: 1px solid #fff; }
    .root .topMenu li a {
      padding: 0px 15px;
      display: block;
      font-weight: bold;
      border-bottom: 0px;
      color: #fff; }
    .root .topMenu li a:hover {
      background-color: #000 !important;
      color: #fff; }
    .root .conUsMenu {
      float: none; }
    .root .conUsMenu .btn {
      display: block !important;
      border: 0;
      text-transform: capitalize;
      font-weight: bold;
      font-size: 16px;
      color: #fff !important;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
      -ms-box-shadow: none;
      box-shadow: none !important;
      line-height: 42px;
      padding: 0px 15px; }
    .root .mlvedaBlogCat .post {
      width: 50%; }
    .root .pagination {
      margin: 20px 20px 80px !important; }
    .root .blogDetailsPage .content-area {
      float: none !important;
      width: 100% !important;
      margin-bottom: 15px !important; }
    .root .post-navigation {
      margin: 0px !important; }
    .root .blogDetailsPage .sidebar {
      float: none !important;
      margin-left: 0 !important;
      padding: 0px !important;
      width: 100% !important; }
    .root .form-submit #submit {
      width: 100% !important; }
    .root .footerLogo {
      width: 18%; }
    .root .footerBlock1 {
      width: 24%; }
    .root .footerEndBlock {
      width: 100%; } }
  @media only screen and (max-width: 767px) {
    .root .siteTopBanner {
      display: none !important; }
    .root .siteBtmFixedBanner {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 9999;
      background-color: #2d308d;
      padding: 30px 0px;
      text-align: center; }
    .root .siteBtmFixedBanner .btnClose {
      right: 0px !important;
      top: -50px !important;
      cursor: pointer; }
    .root .siteBnBtmTitle {
      font-size: 24px;
      color: #fff;
      line-height: 1.6;
      display: inline-block;
      font-weight: 100;
      margin-bottom: 20px; }
    .root .siteBtmFixedBanner .btn {
      display: block;
      width: 100%;
      font-size: 16px; }
    .root .siteBtmFixedBanner .btn:active,
    .root .siteBtmFixedBanner .btn:focus {
      background-color: #fff !important;
      color: #333333 !important; }
    .root .btnFixedArrow {
      position: fixed;
      bottom: 15px;
      right: 5px;
      font-size: 30px;
      font-weight: bold;
      background-color: #000;
      height: 40px;
      width: 40px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      z-index: 99; }
    .root .blogMainPage .content-area,
    .root .blogDetailsPage .content-area {
      padding-top: 15px !important; }
    .root .footerMainBlock {
      margin-bottom: 0; }
    .root .footerLogo {
      float: none;
      width: 100%;
      text-align: center;
      margin-bottom: 25px; }
    .root .footerBlock1,
    .root .footerBlock2,
    .root .footerBlock3,
    .root .footerBlock4,
    .root .footerBlock5 {
      width: 100%; }
    .root .footerBlock4 {
      padding-left: 0; }
    .root .footerBlock {
      float: none;
      text-align: center;
      margin-bottom: 15px; } }
  @media only screen and (max-width: 639px) {
    .root .modelBox {
      padding: 20px;
      max-width: 100%; }
    .root .btnClose {
      right: 0px; }
    .root .blogTitle {
      min-height: auto; }
    .root .mlvedaBlogCat .post {
      width: 100%;
      float: none;
      padding: 0px;
      text-align: center;
      margin-bottom: 30px; }
    .root .postThumbnail img {
      max-height: initial;
      min-height: auto;
      text-align: center;
      width: 100%; } }
  .root [class^='wp-block-'] figcaption {
    color: #686868;
    font-style: italic;
    line-height: 1.6153846154;
    padding-top: 0.5384615385em;
    text-align: left; }
  .root .rtl [class^='wp-block-'] figcaption {
    text-align: right; }
  .root p.has-drop-cap:not(:focus)::first-letter {
    font-size: 5em; }
  @media screen and (min-width: 61.5625em) {
    .root body:not(.search-results)
article:not(.type-page)
.wp-block-image
figcaption.below-entry-meta {
      clear: both;
      display: block;
      float: none;
      margin-right: 0;
      margin-left: -40%;
      max-width: 140%; }
    .root body.rtl:not(.search-results)
article:not(.type-page)
.wp-block-image
figcaption.below-entry-meta {
      margin-left: 0;
      margin-right: -40%; } }
  .root .wp-block-gallery {
    margin-bottom: 1.75em; }
  .root .wp-block-quote:not(.is-large):not(.is-style-large).alignleft,
  .root .wp-block-quote:not(.is-large):not(.is-style-large).alignright {
    border-left: none;
    padding-left: 0; }
  .root .rtl .wp-block-quote:not(.is-large):not(.is-style-large).alignleft,
  .root .rtl .wp-block-quote:not(.is-large):not(.is-style-large).alignright {
    border-right: none;
    padding-right: 0; }
  .root .wp-block-quote cite {
    color: #1a1a1a;
    display: block;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.75; }
  .root .wp-block-quote cite:before {
    content: '\2014\00a0'; }
  .root .wp-block-audio audio {
    display: block;
    width: 100%; }
  .root .wp-block-cover-image.aligncenter,
  .root .wp-block-cover.aligncenter {
    display: flex; }
  .root .wp-block-file .wp-block-file__button {
    background: #1a1a1a;
    border: 0;
    border-radius: 2px;
    color: #fff;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-weight: 700;
    letter-spacing: 0.046875em;
    line-height: 1;
    padding: 0.84375em 0.875em 0.78125em;
    text-transform: uppercase; }
  .root .wp-block-file .wp-block-file__button:hover,
  .root .wp-block-file .wp-block-file__button:focus {
    background: #007acc; }
  .root .wp-block-file .wp-block-file__button:focus {
    outline: thin dotted;
    outline-offset: -4px; }
  .root .rtl .wp-block-file * + .wp-block-file__button {
    margin-left: 0.75em;
    margin-right: 0; }
  .root .wp-block-code {
    border: 0;
    font-family: Inconsolata, monospace;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.75;
    padding: 0; }
  .root .wp-block-pullquote {
    border-width: 4px; }
  .root .wp-block-pullquote blockquote {
    border-left: 0;
    margin: 0;
    padding: 0; }
  .root .rtl .wp-block-pullquote blockquote {
    border-right: 0; }
  .root .wp-block-pullquote p {
    color: #686868;
    font-size: 19px;
    font-size: 1.1875rem; }
  .root .wp-block-pullquote cite {
    color: #1a1a1a;
    display: block;
    font-size: 16px;
    font-size: 1rem;
    font-style: none;
    line-height: 1.75;
    text-transform: none; }
  .root .wp-block-pullquote cite:before {
    content: '\2014\00a0'; }
  .root .wp-block-table,
  .root .wp-block-table th,
  .root .wp-block-table td {
    border: 1px solid #d1d1d1; }
  .root .wp-block-table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.75em;
    table-layout: fixed;
    width: 100%; }
  .root .wp-block-table th,
  .root .wp-block-table td {
    font-weight: normal;
    padding: 0.4375em;
    text-align: left; }
  .root .wp-block-table th {
    border-width: 0 1px 1px 0;
    font-weight: 700; }
  .root .wp-block-table td {
    border-width: 0 1px 1px 0; }
  .root .rtl .wp-block-table th,
  .root .rtl .wp-block-table td {
    text-align: right; }
  .root .wp-block-button .wp-block-button__link {
    box-shadow: none;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-weight: 700;
    letter-spacing: 0.046875em;
    line-height: 1;
    padding: 0.84375em 1.3125em 0.78125em;
    text-transform: uppercase; }
  .root .entry-content .wp-block-button__link {
    background: #1a1a1a;
    color: #fff; }
  .root .entry-content .is-style-outline .wp-block-button__link:not(.has-background) {
    background: transparent; }
  .root .entry-content .is-style-outline .wp-block-button__link:not(.has-text-color) {
    color: #1a1a1a; }
  .root .entry-content .wp-block-button__link:hover,
  .root .entry-content .wp-block-button__link:focus,
  .root .entry-content
.is-style-outline
.wp-block-button__link:not(.has-background):hover,
  .root .entry-content
.is-style-outline
.wp-block-button__link:not(.has-background):focus,
  .root .entry-content
.is-style-outline
.wp-block-button__link:not(.has-text-color):hover,
  .root .entry-content
.is-style-outline
.wp-block-button__link:not(.has-text-color):focus {
    background: #007acc;
    color: #fff; }
  .root .wp-block-button .wp-block-button__link:focus {
    outline: thin dotted;
    outline-offset: -4px; }
  .root hr.wp-block-separator {
    border: 0; }
  .root .wp-block-separator {
    margin-left: auto;
    margin-right: auto;
    max-width: 100px; }
  .root .wp-block-separator.is-style-wide {
    max-width: 100%; }
  .root .wp-block-media-text {
    margin-bottom: 1.75em; }
  .root .wp-block-media-text *:last-child {
    margin-bottom: 0; }
  .root .wp-block-archives.aligncenter,
  .root .wp-block-categories.aligncenter,
  .root .wp-block-latest-posts.aligncenter {
    list-style-position: inside;
    text-align: center; }
  .root .wp-block-latest-comments__comment-meta a {
    box-shadow: none;
    font-weight: 700; }
  .root .wp-block-latest-comments__comment-date {
    color: #686868;
    font-family: Montserrat, 'Helvetica Neue', sans-serif;
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.6153846154; }
  .root .wp-block-latest-comments .wp-block-latest-comments__comment {
    border-top: 1px solid #d1d1d1;
    margin-bottom: 0;
    padding: 1.75em 0; }
  .root .wp-block-latest-comments__comment-excerpt p:last-child {
    margin-bottom: 0; }
  .root .entry-content .has-dark-gray-color {
    color: #1a1a1a; }
  .root .entry-content .has-dark-gray-background-color {
    background-color: #1a1a1a; }
  .root .entry-content .has-medium-gray-color {
    color: #686868; }
  .root .entry-content .has-medium-gray-background-color {
    background-color: #686868; }
  .root .entry-content .has-light-gray-color {
    color: #e5e5e5; }
  .root .entry-content .has-light-gray-background-color {
    background-color: #e5e5e5; }
  .root .entry-content .has-white-color {
    color: #fff; }
  .root .entry-content .has-white-background-color {
    background-color: #fff; }
  .root .entry-content .has-blue-gray-color {
    color: #4d545c; }
  .root .entry-content .has-blue-gray-background-color {
    background-color: #4d545c; }
  .root .entry-content .has-bright-blue-color {
    color: #007acc; }
  .root .entry-content .has-bright-blue-background-color {
    background-color: #007acc; }
  .root .entry-content .has-light-blue-color {
    color: #9adffd; }
  .root .entry-content .has-light-blue-background-color {
    background-color: #9adffd; }
  .root .entry-content .has-dark-brown-color {
    color: #402b30; }
  .root .entry-content .has-dark-brown-background-color {
    background-color: #402b30; }
  .root .entry-content .has-medium-brown-color {
    color: #774e24; }
  .root .entry-content .has-medium-brown-background-color {
    background-color: #774e24; }
  .root .entry-content .has-dark-red-color {
    color: #640c1f; }
  .root .entry-content .has-dark-red-background-color {
    background-color: #640c1f; }
  .root .entry-content .has-bright-red-color {
    color: #ff675f; }
  .root .entry-content .has-bright-red-background-color {
    background-color: #ff675f; }
  .root .entry-content .has-yellow-color {
    color: #ffef8e; }
  .root .entry-content .has-yellow-background-color {
    background-color: #ffef8e; }
  .root figure.wp-block-table {
    border: none !important; }
  .root h2 {
    font-size: 24px !important; }
  .root h3 {
    font-size: 16px !important; }
  .root h4 {
    font-size: 16px !important; }
