@import './common/variables.scss';
@import './common/mixins.scss';
.last-step-wrapper {
   margin-left: 115px;
}

.last-step {
   & textarea {
      min-height: 100px;
   }
}

.last-step-btn {
  width: auto !important;
}

.btn-custom-link-secondary {
   text-align: center;
   & span {
      @include font-type($font-Proxima-regular, 16px, 400);
      color: $primary-font-color;
      cursor: pointer;
      opacity: 0.7;
   }
}

.shareLoveWithBtn:hover {
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
   .last-step-wrapper {
      margin-left: 0px;
   }
}