@import './mixins.scss';
@import './variables.scss';
html,
body {
   overflow-y: visible;
   margin: 0px;
   padding: 0px;
   @include font-type($font-Proxima-regular, 16px, 400);
   color: $primary-font-color;
   line-height: 1.5em;
   height: 100%;
   width: 100%;
}

.clear-fix {
   clear: both;
}

.float-left {
   float: left;
}

.light-font {
   @include font-type($font-Proxima-regular, 16px, 300);
   color: rgba(0, 0, 0, 0.4);
}

.regular-font {
   @include font-type($font-Proxima-regular, 16px, 400);
}

.bold-font {
   @include font-type($font-Proxima-bold, 16px, 900);
}
.position-relative{
   position: relative;
}
.mt5 {
   margin-top: 5px;
}
.mt10 {
   margin-top: 10px;
}

.mt15 {
   margin-top: 15px;
}

.mt25 {
   margin-top: 25px;
}

.mt30 {
   margin-top: 30px;
}

.mt50 {
   margin-top: 50px;
}

.mb15 {
   margin-bottom: 15px;
}

.mb10 {
   margin-bottom: 10px !important;
}

.mr10 {
   margin-right: 10px;
}

.mt20 {
   margin-top: 20px;
}

.mr15 {
   margin-right: 15px;
}

.mr25 {
   margin-right: 25px;
}

.mr20 {
   margin-right: 20px;
}

.partition-div-mt-30 {
   margin-top: 30px;
}

.test {
   padding: 15px;
   @include font-type($font-Proxima-regular, 14px, 400);
}

.css-xp4uvy {
  position: sticky;
}

.css-1g6gooi {
  vertical-align: middle;
  position: absolute;
}

.topDrop .custom-select-box__menu {
  top: auto !important;
  bottom: 100% !important;
  margin-bottom: 0px;
}

.round-upto-notice{
  padding-left: 20px;
  padding-top: 10px;
  @include font-type($font-Proxima-regular, 14px, 400);
}

.custom-select-box__multi-value {
   background: white !important;
   border: 1px solid $primary-border-color;
}

.custom-select-box__multi-value__remove:hover {
   background-color: $gray-background !important;
}

.custom-select-box__control {
   border-radius: 0 !important;
   box-shadow: none !important;
   background: #fff !important;
   border: 1px solid #d0d0d0 !important;
   padding: 5px 20px !important;
   font-size: 16px !important;
   line-height: 26px !important;
   color: #4D5055 !important;
}

.custom-select-box__control--is-focused {
   border: 1px solid $primary-color !important;
   cursor: pointer !important;
}

.custom-select-box__indicator-separator {
   display: none !important;
}

.custom-select-box__menu {
   margin-top: -1px !important;
   border-radius: 0px !important;
   border: 1px solid $primary-color !important;
   border-top: 1px solid #f1f1f1 !important;
   box-shadow: none !important;
}

.custom-select-box__indicator {
   color: $primary-color !important;
   align-items: flex-end !important;
   padding-right: 0px !important;
}

.custom-select-box__option {
   color: #808080 !important;
   font-size: 16px !important;
   padding: 12px 15px !important;
}

.custom-select-box__option--is-selected {
   background-color: #f8f8f8 !important;
   color: $primary-color !important;
}

.custom-select-box__option--is-focused {
   background-color: #f8f8f8 !important;
   color: $primary-color !important
}

.custom-select-box__value-container {
   padding: 0px !important;
   height: 26px !important;
   padding-left: 5px !important;
   vertical-align: middle;
}

.custom-select-box__value-container--has-value {
   height: 26px !important;
   padding-left: 5px !important;
   vertical-align: middle;
}

.custom-select-box__value-container--is-multi {
   max-height: 150px !important;
   overflow: auto !important;
   height: auto !important;
   margin: -5px -20px -5px 0px;
   padding: 5px 0px !important;
}

.custom-select-box__multi-value:nth-child(1) {
   background: $gray-background !important;
}

.custom-select-box__value-container--is-multi+.custom-select-box__indicators {
   align-items: flex-end !important;
   display: none !important;
}

.select-checkbox {
   display: none;
}

.select-checkbox:checked+.select-checkbox-label {
   background: $primary-color;
}

.select-checkbox:checked+.select-checkbox-label:after {
   content: '';
   position: absolute;
   left: 2px;
   top: 6px;
   background: white;
   width: 2px;
   height: 2px;
   box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
   transform: rotate(45deg);
}

.select-checkbox-label {
   /* Style for checkbox normal */
   width: 16px;
   height: 16px;
   display: block;
   background: $gray-background;
   border-radius: 2px;
   position: relative;
   border: 1px solid $primary-border-color;
}

.select-checkbox-label>span {
   display: inline-block;
   padding-left: 25px;
   margin-top: -4px;
   position: absolute;
   width: 350px;
   text-overflow: ellipsis;
   white-space: nowrap;
   overflow: hidden;
   cursor: pointer;
}

.select-checkbox-label.checked {
   background: $primary-color;
}

.select-checkbox-label.checked:after {
   content: '';
   position: absolute;
   left: 4px;
   top: 7px;
   background: white;
   width: 2px;
   height: 2px;
   box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
   transform: rotate(45deg);
}

.search-wrapper {
   border: 1px solid $primary-border-color;
   margin: 20px;
   padding: 15px 20px;
   & .tag-name-container {
      display: inline-block;
      margin-right: 15px;
      background: #ccc;
      color: white;
      padding: 5px 10px;
   }
   & .search-result-wrapper {
      list-style-type: none;
      padding: 0px;
      border: 1px solid $primary-border-color;
      & li {
         color: #808080;
         font-size: 16px;
         padding: 12px 15px;
         &:hover {
            background-color: #f8f8f8;
            color: $primary-color;
            cursor: pointer;
            & label {
               cursor: pointer;
            }
         }
      }
   }
}

.text-align-center {
   text-align: center;
}

// css for label
.custom-label {
   position: relative;
   margin-bottom: 10px; // @include font-type($font-Proxima-regular, 14px, 900);
   @include font-type($font-Proxima-bold, 16px, 900);
   color: $primary-font-color;
}

// css for textox
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
   width: 100%;
   min-height: 46px;
   box-sizing: border-box;
   border: 1px solid #d0d0d0;
   background: #fff;
   padding: 11px 14px;
   font-size: 16px;
   word-break: normal;
   outline: none;
   -webkit-appearance: none;
   border-radius: 0;
   line-height: normal;
   margin: 0;
}

.btn-custom-link {
   @include font-type($font-Proxima-regular, 16px, 400);
   color: $primary-color;
   cursor: pointer;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
textarea:focus {
   border-color: $primary-color;
}

button:focus {
   outline: none;
}

button:active,
button .active,
.button:active,
.button .active,
a.button:active,
a.button .active,
a.button:visited:active,
a.button:visited .active,
a.button:link:active,
a.button:link .active {
   opacity: 0.6;
}

//css for button
%btn-main {
   -webkit-appearance: none;
   font-weight: 100;
   text-align: center;
   text-decoration: none;
   line-height: 20px;
   cursor: pointer;
   outline: none;
   padding: 12px 12px 11px;
   border-radius: 0;
   display: inline-block;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   box-sizing: border-box;
}

.btn-custom-back {
   color: $primary-color;
   margin-right: 10px;
   cursor: pointer;
   background: #fff;
   border-radius: 50%;
   border: 1px solid $primary-color;
   padding: 7px;
   display: inline-flex;
   & img {
      width: 20px;
   }
}

.btn-custom-primary {
   @extend %btn-main;
   background-color: $primary-btn-color;
   -webkit-appearance: none;
   border: 1px solid $primary-btn-color;
   color: #fff;
   & svg{
      margin-right: 5px;
   }
   &.disabled{
      pointer-events: none;
      opacity: 0.8;
   }
}

.btn-custom-secondary {
   @extend %btn-main;
   background-color: transparent;
   border: 1px solid #b8b298;
   color: #b8b298;
}

.btn-custom-third {
   @extend %btn-main;
   background-color: white;
   -webkit-appearance: none;
   border: 1px solid $primary-btn-color;
   color: $primary-btn-color;
   padding: 8px 12px 8px;
}

.btn-custom-alert {
   @extend %btn-main;
   background-color: white;
   -webkit-appearance: none;
   border: 1px solid red;
   color: red;
}

.info-icon {
   color: $primary-border-color;
   margin-left: 5px;
}

// css for toggle switch
.switch-wrapper.on {
   color: #14c6a2;
   border: 1px solid #00c19a;
   background-color: #fff;
}

.title-container {
   max-width: 580px;
   width: 100%;
   & div {
      margin-bottom: 10px;
   }
   & .custom-label {
      margin-bottom: 5px;
      vertical-align: super;
   }
}

.switch-wrapper.off {
   color: #fff;
   border: 1px solid #a8a8a8;
   background-color: #a8a8a8;
   position: relative;
}

.switch-wrapper.off .is.on {
   left: -100%;
}

.switch-wrapper.off .is.off {
   margin-right: -6px;
}

.switch-wrapper {
   cursor: pointer;
   display: inline-block;
   overflow: hidden;
   position: relative;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   font-size: 14px;
   text-transform: uppercase;
   line-height: 1;
   word-wrap: normal;
   word-break: normal;
}

.switch-wrapper.on .is.on {
   left: 0;
}

.switch-wrapper .is {
   margin: 0 2px;
   padding: 9px 30px 7px 10px;
   position: relative;
   width: 100%;
   box-sizing: border-box;
   transition: left 0.2s linear, margin 0.2s linear;
}

.switch-wrapper.on .is.off {
   margin-right: 26px;
}

.switch-wrapper .is.off {
   right: -99%;
   padding-left: 25px;
   position: absolute;
   top: 0;
}

.switch-wrapper .is.off:before {
   position: absolute;
   left: 0;
   top: 6px;
   content: '';
   display: inline-block;
   width: 18px;
   height: 18px;
   border-radius: 50%;
}

.switch-wrapper.on .is:before {
   background: #00c19a;
}

.switch-wrapper.off .is:before {
   background: #fff;
}

.custom-container {
   width: 100%;
   max-width: 1024px;
   margin: 0 auto;
   margin-top: 35px;
   &.margin{
      margin-bottom: 185px;
   }
}

.custom-container-1 {
   max-width: 600px;
   margin: 0 auto;
   width: 100%;
   & .content-wrapper {
      padding: 30px 70px;
      width: 100%;
      border: 1px solid $primary-border-color;
   }
}

.content-wrapper {
   width: 100%;
   max-width: 810px;
   padding: 15px 0px 15px 0px;
   margin: 0 auto;
   &.setup-done {
      float: none;
      margin: 0 auto;
   }
   &.margin {
    padding-bottom: 185px;
   }
}

.section-wrapper {
   padding: 30px 70px;
   max-width: 580px;
   width: 100%;
   float: left;
   border: 1px solid $primary-border-color;
}

.float-right {
   float: right;
}

.save-btn-wrapper {
   background-color: $gray-background;
   width: calc(100% + 140px);
   padding: 15px 0px;
   text-align: center;
   margin: 30px -70px -30px -70px;
   margin-top: 30px;
   border-top: 1px solid $primary-border-color;
}

.color-picker-wrapper {
   width: 100%;
   position: relative;
   & .color-picker {
      cursor: pointer;
      min-height: 38px;
      padding: 10px 15px;
      font-size: 16px;
      border: 1px solid $primary-border-color;
      span {
         color: $primary-font-color;
      }
      & .color-shower {
         display: inline-block;
         background: rgba(1, 1, 1, 0.5);
         float: right;
         width: 25px;
         height: 25px;
         border-radius: 50%;
         margin-top: -2px;
      }
   }
}

.color-code-wrapper {
   padding: 15px;
}

.circle-picker {
   width: 105% !important;
}

.dotted-partition {
   margin: 30px auto;
   width: calc(100% - 50px);
   border-bottom: 2px dotted $primary-border-color;
}

.notice-support {
   @include font-type($font-Proxima-regular, 14px, 400);
   width: calc(100% - 50px);
   margin: auto;
}

.contact-us {
   @include font-type($font-Proxima-regular, 14px, 400);
   width: calc(100% + 30px);
   margin: auto;
   margin-left: -10px;
}

.color-picker-error {
   position: absolute;
   padding: 10px;
   color: red;
   font-size: 12px;
   border-radius: 4px;
   background: white;
   top: 47px;
   border: 1px solid red;
}

.advanced-page-header {
   width: 100%;
   padding: 30px 100px;
   border-bottom: 1px solid $primary-border-color;
   .title {
      text-align: center;
      @include font-type($font-Proxima-bold, 22px, 900);
      margin-top: -32px;
   }
}

.page-navigation {
   list-style: none;
   margin: 0;
   padding: 0;
   padding-top: 80px;
   & .navbar-link {
      font-size: 16px;
      font-weight: 700;
      color: $primary-font-color;
      font-weight: normal;
      padding-bottom: 15px;
      line-height: 20px;
      cursor: pointer;
      display: block;
      &:visited,
      &:hover {
         text-decoration: none;
         border-bottom: none;
      }
      &.selected {
         font-family: $font-Proxima-bold !important;
         font-weight: 900;
      }
   }
   & .importantNoteLink {
     color: $red-font-color;
   }
}

.side-menu {
   float: left;
   width: 160px;
   display: inline-block;
}

.responsive-menu {
   display: none;
   margin-top: -30px;
   & .selected-menu {
      border-bottom: 1px solid #d3d3d3;
      padding: 10px;
      cursor: pointer;
      text-align: center;
      span:nth-child(1) {
         @include font-type($font-Proxima-bold, 18px, 900);
      }
   }
   & .page-navigation-wrapper {
      position: relative;
      & .page-navigation {
         transition: all 0.2s ease-in-out;
         -webkit-transition: all 0.2s ease-in-out;
         &.show {
            // height: 260px;
            padding: 10px;
            display: block;
         } // height: 0px;
         display: none;
         overflow: hidden;
         border-top: 0px;
         padding-top: 0px;
         text-align: center;
         position: absolute;
         background: white;
         width: 99%;
         z-index: 9;
         right: 0;
         left: 0;
         margin: 0 auto;
         border: 1px solid $primary-border-color;
         border-top: 0px;
         box-shadow: 0 6px 16px #3333;
      }
   }
}

.responsive-setup-container {
   display: none;
   padding: 15px;
   .setup-container {
      display: block;
      width: 100%;
      border: 1px solid $primary-border-color;
      margin-bottom: 15px;
   }
}

.help-link {
   display: none;
   margin-left: 2px;
}

.proLink {
  padding: 4px 12px;
  border: 1px solid $primary-btn-color;
  margin-left: 10px;
  color: $primary-btn-color;
  font-weight: 100;
  font-family: "Proxima Nova Rg Regular", sans-serif;
}

.custom-label-ip {
  width: 80%;
}

.proLink-ip {
  width: 20%;
  vertical-align: top;
  margin-left: 0px;
}

.set-default {
  color: $primary-btn-color;
  cursor: pointer;
}

.setDefaultCustomMessage {
  position: absolute;
  bottom: 15px;
}

.appNameLabel {
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
  margin-left: 3px;
  vertical-align: middle;
  display: table-cell;
}

.toastImg {
  margin-right: 10px;
  margin-left: 10px;
}

.Toastify__toast--success {
  background: white;
  color: $green-font-color;
  border: 1px solid $green-font-color;
}

.Toastify__toast--error {
  background: white;
  color: $red-font-color;
  border: 1px solid $red-font-color;
}

.close-btn-video {
  position: absolute;
  top: -20px;
  right: -16px;
  font-size: 10px;
  padding: 5px 8px;
  border-radius: 50%;
  background: white;
  border: 3px solid #d0d0d0;
  cursor: pointer;
  padding-bottom: 0px;
}

.modal-dialog {
  height: 100%;
  width: 100%;
  max-width: 887px;
}

.impNoteText {
   font-size: 30px;
   text-align: center;
   font-weight: 900;
   margin-bottom: 40px;
   color: red;
   font-family: 'Proxima Nova Bold';
}

.impNoticeModal {
   max-width: 65%;
   margin-top: 20vh;
}

.impNoticeDialog{
   padding: 70px 60px !important;
   line-height: 1.3;
   & .importantIcon-store {
      padding: 6px 33px;
      font-size: 50px;
   }
   & .noticeDiv-store {
      margin-right: 0px;
      margin-left: 0px;
   }
   & .closeBtn {
      font-size: 26px;
      position: absolute;
      right: 65px;
      padding: 0px 10px;
      border-radius: 50%;
      border: 2px solid;
      top: 40px;
      font-weight: 600;
      cursor: pointer;
   }
}

.font-22 {
   font-size: 24px !important;
}

.font-14 {
  font-size: 14px;
}

.impNoteBtn {
   text-align: center;
   margin-top: 30px;
   & .btn-custom-primary  {
      width: 160px;
   }
}

.color-red {
   color: red;
   font-weight: 900;
   font-family: 'Proxima Nova Bold';
}
.underline {
   text-decoration: underline;
   font-weight: 900;
   font-family: 'Proxima Nova Bold';
   text-underline-position: under;
}
.font-weight {
   font-weight: 900;
   font-family: 'Proxima Nova Bold';
}

.visibilty-hidden {
  visibility: hidden;
}

.footerMain {
   height: 150px;
   background: #e3e7eb;
   color: #4D5055;
   font-size: 30px;
   position: fixed;
   bottom: 0;
   width: 100%;
   display: table;
   z-index: 999;
   line-height: 1.2;
   padding: 0px 90px;
   & .inner {
      vertical-align: middle;
      display: table-cell;
   }
   & .pl25 {
      padding-left: 25px;
   }
   & .importantIcon-store {
      padding: 8px 20px;
   }
}

.app-status-main {
  border: 1px solid #d0d0d0;
  padding: 12px 20px;
  margin: 0 auto;
  width: 60%;
  & .success {
    padding: 3px 8px;
    background: #bbe5b3;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
  }
  & .error {
    padding: 3px 8px;
    background: #fead9a;
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    margin-left: 5px;
  }
  & .btn-custom-secondary {
    float: right;
    padding: 2px 12px;
    font-size: 14px;
    color: $primary-color;
    border-color: $primary-color;
  }
}

.line-seperator {
  height: 20px;
  border-bottom: 1px solid #d0d0d0;
}

.settings-main-div {
  width: 640px;
  margin: 0 auto;
  padding-top: 20px;
  justify-content: flex-start;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  max-width: 100%;
  & .settings-card {
    padding: 20px;
    width: 160px;
    flex: 0 0 auto;
    text-align: center;
    cursor: pointer;
    position: relative;
    & .pro {
      border: 1px solid $primary-color;
      background: #fff;
      color: $primary-color;
      font-weight: 400;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0px 15px;
      border-radius: 50px;
      font-size: 12px;
    }
    & img {
      width: 48px;
      height: 48px;
      margin-top: 8px;
    }
    & .name {
      margin-top: 12px;
    }
    & .secondLine {
      line-height: 1.1;
    }
  }
}

@media screen and (max-width: 768px) {
   .help-link {
      display: inline-block;
   }
   .app-name-wrapper {
      width: 150px;
   }
   .app-status-wrapper {
      & label {
         display: none;
      }
   }
   .title-container {
      max-width: 100%; // padding: 25px;
      & div:nth-child(1) {
         display: none;
      }
   }
   .section-wrapper {
      padding: 15px;
      
   }
   .content-wrapper {
      width: 100%;
      padding: 15px;
      padding-top: 0px;
   }
   .side-menu {
      display: none;
   }
   .responsive-menu {
      display: block;
   }
   .responsive-setup-container {
      display: block;
   }
   .contact-us {
      width: calc(100%);
      margin: auto;
   }
   .custom-container-1 {
      padding: 15px;
      & .content-wrapper {
         padding: 25px;
      }
   }
   .setDefaultCustomMessage {
      position: relative;
      bottom: 0px;
      padding-left: 15px;
   }
   .footerMain {
      font-size: 21px;
      height: 140px;
      padding: 5px 20px;
      & .pl25 {
         padding-left: 20px;
      }
   }
   .custom-container{
      &.margin{
         margin-bottom: 175px;
      }
   }
   .impNoticeModal {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin-top: 10px;
   }
   .impNoticeDialog {
      padding: 30px !important;
      & .closeBtn {
         right: 20px;
         top: 15px;
      }
      & .importantIcon-store {
         padding: 7px 25px;
         font-size: 35px;
      }
   }
}

@media screen and (max-width: 1040px) and (min-width: 769px) {
   .save-btn-wrapper {
      width: calc(100% + 60px);
      margin: 30px -30px -30px -30px;
   }
   .side-menu {
      width: 19%;
   }
   .custom-container {
      padding: 0 10px;
   }
   .content-wrapper {
      width: 80%;
      padding: 15px 0px 15px 25px;
   }
   .section-wrapper {
      padding: 30px;
   }
   .title-container {
      width: 75%;
   }
   .section-wrapper {
      width: 75%;
   }
}

@media screen and (max-width: 768px) {
   .mt30-responsive {
      margin-top: 30px;
   }
   .custom-label,
   .btn-custom-link {
      font-size: 14px;
   }
   html,
   body {
      font-size: 14px;
   }
   .title-container {
      max-width: 100%; // padding: 25px;
   }
   .section-wrapper {
      padding: 15px;
      max-width: 100%;
   }

   .advanced-page-header {
      display: none;
   }
   .save-btn-wrapper {
      width: calc(100% + 30px);
      margin: 15px -15px -15px -15px;
   }
   .app-status-main {
    width: 100%;
  }
}

.pricing-btn-container {
   text-align: center;
   & span {
      cursor: pointer;
      margin-right: 15px;
      color: white;
      display: inline-block;
      padding: 3px 10px;
      width: 150px;
      text-align: center;
      & div:nth-child(1) {
         @include font-type($font-Proxima-regular, 24px, 600);
      }
      & div:nth-child(2) {
         @include font-type($font-Proxima-regular, 12px, 400);
      }
   }
   & span:nth-child(1) {
    background: $red-font-color;
   }
   & span:nth-child(2) {
      background: $primary-btn-color;
   }
}

.sc-gsTEea, .sc-gsTEea code, .sc-gsTEea span {
   background: #f7f7f7 !important;
}

.sc-gsTEea button {
   cursor: pointer;
}