@import '../../src/styles/common/mixins.scss';
@import '../../src/styles/common/variables.scss';


.mt15 {
   margin-top: 15px;
}

.mt25 {
   margin-top: 25px;
}

.mb15 {
   margin-bottom: 15px;
}

.test {
   padding: 15px;
   @include font-type($font-Proxima-regular, 14px, 400);
}





.test {}

.select-checkbox {
   display: none;
}







.search-wrapper {
   border: 1px solid $primary-border-color;
   margin: 20px;
   padding: 15px 20px;
   & .tag-name-container {
      display: inline-block;
      margin-right: 15px;
      background: #ccc;
      color: white;
      padding: 5px 10px;
   }
   & .search-result-wrapper {
      list-style-type: none;
      padding: 0px;
      border: 1px solid $primary-border-color;
      & li {
         color: #808080;
         font-size: 16px;
         padding: 12px 15px;
         &:hover {
            background-color: #f8f8f8;
            color: $primary-color;
            cursor: pointer;
            & label {
               cursor: pointer;
            }
         }
      }
   }
}


// css for textox
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
textarea {
   width: 100%;
   min-height: 46px;
   box-sizing: border-box;
   border: 1px solid #d0d0d0;
   background: #fff;
   padding: 11px 14px;
   font-size: 16px;
   word-break: normal;
   outline: none;
   -webkit-appearance: none;
   border-radius: 0;
   line-height: normal;
   margin: 0;
}



button:focus {
   outline: none;
}

button:active,
button .active,
.button:active,
.button .active,
a.button:active,
a.button .active,
a.button:visited:active,
a.button:visited .active,
a.button:link:active,
a.button:link .active {
   opacity: 0.6;
}

//css for button
%btn-main {
   -webkit-appearance: none;
   font-weight: 100;
   text-align: center;
   text-decoration: none;
   line-height: 20px;
   cursor: pointer;
   outline: none;
   padding: 12px 12px 11px;
   border-radius: 0;
   display: inline-block;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   box-sizing: border-box;
}

.btn-custom-primary {
   @extend %btn-main;
   background-color: #00a2ff;
   -webkit-appearance: none;
   border: 1px solid #00a2ff;
   color: #fff;
}

.btn-custom-secondary {
   @extend %btn-main;
   background-color: transparent;
   border: 1px solid #b8b298;
   color: #b8b298;
}

// css for toggle switch
.switch-wrapper.on {
   color: #14c6a2;
   border: 1px solid #00c19a;
   background-color: #fff;
}

.switch-wrapper.off {
   color: #fff;
   border: 1px solid #a8a8a8;
   background-color: #a8a8a8;
   position: relative;
}

.switch-wrapper.off .is.on {
   left: -100%;
}

.switch-wrapper.off .is.off {
   margin-right: -6px;
}

.switch-wrapper {
   cursor: pointer;
   display: inline-block;
   overflow: hidden;
   position: relative;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   font-size: 14px;
   text-transform: uppercase;
   line-height: 1;
   word-wrap: normal;
   word-break: normal;
}

.switch-wrapper.on .is.on {
   left: 0;
}

.switch-wrapper .is {
   margin: 0 2px;
   padding: 9px 30px 7px 10px;
   position: relative;
   width: 100%;
   box-sizing: border-box;
   transition: left 0.2s linear, margin 0.2s linear;
}

.switch-wrapper.on .is.off {
   margin-right: 26px;
}

.switch-wrapper .is.off {
   right: -99%;
   padding-left: 25px;
   position: absolute;
   top: 0;
}

.switch-wrapper .is.off:before {
   position: absolute;
   left: 0;
   top: 6px;
   content: '';
   display: inline-block;
   width: 18px;
   height: 18px;
   border-radius: 50%;
}

.switch-wrapper.on .is:before {
   background: #00c19a;
}

.switch-wrapper.off .is:before {
   background: #fff;
}