@import './common/variables.scss';
@import './common/mixins.scss';
.start-plan-container {
   height: auto !important;
   align-items: center;
   margin-top: 30px;
   flex-wrap: wrap;
   display: flex;
   & .btn-custom-link {
      margin-left: 15px;
      margin-top: 15px;
   }
}

.lessThan60SecMsg {
  font-size: 12px;
  margin-left: 3px;
  font-weight: 100;
}

.blur-text {
   @include font-type($font-Proxima-regular, 14px, 300);
   opacity: 0.8;
}

.welcomeImgDiv {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #d0d0d0;
  margin: 0px 40px 40px;
}

.welcomeImgDiv img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.font-weight-600 {
  font-weight: 600;
}

.btn-custom-primary {
  font-weight: 700;
}

.custom-container {
   padding-bottom: 35px;
}

.welcome-instruction-container {
   max-width: 900px;
   margin: 0 auto;
   width: 100%;
   & .title-container {
      max-width: 100%;
      & .custom-label {
         font-size: 18px;
         display: block;
         text-align: center;
         text-decoration: underline;
         margin-bottom: 25px;
         text-underline-position: under;
      }
   }
   & .content-wrapper {
      padding: 30px 70px;
      width: 100%;
      border: 1px solid $primary-border-color;
   }
   & .sub-title {
      font-family: "Proxima Nova Bold", sans-serif;
      font-weight: 900;
   }
   & .step1 {
      margin-top: 15px;
      & .file-list {
         margin-top: 10px;
      }
   }
   & .step2 {
      margin-top: 15px;
      & .sub-step1 {
         margin-top: 10px;
      }
   }
   & .step3 {
      margin-top: 15px;
      & .sub-step1 {
         margin-top: 10px;
      }
   }
   & .btn-custom-primary {
      width: 200px;
   }
   & .mt30 {
      margin-top: 30px !important;
   }
}

.welcome-help-footer {
   display: table;
   width: 350px;
   margin: 0 auto;
   background: #f9f5f1;
   padding: 1.6rem 2rem 1.6rem 1.6rem;
   border-radius: 50px;
   margin-top: 50px;
   & img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: table-cell;
   }
   & div {
      display: table-cell;
      vertical-align: middle;
      padding-left: 15px;
      line-height: 1.2;
   }
}

.uninstall-container .sc-gsTEea button {
   display: none;
}

@media screen and (max-width: 768px) {
   .start-plan-container {
    
      & .btn-custom-link {
         margin-left: 0px;
      }
   }

}