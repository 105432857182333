
@import './common/variables.scss';
@import './common/mixins.scss';
.headerDiv {
  font-size: 25px;
  text-align: center;
  padding-bottom: 10px;

}

.font-weight-600 {
  font-weight: 600;
}

.content-wrapper-help {
  & .section-wrapper {
    float: none;
    margin: 0 auto;
    border: none;
    max-width: 700px;
    padding-top: 0px;
  }
  & .header-section {
    margin-bottom: 20px;
    & .heading {
      font-weight: 600;
      margin-bottom: 3px;
    }
  }
  & .middle-section {
    border: 1px solid $primary-border-color;
    padding: 30px 60px;
    margin-bottom: 20px;
    & .img-section {
      text-align: center;
      & img {
        width: 150px;
        border-radius: 50%;
        height: 150px;
      }
      & .name {
        font-size: 23px;
        font-family: 'Proxima Nova Bold', sans-serif;
        margin-top: 15px;
      }
      & .knowledge {
        color: $label-color;
        font-size: 14px;
      }
    }
  }
  & .note-section {
    border: 1px solid $primary-border-color;
    padding: 30px 60px;
  }
}

@media screen and (max-width: 768px) {
  .content-wrapper-help {
    & .middle-section {
      padding: 20px 30px;
    }
    & .note-section {
      padding: 20px 30px;
      margin-bottom: 20px;
    }
  }
}