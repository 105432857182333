@import './variables.scss';
@import './mixins.scss';
.setup-container {
   float: left;
   position: relative;
   width: 230px;
   border: 1px solid $primary-border-color;
   padding: 15px;
   text-align: center;
   border-left: 0px;
   background: $gray-background;
   & .setup-video-wrapper {
      margin-top: 15px;
   }
   & .mlveda-ads-wrapper {
      // width: 100%;
      background: $gray-background;
      padding: 10px;
      @include font-type($font-Proxima-regular, 14px, 300);
      color: $primary-font-color;
      margin: -15px;
      margin-top: 15px;
      border-top: 1px solid $primary-border-color;
   }
   &::before {
      content: '';
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0px;
      left: -1px;
      background: $primary-border-color;
   }
   & .video-player-mockup {
      width: 100%;
      height: 120px;
      text-align: center;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 75px;
      color: $primary-border-color;
      & img{
         width: 50px;
      }
   }
}

.videoBackground {
  background-size: contain;
  cursor: pointer;
}

.video-modal-body-wrapper {
  & iframe {
     width: 854px;
     height: 480px; // padding
  }
}

@media screen and (max-width: 1040px) and (min-width: 769px) {
   .setup-container {
      width: 25%;
   }
}

@media screen and (max-width: 768px) {
   .setup-container {
      display: none;
   }
   .setup-container {
      & .video-player-mockup {
         // padding-bottom: 50%;
      }
   }
   .modal-content {
    width: 96%;
   }
   .video-modal-body-wrapper {
    & iframe {
       width: 100%;
       height: 260px // padding
    }
 }
}