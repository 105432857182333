@mixin font-type($family, $size, $weight) {
    font-family: $family;
    font-size: $size;
    font-weight: $weight;
  }
  
  @mixin calc($property, $default, $expression) {
    #{$property}: $default;
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: -moz-calc(#{$expression});
    #{$property}: calc(#{$expression});
  }
  
  @mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
  }
  
  
  /* border-radius */
  
  @mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }
  
  
  /* transform */
  
  @mixin transform($value) {
    transform: $value;
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;    
  }
  
  
  @mixin transition($value) {
    transition: $value;
    -webkit-transition: $value;
    -moz-transition: $value;
    -ms-transition: $value;    
  }
  @mixin transform-origin($value) {
    -webkit-transform-origin: $value;
    -moz-transform-origin: $value;
    -ms-transform-origin: $value;
    transform-origin: $value;
  }
  
  @mixin transition-box-shadow($value) {
    -webkit-box-shadow: $value;
    -moz-box-shadow: $value;
    -ms-box-shadow: $value;
    box-shadow: $value;
  }
  @mixin transform($value) {
    transform: $value;
    -webkit-transform: $value;
    -moz-transform: $value;
    -ms-transform: $value;    
  }
  @mixin make-slope($angle: 5deg) {
    @include transform(rotate(-$angle));
    .slope-content {        
        @include transform(rotate($angle));
    }
  }