@import './common/variables.scss';
@import './common/mixins.scss';

.code-string {
  padding: 12px 20px;
  border: 1px solid $primary-border-color;

  &.with-bg {
    & span {
      background: #ef3c3c;
      color: white;
    }
  }
}

.copy-btn {
  display: inline-block;
  float: right;
}

#codeStringWithMoney,
#codeStringWithOutMoney {
  opacity: 0;
  visibility: hidden;
  position: fixed;
}

.store-integration-instructions {
  line-height: 1.7em;
}

.last-step-btn {
  margin: 5px auto;
  margin-bottom: 0px;
  width: 112px;
  display: inline-block;
}

.success-button-sec {
  margin-top: 35px;
  text-align: center;
  display: block;
  & .btn-custom-primary  {
    padding: 12px 25px;
  }
}

.success-notice {
  margin-top: 35px;
  padding: 35px 50px 40px 50px;
  border-top: 1px solid $primary-border-color;
}

.success-popup {
  width: 600px;
  margin-top: 10vh;
  & .modal-body-wrapper {
    padding: 0px;
    & .main-sec {
      padding: 40px 50px 0px 50px;
    }
  }
}

.inline-block {
  display: inline-block;
}

.noticeDiv-store {
  text-align: center;
  margin: 0px 40px 30px;
}

.importantIcon-store{
  font-size: 28px;
  color: $red-font-color;
  border: 3px solid $red-font-color;
  padding: 12px 20px;
  border-radius: 50%;
  display: inline-block;
}

.verifyLink {
  margin-left: 10px;
  text-decoration: underline;
}

.success-main {
  & .successImg {
    height: 90px;
    width: 90px;
    margin: 0 auto;
    text-align: center;
    display: block;
  }
  & .congrats-text {
    text-align: center;
    margin-top: 10px;
  }
  & .success-msg {
    margin-bottom: 5px;
    font-size: 21px;
    text-align: center;
    // font-family: $font-Proxima-bold;
    margin-top: 40px;
    text-decoration: underline;
    color: $primary-color;
  }
}

.empower-msg {
  margin-top: 25px;
}

.btn-custom-primary {
  margin: 0px !important;
  margin-left: 10px !important;
}

.errorPopupAction {
  margin-top: 20px;
  text-align: center;

  & .btn-custom-primary {
    width: 85px;
  }
}

.popupBtn {
  text-align: center;
  margin-top: 15px;
}

.mr25-imp {
  margin-right: 25px !important;
}

.modal-body-wrapper {
  padding: 40px 50px;

  & .bold-text {
    @include font-type($font-Proxima-bold, 16px, 400);
  }

  & button {
    margin: 5px 10px;
  }
}

.modal-title {
  @include font-type($font-Proxima-bold, 24px, 900);
}

.modal-content-wrapper {
  margin-top: 25px;
  padding: 35px;
  border: 1px solid $primary-border-color;
}

.copyInput {
  padding: 0px !important;
  border: none !important;
  min-height: 0px !important;
  color: #4D5055 !important;
}

.success-svg {
  width: 100px;
  display: block;
  margin: 0 auto;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  &.circle {
    -webkit-animation: dash 1.2s ease-in-out;
    animation: dash 1.2s ease-in-out;
  }
  &.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 1.2s .35s ease-in-out forwards;
    animation: dash 1.2s .35s ease-in-out forwards;
  }
  &.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check 1.2s .35s ease-in-out forwards;
    animation: dash-check 1.2s .35s ease-in-out forwards;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

@media screen and (max-width: 768px) {
  .modal-body-wrapper {
    padding: 30px;

    & .bold-text {
      @include font-type($font-Proxima-bold, 14px, 400);
    }

    & button {
      margin: 0 10px 5px 0px;
    }
  }

  .modal-content-wrapper {
    margin-top: 15px;
    padding: 20px;
  }
}