@import './common/variables.scss';
@import './common/mixins.scss';
.pricing-container {
  & .btn-custom-primary {
    width: 120px;
     &.pulse {
        // animation: pulse 2s infinite;
        // -webkit-animation: pulse 2s infinite;
        // -moz-animation: pulse 2s infinite;
        -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
     }
     &.install {
        background: black;
        color: white;
        border-color: black;
     }
     &.black {
        border-color: $primary-font-color;
        background: white;
        color: $primary-font-color;
     }
  }
}

@-webkit-keyframes pulse {
   to {
      box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
   }
}

@-moz-keyframes pulse {
   to {
      box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
   }
}

@-ms-keyframes pulse {
   to {
      box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
   }
}

@keyframes pulse {
   to {
      box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
   }
}

.pulse:hover {
   animation: none;
}

// @-webkit-keyframes pulse {
//   0% {
//     -webkit-box-shadow: 0 0 0 0 rgba(90, 190, 190, 0.4);
//   }
//   70% {
//       -webkit-box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
//   }
//   100% {
//       -webkit-box-shadow: 0 0 0 0 rgba(90, 190, 190, 0);
//   }
// }
// @keyframes pulse {
//   0% {
//     -moz-box-shadow: 0 0 0 0 rgba(90, 190, 190, 0.4);
//     box-shadow: 0 0 0 0 rgba(90, 190, 190, 0.4);
//   }
//   70% {
//       -moz-box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
//       box-shadow: 0 0 0 10px rgba(90, 190, 190, 0);
//   }
//   100% {
//       -moz-box-shadow: 0 0 0 0 rgba(90, 190, 190, 0);
//       box-shadow: 0 0 0 0 rgba(90, 190, 190, 0);
//   }
// }
.btn-custom-primary {
   &.black {
      width: 120px;
   }
}

@keyframes flash {
   0% {
      opacity: 1.0;
   }
   50% {
      opacity: 0.5;
   }
   100% {
      opacity: 1.0;
   }
}

//Firefox 1+
@-webkit-keyframes flash {
   0% {
      opacity: 1.0;
   }
   50% {
      opacity: 0.5;
   }
   100% {
      opacity: 1.0;
   }
}

//Safari 3-4
@-moz-keyframes flash {
   0% {
      opacity: 1.0;
   }
   50% {
      opacity: 0.5;
   }
   100% {
      opacity: 1.0;
   }
}

.pricing-title {
   @include font-type($font-Proxima-bold, 20px, 900);
   text-align: center;
   color: black;
   margin: 25px;
   margin-bottom: 100px;
}

.pricing-container {
   width: 100%;
   max-width: 600px;
   margin-bottom: 30px;
}

.pricing-row {
   display: flex;
   width: 100%;
   flex-direction: row;
   & .whole-row {
      width: 100%;
      background: $gray-background;
      padding: 15px; // border-left: 1px solid $primary-border-color;
      // border-top: 1px solid $primary-border-color; 
      border: 1px solid $primary-border-color;
      border-top: none;
   }
   & .col-no-1 {
      justify-content: center;
      align-items: center;
      flex: 1;
      padding: 0px;
      border-right: 1px solid $primary-border-color;
      border-left: 1px solid $primary-border-color;
      border-bottom: 1px solid $primary-border-color;
      height: 0%;
      & .customer-rate{
        border-top: 1px solid black;
        border-left: 1px solid black;
      }
      & .content {
         text-align: center;
         width: 100%;
         padding: 20px; //  border-bottom: 1px solid $primary-border-color;
         height: 100%;
         & .title {
            @include font-type($font-Proxima-regular, 20px, 400);
         }
         & .price {
            & span:nth-child(1) {
               @include font-type($font-Proxima-regular, 35px, 500);
               color: black;
            }
            & span:nth-child(2) {
            
               @include font-type($font-Proxima-regular, 35px, 500);
               color: black;
            }
            & span:nth-child(3) {
               @include font-type($font-Proxima-regular, 20px, 400);
               color: black;
            }
         }
      }
   }
   & .col-no-2 {
      height: 0%;
   }
   & .col-no-3 {
      transform: translateY(-45px);
      & .infoDiv {
         padding-top: 60px;
         padding-bottom: 80px
      }
      box-shadow: 0px 6px 12px rgba(0,0,0,0.5);
   }
   & .col-no-2,
   .col-no-3 {
      border-right: 1px solid $primary-border-color; // border-bottom: 1px solid $primary-border-color;
      // border-left: 1px solid $primary-border-color;
      padding: 0px;
      justify-content: center;
      align-items: center;
      flex: 1;
      & .content {
         text-align: center;
         width: 100%;
         padding: 20px; //  border-bottom: 1px solid $primary-border-color;
         height: 100%;
         & .title {
            @include font-type($font-Proxima-regular, 20px, 400);
         }
         & .price {
            & span:nth-child(1) {
               text-decoration: line-through;
               @include font-type($font-Proxima-regular, 35px, 500);
               color: black;
            }
            & span:nth-child(2) {
               margin-left: 10px;
               @include font-type($font-Proxima-regular, 35px, 500);
               color: black;
            }
            & span:nth-child(3) {
               @include font-type($font-Proxima-regular, 20px, 400);
               color: black;
            }
         }
      }
   }
}

.pricing-row-custom {
  width: 400px;
  margin: 0 auto;
}

.asterisk{
   color: black;
   position: absolute;
}

.col-no-2 .bottomInstallBtn {
   padding: 20px;
   text-align: center;
   border-top: 1px solid $primary-border-color;
   border-bottom: 1px solid $primary-border-color;
}

.col-no-3 .bottomInstallBtn {
   padding: 20px;
   text-align: center; // border-top: 1px solid $primary-border-color;
   border-bottom: 1px solid $primary-color !important;
}

.col-no-3 .content {
   border-bottom: 1px solid black !important;
}

.special-border-lrb {
   border-left: 1px solid black !important;
   border-right: 1px solid black !important;
   border-bottom: 1px solid black !important;
}

.questionMark {
   color: #00a2ff;
   margin-left: 10px;
}

// .bottomInstallBtn {
//   padding: 20px;
//   text-align: center;
//   border-top: 1px solid $primary-border-color;
// }
.recommended-tab {
   letter-spacing: 1px;
   @include font-type($font-Proxima-regular, 16px, 400);
   position: absolute;
   color: white;
   padding: 5px;
   top: -34px;
   right: 0px;
   left: -1px;
   background: black;
   width: calc(100% + 2px);
   margin: auto;
   text-align: center;
   box-shadow: 0px -1px 12px rgba(0,0,0,0.5); 
}

.customer-rate {
   background: $primary-border-color;
   color: black;
   text-align: center;
   padding: 10px;
   &.special {
      border-bottom: 1px solid black;
   }
}

.infoDiv {
   padding: 20px;
   text-align: left;
   margin-bottom: 10px;
   color: black;
   &.text {
      padding-right: 10;
      padding-left: 50px;
   }
   & .tick-sign {
      position: relative;
      &:after {
         content: "";
         background-color: transparent;
         /* position the checkbox */
         position: absolute;
         left: -25px;
         top: 5px;
         /* setting the checkbox */
         /* short arm */
         width: 7px;
         border-bottom: 3px solid black;
         /* long arm */
         height: 14px;
         border-right: 3px solid black;
         /* rotate the mirrored L to make it a checkbox */
         transform: rotate(45deg);
         -o-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         -webkit-transform: rotate(45deg);
      }
   }
}

.mt-10 {
   margin-top: 15px;
}

.no-border {
   border: none !important;
}

.top-border {
   border-top: 1px solid $primary-border-color;
   border-bottom: 1px solid $primary-border-color;
}

.bottom-border {
   border-bottom: 1px solid $primary-border-color;
}

.right-border {
   border-right: 1px solid $primary-border-color;
}

.no-left-border {
   border-left: none !important;
}

.left-border {
   border-left: 1px solid $primary-border-color;
}

.special-border {
   border-top: 1px solid black !important;
   border-right: 1px solid black !important;
   border-left: 1px solid black !important;
}

.pricing-btn-container {
  span {
    padding: 12px 10px !important;
  }
  & div:nth-child(1) {
    padding-bottom: 4px;
  }
  & div:nth-child(2) {
    font-size: 14px !important;
  }
}

.price .textSpan {
  margin-top: 10px;
  color: $primary-btn-color;
}

.thinking-face-img{
   margin-right: 30px;
}

@media screen and (max-width: 768px) {
   .pricing-row {
      & .col-no-1.res {
         display: none;
         &+div {
            margin-left: 7px;
            border-left: 1px solid $primary-border-color;
         }
      }
   }
   & .col-no-2,
   .col-no-3 {
      & .content {
         padding: 15px !important;
      }
   }
   .pricing-container {
      padding: 5px;
   }
}

.speech-bubble-container {
   position: absolute;
   top: -80px;
   left: -37px;
   & .speech-bubble {
      position: relative;
      background: white;
      border: 1px solid $primary-color;
      border-radius: 50%;
      width: 92px;
      text-align: center;
      font-size: 20px;
      padding: 15px;
      z-index: 1;
   }
   & .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 4px;
      left: 58%;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: $primary-color;
      border-bottom: 0;
      border-right: 0;
      margin-left: -10px;
      margin-bottom: -20px;
   }
}

.pricing-pop-up {
   color: black;
   & .flex-row {
      display: flex;
      align-items: center;
      padding: 15px;
      & .wait-title {
         @include font-type($font-Proxima-regular, 30px, 900);
      }
   }
}

.pricing-pop-up{
   & .heading {
      @include font-type($font-Proxima-regular, 24px, 900);
   }
   & .text-left {
      text-align: left;
   }
   & .ml0{
      margin-left: 0px !important;
   }
   & .bold-text {
      @include font-type($font-Proxima-bold, 16px, 400);
   }
   & .underline-text{
      text-decoration: underline;
      text-underline-position: under;
   }
}