// .googleTranslateFixedEle {
// 	top: 15px;
// 	right: 10px;
// 	display: inline-block;
// 	position: absolute;
// }

.goog-te-banner-frame.skiptranslate {
  display: none !important;
} 
body {
  top: 0px !important; 
}