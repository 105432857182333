// Cutoms Gray colors for theme
$gray-darker: #232735;
$gray-background: #f8f8f8;
$gray: #dde6e9;
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;
$white: #ffffff;

$font-Proxima-light: 'Proxima Nova Thin', sans-serif;
$font-Proxima-regular: 'Proxima Nova Rg Regular', sans-serif;
$font-Proxima-bold: 'Proxima Nova Bold', sans-serif;


$primary-font-color:#4D5055;
$label-color:#666a73;
$primary-border-color:#d0d0d0;
$primary-color:#00a2ff;
$primary-btn-color:#00a2ff;

$red-font-color: red;
$green-font-color: #04cea4;