@import './variables.scss';
@import './mixins.scss';
.settings-wrapper {
   display: flex;
   flex-wrap: wrap;
   width: 100%;
   border: 1px solid $primary-border-color;
   & .item {
      flex: 0 0 50%;
      padding: 25px;
      & .setting-title {
         width: 100%;
         background: $gray-background;
         text-align: center;
         @include font-type($font-Proxima-regular, 16px, 400);
         color: $primary-font-color;
         padding: 15px;
         border: 1px solid $primary-border-color;
         cursor: pointer;
      }
      & .setting-title:hover {
        box-shadow: 0px 3px 10px 0px #d0d0d0;
      }
   }
   & ul {
      padding: 10px 0 0 20px;
   }
}

.setting-title-wrapper div {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
   .settings-wrapper {
      & .item {
         flex: 0 0 100%;
      }
   }
}