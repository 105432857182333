@import './common/variables.scss';
.currency-location {
   position: relative;
   & img {
      width: 100%;
      height: auto;
      margin-left: -40px;
   }
   & .show {
      position: absolute;
      left: 0;
      right: 0;
      width: 50%;
      margin: 0 auto;
      top: 0;
      height: 100%;
      & div:nth-child(1) {
         position: absolute;
         left: 0px;
         top: 30px;
      }
      & div:nth-child(2) {
         position: absolute;
         right: 0px;
         top: 30px;
      }
      & div:nth-child(3) {
         position: absolute;
         left: 0px;
         bottom: 62px;
      }
      & div:nth-child(4) {
         position: absolute;
         right: 0px;
         bottom: 62px;
      }
   }
   & .custom-show {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    margin: 0 auto;
    top: 0;
    height: 100%;
    & div:nth-child(1) {
       position: absolute;
       left: 0px;
       top: 145px;
    }
    & div:nth-child(2) {
       position: absolute;
       right: 0px;
       top: 145px;
    }
   }
}

.themeSupportedDiv {
  padding-top: 5px;
  & li {
    margin-top: 20px;
  }
}

.expandIcon {
  transform: rotate(90deg);
  display: inline-block;
}

.inline-block {
  display: inline-block;
}

@media screen and (max-width: 768px) {
   .currency-location {
      & img {
         margin-left: -30px;
      }
      & .show {
         & div:nth-child(1) {
            top: 20px;
         }
         & div:nth-child(2) {
            top: 20px;
         }
         & div:nth-child(3) {
            bottom: 25%;
         }
         & div:nth-child(4) {
            bottom: 25%;
         }
      }
   }
}