@import './variables.scss';
.header {
   width: 100%;
   height: 56px;
   border-bottom: 1px solid $primary-border-color;
}

.menu-bar {
  display: inline-block;
  width: 52%;
  height: 100%;
  vertical-align: top;
  & ul {
    padding: 0px;
    margin-bottom: 0px;
    margin: 0 auto;
    width: 85%;
    height: 100%;
    & li {
      list-style-type: none;
      vertical-align: middle;
      padding: 0px 24px;
      display: inline-block;
      height: 100%;
      padding-top: 15px;
      cursor: pointer;
      & .icon {
        display: inline-block;
        vertical-align: middle;
      }
      & .label {
        display: inline-block;
        margin-left: 8px;
        font-size: 12px;
        vertical-align: middle;
        text-transform: uppercase;
      }
      & svg {
        font-size: 14px;
        color: #777;
      }
    }
    & .selected {
      border-bottom: 3px solid $primary-color;
      color: $primary-color;
      & svg {
        color: $primary-color;
      }
    }
  }
}

.review-wrapper {
  display: inline-block;
  width: 5%;
  cursor: pointer;
  & img {
    width: 32px;
  }
}

.app-name-wrapper {
   width: 25%;
   display: inline-block;
   vertical-align: middle;
   height: 100%;
   padding-top: 6px;
   & .custom-select-box__control {
      padding: 0px 15px !important;
      width: 235px !important;
      border: 0px !important;
      background: transparent !important;
   }
   & .custom-select-box__menu {
      width: 200px !important;
      border: 1px solid $primary-color !important;
      ;
   }
   & img {
    width: 40px;
    display: table-cell;
  }
  & div {
    display: table;
  }
}

.app-status-wrapper {
   display: inline-block;
   width: 30%; // width: calc(100% - 250px);
   padding-top: 5px;
   vertical-align: middle;
   & .progress-wrapper {
      width: 220px;
      margin: auto;
   }
   & .custom-label {
      margin-bottom: 25px;
      margin-right: 15px;
      vertical-align: middle;
   }
   & .app-status {
      float: right;
   }
}
.hot-offer-img{
   width: 90px;
   margin-right: 30px;
}
.plan-wrapper{
   display: inline-block;
}
.notification-wrapper {
   position: relative;
   color: $primary-border-color;
   margin-left: 25px;
   top: -5px;
   cursor: pointer;
   display: inline-block;
   .red-dot {
      position: absolute;
      right: 1px;
      top: 2px;
      height: 8px;
      width: 8px;
      background: red;
      border-radius: 50%;
   }
   & .notification-block {
      width: 300px;
      background: white;
      border: 1px solid $primary-border-color;
      position: absolute;
      right: 0px;
      top: 27px;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      & ul {
         list-style: none;
         padding: 0px;
         & li {
            color: $primary-font-color;
            padding: 15px 15px 15px 15px;
            border-bottom: 1px solid $gray;
            &:last-child {
               border-bottom: none;
               padding-bottom: 0px;
            }
         }
      }
   }
}

.acs-logo-wrapper {
  width: 25%;
  display: inline-block;
  vertical-align: middle;
   & img {
      width: 50px;
      height: auto;
      display: block;
    margin: auto;
   }
}

.progress-wrapper {
    width: 52%;
    display: inline-block;
    margin: auto;
    vertical-align: middle;
   & .progress-bar {
      background-color: $primary-color;
   }
   & .progress {
      height: 10px;
      border: 1px solid $primary-border-color;
      width: 50%;
      margin: 0 auto;
   }
}

.app-burger-section {
   display: none;
   padding-top: 7px;
   & label {
      font-size: 16px;
      &.center {
         vertical-align: middle;
      }
   }
   & span {
      cursor: pointer;
      margin-right: 15px;
      display: inline-block;
   }
}

.sidebar-menu-wrapper {
   position: fixed;
   background: white;
   z-index: 999;
   top: 0px;
   width: 240px;
   height: 100%;
   border-right: 1px solid $primary-border-color;
   box-shadow: -7px 0px 15px rgba(0, 0, 0, 0.8);
   transition: left 0.3s ease-in-out;
   left: -240px;
   &.show {
      left: 0px;
   }
   & ul {
      padding: 25px 35px;
   }
   & .app-name-dropdown {
      padding: 20px 35px;
      background: $gray-background;
   }
}

.back-arrow {
   width: 25px;
   cursor: pointer;
   margin-right: 12px;
   margin-bottom: 5px;
}

.basicPlanDiv {
  display: inline-block;
  & button {
    color: $green-font-color;
    border: 1px solid $green-font-color;
    padding: 6px 10px;
    margin-left: 5px;
  }
  & label {
    margin-left: 20px;
  }
}

.translate-wrapper {
  width: 18%;
    display: inline-block;
    vertical-align: middle;
}

.main-top-bar {
  background: #f9f5f1;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  font-size: 16px;
  & .paid-text {
    color: #04cea4;
  }
  & .btn-custom-primary {
    padding: 4px 12px;
    background: #fff;
    color: $green-font-color;
    box-shadow: none;
    border: 1px solid $green-font-color;
    margin-left: 25px !important;
    font-size: 14px;
    line-height: 16px;
  }
  & .btn-custom-primary:hover {
    background: $green-font-color;
    color: #fff;
  }
}

.header-main-div {
  max-width: 1120px;
  margin: 0 auto;
  height: 100%;
}

.mb-app-name, .close-icon {
  display: none;
}

.reviewPopup {
  & .modal-body {
    padding: 0px;
    & .heading {
      text-align: center;
      background: $green-font-color;
      padding: 15px 8px;
      color: $white;
      font-size: 18px;
      font-weight: 600;
      & img {
        height: 26px;
      }
      & div {
        display: inline;
        vertical-align: middle;
        margin-left: 8px;
      }
    }
    & .body-main {
      padding: 40px 50px;
      & .first-section{
        padding-left: 60px;
        font-weight: 600;
      }
      & .second-section {
        padding-top: 20px;
        & span {
          font-weight: 600;
        }
      }
      & .third-section {
        padding-top: 20px;
        text-align: center;
        & .btn-custom-primary {
          padding: 8px 12px;
          width: 100%;
          margin: 0px !important;
          & img {
            width: 25px;
            margin-right: 8px;
            vertical-align: sub;
          }
          & h2 {
            font-size: 1.5rem;
            font-family: 'Proxima Nova Bold';
            display: inline-block;
            margin-right: 8px;
            margin-bottom: 0px;
          }
        }
        & .btn-custom-primary:last-child {
          margin-top: 15px !important;
          background: $white;
          color: $primary-font-color;
          border: 1px solid $primary-border-color;
        }
      }
      & .fourth-section {
        padding-top: 20px;
        text-align: center;
        & .btn-custom-primary {
          width: 75%;
          margin-left: 0 !important;
        }
        & .message {
          padding-top: 8px;
          width: 100%;
          margin: 0 auto;
          text-align: left;
          line-height: 1.2;
          & span {
            color: $primary-color;
            cursor: default;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1130px) {
   .svg-inline--fa {
      font-size: 1.8rem !important;
   }
   .app-status {
      text-align: center;
      margin-top: 15px;
   }
   .app-burger-section {
      display: block;
   }
   .app-status-wrapper {
      display: inline-block;
      float: right; // width: calc(100% - 250px);
      .app-status {
         display: none;
      }
   }
   .app-name-wrapper {
      width: 150px;
      display: none;
      & .custom-select-box__control {
         padding-left: 0px !important;
      }
      & .custom-select-box__control {
         width: 150px !important;
      }
      & .custom-select-box__menu {
         width: 150px !important;
      }
   }
   .progress-wrapper {
      width: 50%;
      left: auto;
      height: 100%;
      padding-top: 30px;
      & .progress { 
        width: 90%;
      }
   }
   .header {
      min-height: 73px;
   }
   .menu-bar {
     display: none !important;
   }

   .review-wrapper {
    display: none !important;
   }
   .mb-app-name {
    display: table;
    & img {
      width: 40px;
      display: table-cell;
    }
    & .appNameLabel {
      display: table-cell;
      padding-left: 5px;
      font-size: 18px;
    }
   }
   .googleTranslateFixedEle {
    // position: absolute;
    // right: 5px;
    // top: 45px;
    padding-left: 40px;
   }
   .close-icon {
    position: absolute;
    top: 0px;
    left: 226px;
    background: #fff;
    display: block;
   }
}
