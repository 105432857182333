@import './common/variables.scss';
@import './common/mixins.scss';

.partners-app-heading {
  font-family: "Proxima Nova Bold", sans-serif;
  font-size: 20px;
  font-weight: 900;
  margin-top: 30px;
  text-align: center;
  & span {
    color: #fa2d48;
  }
}

.partners-app-card {
  display: table;
  width: 100%;
  border: 1px solid #d0d0d0;
  padding: 20px 30px;
  margin: 20px 0px;
  & .img-section {
    display: table-cell;
    vertical-align: middle;
    padding-right: 40px;
    & img {
      height: 45px;
      width: 45px;
    }
  }
  & .description-section {
    display: table-cell;
    vertical-align: middle;
    padding-right: 40px;
    & .app-name {
      font-family: 'Proxima Nova Bold';
      & .price-off {
        border: 1px solid #00a2ff;
        background: #fff;
        color: #00a2ff;
        font-weight: 400;
        padding: 5px 8px;
        border-radius: 50px;
        font-size: 13px;
        margin-left: 10px;
      }
    }
    & .app-desc {
      line-height: 1.3;
      margin-top: 5px;
    }
  }
  & .button-section {
    display: table-cell;
    vertical-align: middle;
    & .btn-custom-primary {
      padding: 10px 20px;
    }
  }
}

.main-partners-app {
  max-width: 580px;
  margin: 0 auto;
  margin-top: 70px;
}