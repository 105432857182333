@import './common/variables.scss';
@import './common/mixins.scss';
.preview-wrapper {
   float: right;
   margin-top: 25px;
   width: 125px;
   padding: 10px;
   border: 1px solid $primary-border-color;
   & span:nth-child(2) {
      margin-left: 15px;
      font-weight: 700;
   }
}

.inline-block {
  display: inline-block;
}

.advanceOption {
  margin-top: 20px;
  height: 10px;
}

.lookLeftDiv {
  padding-left: 0px;
}

.lookRightDiv {
  display: inline-block;
  padding-right: 0px;
  position: relative;

}

.preview-wrapper {
  float: none;
  position: absolute;
  top: -23px;
  margin-top: 0px;
}

.usd-image {
   width: 35px;
   height: auto;
}

.moreSwitcherOption {
  position: absolute;
  bottom: -20px;
}

.moreSwitcherDiv {
  width: 55%;
}

@media screen and (max-width: 768px) {
  .lookRightDiv {
    padding-left: 0px;
    margin-top: 5px;
    & .preview-wrapper {
      float: left;
      position: relative;
      top: 0px;
      margin-top: 20px;
    }
  }

  .moreSwitcherDiv {
    width: 70%;
  }
}

